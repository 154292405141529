import StyleTypography from '../../StyledComponents/StyleTypography';
import React from 'react';
import taxYearBanner from '../../../images/Photo-min.jpg';
import StyleButton from '../../StyledComponents/StyleButton';

export const TaxYearBanner = ({ push = () => {} }) => {
	return (
		<div
			style={{
				position: 'relative',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<img
				src={taxYearBanner}
				alt={'close tax year'}
				className={'image-tax-year'}
			/>
			<div
				style={{
					position: 'absolute',
					top: '20px',
					left: '40px',
				}}
			>
				<div>
					<StyleTypography fontSize={16} fontSizeMedium={12} color={'#FFF'}>
						Reminder
					</StyleTypography>
				</div>
				<div
					style={{
						paddingTop: '10px',
					}}
				>
					<StyleTypography
						fontSize={30}
						fontSizeMedium={22}
						fontWeight={'bold'}
						color={'#FFF'}
					>
						This tax year is coming to an end
					</StyleTypography>
				</div>
				<div>
					<StyleTypography fontSize={16} fontSizeMedium={12} color={'#FFF'}>
						When you are done with all your pay runs for the current tax year,
						click on <br />
						the button below to close this tax year.
					</StyleTypography>
				</div>
				<div
					style={{
						flex: '0 0 41.66%',
						paddingTop: '10px',
						display: 'flex',
					}}
				>
					<StyleButton
						isBlueButton={true}
						onClick={() => push('/main/close-tax-year')}
					>
						<StyleTypography fontSize={16} fontSizeMedium={12} color={'#FFF'}>
							Close Tax Year
						</StyleTypography>
					</StyleButton>
				</div>
			</div>
		</div>
	);
};
