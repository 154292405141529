import { Button, Typography, Select, MenuItem } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import enGb from 'date-fns/locale/en-GB';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { parseISO } from 'date-fns';

const InnerStepOne = ({ stepZero, setStepZero, basicSetup = () => {} }) => {
	const [dateError, setDateError] = useState(false);
	const minDate = parseISO('2024-04-06');
	const maxDate = parseISO('2025-04-05');

	return (
		<div id={'inner-step-one'} style={{ display: 'flex', flexWrap: 'wrap' }}>
			<div className={'body-title-container'} style={{ width: '100%' }}>
				<Typography className={'body-title'}>
					Customising your setup experience
				</Typography>
			</div>

			<div className={'body-sub-container'} style={{ width: '100%' }}>
				<Typography className={'body-subtitle-1'}>
					What's your desired first payday with FreePayroll?
				</Typography>
				<Typography className={'body-subtitle-2'}>
					Not sure? No problem, just give us an estimate. You'll be able to
					change this date later.
				</Typography>

				<div style={{ width: '40%' }}>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						adapterLocale={enGb}
					>
						<DesktopDatePicker
							className={'date-picker'}
							onChange={(newValue) => {
								setStepZero({
									...stepZero,
									first_payday_date: newValue,
								});
							}}
							onError={(error) => {
								setDateError(error !== null);
							}}
							slotProps={{ field: { readOnly: true } }}
							enableAccessibleFieldDOMStructure
							value={stepZero.first_payday_date}
							minDate={minDate}
							maxDate={maxDate}
						/>
					</LocalizationProvider>
				</div>
			</div>

			<div
				className={'body-sub-container-2'}
				style={{ flexBasis: '100%', maxWidth: '100%' }}
			>
				<Typography className={'body-subtitle-1'}>
					Will this be your company’s first pay day since 6 April 2024?
				</Typography>
				<RadioGroup
					row
					onChange={(event) => {
						setStepZero({
							...stepZero,
							first_tax_year_pay: event.target.value === 'true',
							first_payroll_ever: null,
							current_payroll_provider: ' ',
						});
					}}
				>
					<FormControlLabel value={true} control={<Radio />} label="Yes" />
					<FormControlLabel value={false} control={<Radio />} label="No" />
				</RadioGroup>
			</div>

			{stepZero.first_tax_year_pay === true && (
				<div
					className={'body-sub-container-3'}
					style={{ flexBasis: '100%', maxWidth: '100%' }}
				>
					<Typography className={'body-subtitle-1'}>
						Will this be the first payroll ever for this company?
					</Typography>
					<RadioGroup
						row
						onChange={(event) => {
							setStepZero({
								...stepZero,
								first_payroll_ever: event.target.value === 'true',
								current_payroll_provider: ' ',
							});
						}}
					>
						<FormControlLabel value={true} control={<Radio />} label="Yes" />
						<FormControlLabel value={false} control={<Radio />} label="No" />
					</RadioGroup>
				</div>
			)}

			{(stepZero.first_tax_year_pay === false ||
				(stepZero.first_tax_year_pay === true &&
					stepZero.first_payroll_ever === false)) && (
				<div className={'body-sub-container-4'} style={{ width: '40%' }}>
					<Typography className={'body-subtitle-1'}>
						Who is your current payroll provider?
					</Typography>
					<FormControl fullWidth>
						<Select
							className={'default-select'}
							id={'select-current-provider'}
							value={stepZero.current_payroll_provider}
							onChange={(event) => {
								setStepZero({
									...stepZero,
									current_payroll_provider: event.target.value,
								});
							}}
						>
							<MenuItem value={' '}>Select current provider...</MenuItem>
							<MenuItem value={'BrightPay'}>BrightPay</MenuItem>
							<MenuItem value={'Sage'}>Sage</MenuItem>
							<MenuItem value={'Iris'}>Iris</MenuItem>
							<MenuItem value={'MoneySoft'}>MoneySoft</MenuItem>
							<MenuItem value={'Basic PAYE Tools'}>Basic PAYE Tools</MenuItem>
							<MenuItem value={'Other'}>Other</MenuItem>
						</Select>
					</FormControl>
				</div>
			)}

			<div
				className={'button-container'}
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					flexBasis: '100%',
					maxWidth: '100%',
				}}
			>
				<Button
					className={'default-black-button'}
					onClick={() =>
						basicSetup({
							employers: {
								step: 'payroll_dates',
								data_insert: {
									company_first_payday: `${stepZero.first_payday_date}`,
									first_pay_in_tax_year: stepZero.first_tax_year_pay,
									first_payroll_ever:
										stepZero.first_payroll_ever === null
											? false
											: stepZero.first_payroll_ever,
									current_payroll_provider: stepZero.current_payroll_provider,
									company_planning_pay: stepZero.myself
										? 'myself '
										: '' + stepZero.employees
										? 'employees '
										: '' + stepZero.not_paying
										? 'not_paying'
										: '',
								},
							},
						})
					}
					disabled={
						dateError ||
						!stepZero.first_payday_date ||
						stepZero.first_tax_year_pay === null ||
						(stepZero.first_tax_year_pay === false &&
							stepZero.current_payroll_provider === ' ') ||
						(stepZero.first_tax_year_pay === true &&
							stepZero.first_payroll_ever === false &&
							stepZero.current_payroll_provider === ' ') ||
						(stepZero.first_tax_year_pay === true &&
							stepZero.first_payroll_ever === null)
					}
				>
					Submit
				</Button>
			</div>
		</div>
	);
};

export default InnerStepOne;
