import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import StyleTypography from '../../../StyledComponents/StyleTypography';
import StyleButton from '../../../StyledComponents/StyleButton';

const StepThreeCompliance = ({ basicSetup = () => {} }) => {
	return (
		<div
			id="step-three"
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
				width: '100%',
			}}
		>
			<div style={{ width: '100%' }} className={'main-title-container'}>
				<Typography variant="h6" className={'main-title'}>
					FreePayroll: Ensuring Legal Compliance
				</Typography>
				<StyleTypography fontSize={20} fontSizeMedium={15} color={'#ADB0B8'}>
					As an employer, it's crucial to understand and comply with the legal
					and regulatory standards related to employment.
				</StyleTypography>
			</div>

			<div style={{ width: '100%' }} className={'body-sub-container'}>
				<StyleTypography color={'#000'} fontSize={18} fontSizeMedium={16}>
					At FreePayroll, ensuring compliance with legal and regulatory
					standards is fundamental to our service. We've built several features
					into our platform to guarantee that your payroll processing is both
					compliant and secure:
				</StyleTypography>
				<ul>
					<li>
						<StyleTypography color={'#000'} fontSize={17} fontSizeMedium={16}>
							<strong>Automated Reporting to HMRC:</strong> <br />
							Every payroll run includes an automatic Full Payment Submission
							(FPS) to HMRC. More details on reporting can be found at{' '}
							<Link
								href="https://www.gov.uk/running-payroll/reporting-to-hmrc"
								target="_blank"
								rel="noopener"
							>
								Reporting to HMRC
							</Link>
							.
						</StyleTypography>
					</li>
					<li>
						<StyleTypography color={'#000'} fontSize={17} fontSizeMedium={16}>
							<strong>Encrypted Payslips:</strong>
							<br /> Payslips are encrypted and securely delivered to maintain
							confidentiality. Learn more about data protection at{' '}
							<Link
								href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/"
								target="_blank"
								rel="noopener"
							>
								UK's GDPR Compliance
							</Link>
							.
						</StyleTypography>
					</li>
					<li>
						<StyleTypography color={'#000'} fontSize={17} fontSizeMedium={16}>
							<strong>Real-Time Information (RTI) Compliance:</strong>
							<br /> We provide instant updates to HMRC with each payroll
							submission, ensuring accuracy and compliance.
						</StyleTypography>
					</li>
					<li>
						<StyleTypography color={'#000'} fontSize={17} fontSizeMedium={16}>
							<strong>Automatic Updates on Legislation Changes:</strong>
							<br /> FreePayroll automatically updates to reflect changes in
							legislation, keeping your payroll compliant without manual
							intervention.
						</StyleTypography>
					</li>
					<li>
						<StyleTypography color={'#000'} fontSize={17} fontSizeMedium={16}>
							<strong>Comprehensive Record Keeping:</strong> <br />
							All transactions are logged and accessible, aligning with UK
							record-keeping laws. More on keeping records can be found at{' '}
							<Link
								href="https://www.gov.uk/running-payroll/keeping-records"
								target="_blank"
								rel="noopener"
							>
								Keeping Payroll Records
							</Link>
							.
						</StyleTypography>
					</li>
					<li>
						<StyleTypography color={'#000'} fontSize={17} fontSizeMedium={16}>
							<strong>Pension Automatic Enrolment:</strong> Automatic
							assessments and contribution management for employee pensions as
							per Automatic Enrolment regulations.
						</StyleTypography>
					</li>
				</ul>
			</div>

			<div style={{ width: '100%' }}>
				<div>
					<StyleButton
						fullWidth={false}
						onClick={() =>
							basicSetup({
								employers: {
									step: 'compliance',
								},
							})
						}
					>
						Continue to Setup
					</StyleButton>
				</div>
			</div>
		</div>
	);
};

export default StepThreeCompliance;
