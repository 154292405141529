import TextField from '@mui/material/TextField';
import { InputLabel, MenuItem, Popover, Select } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import StyleTypography from '../../../StyledComponents/StyleTypography';
import FormControl from '@mui/material/FormControl';
import HelpIcon from '@mui/icons-material/Help';
import { useState } from 'react';
import Typography from '@mui/material/Typography';

export const NewEmployeeWithoutP45 = ({
	employeeInformation,
	setEmployeeInformation,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const regexTaxCode =
		/^(?:[CS])?(([1-9][0-9]{0,5}[LMNPTY])|(BR)|(0T)|(NT)|(D[0-8])|([K][1-9][0-9]{0,5}))$/;

	return (
		<>
			{/*Starter Declaration*/}
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					gap: '32px',
					width: '100%',
				}}
			>
				{/*Starter Declaration*/}
				<div
					style={{
						flex: '0 0 calc(100% * (4/12))',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<div>
							<InputLabel className={'default-input-label'}>
								Starter Declaration*
							</InputLabel>
						</div>
						<div
							style={{ cursor: 'pointer' }}
							onMouseEnter={handlePopoverOpen}
							onMouseLeave={handlePopoverClose}
						>
							<HelpIcon fontSize={'small'} />
						</div>
					</div>
					<FormControl fullWidth>
						<Select
							className={'default-select'}
							id={'select-title'}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 200, // Adjust the maximum height of the list
									},
								},
							}}
							value={employeeInformation.starter_declaration}
							onChange={(event) => {
								if (event.target.value === 'first_job') {
									setEmployeeInformation({
										...employeeInformation,
										starter_declaration: event.target.value,
										is_cumulative: 'true',
										tax_code: '1257L',
									});
								} else if (event.target.value === 'only_job') {
									setEmployeeInformation({
										...employeeInformation,
										starter_declaration: event.target.value,
										is_cumulative: 'false',
										tax_code: '1257L',
									});
								} else if (event.target.value === 'other_job') {
									setEmployeeInformation({
										...employeeInformation,
										starter_declaration: event.target.value,
										is_cumulative: 'true',
										tax_code: 'BR',
									});
								}
							}}
						>
							<MenuItem key={'select-title'} value={' '} disabled>
								Select
							</MenuItem>
							<MenuItem key={'first_job'} value={'first_job'}>
								First job since start of tax year
							</MenuItem>
							<MenuItem key={'only_job'} value={'only_job'}>
								Currently only job
							</MenuItem>
							<MenuItem key={'other_job'} value={'other_job'}>
								Got another job or pension
							</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>

			{/*Tax Information*/}
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					gap: '32px',
					width: '100%',
				}}
			>
				{/*Tax Code*/}
				<div
					style={{
						flex: '0 0 calc(100% * (4/12))',
					}}
				>
					<InputLabel className={'default-input-label'}>Tax Code*</InputLabel>
					<TextField
						fullWidth
						className={'default-text-field'}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								tax_code: event.target.value,
							});
						}}
						error={!regexTaxCode.test(employeeInformation.tax_code)}
						value={employeeInformation.tax_code}
					/>
				</div>

				{/*Tax Basis*/}
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						width: 'calc(100% * (4/12))',
					}}
				>
					<InputLabel className={'default-input-label'}>Tax Basis*</InputLabel>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<RadioGroup
							onChange={(event) => {
								setEmployeeInformation({
									...employeeInformation,
									is_cumulative: event.target.value,
								});
							}}
							value={employeeInformation.is_cumulative}
							row
						>
							<FormControlLabel
								value={'true'}
								control={<Radio />}
								label={
									<StyleTypography
										fontSize={18}
										fontSizeMedium={13}
										color={'#000000'}
									>
										Cumulative
									</StyleTypography>
								}
							/>
							<FormControlLabel
								value={'false'}
								control={<Radio />}
								label={
									<StyleTypography
										fontSize={18}
										fontSizeMedium={13}
										color={'#000000'}
									>
										Non-Cumulative (W1/M1)
									</StyleTypography>
								}
							/>
						</RadioGroup>
					</div>
				</div>
			</div>

			<Popover
				id="mouse-over-popover"
				sx={{
					pointerEvents: 'none',
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography sx={{ p: 1 }}>
					<strong>Which statement applies to this new starter ?</strong>
					<br />
					<br />
					<li>
						Select the statement that best describes the new starters' present
						circumstances. <br />
						This information will determine which tax code should be used.
					</li>
					<br />
					<li>
						If you are unable to obtain this information from the employee, you
						should choose <br />
						the last statement and then amend the tax code to 0T on a week
						1/month 1 basis <br />
						in the appropriate section.
					</li>
				</Typography>
			</Popover>
		</>
	);
};
