import React, {useEffect, useState} from "react";
import {
	Divider,
	FreepayrollButton,
	FreepayrollSelect,
	FreepayrollTextField,
	Typography
} from "@collegia-partners/ui-kit";
import {
	useUpdateEmployee
} from "../../../../../hooks/employees";
import {useGetEmployee} from "../../../../../hooks/employee/useGetEmployee";
import {isTaxCodeValid} from "../../../../../utils/Helpers";
import {useParams} from "react-router-dom";
import styles from "../../../../../components/common/FPTable/styles.module.scss";
import Money from "../../../../../images/svg/money-icon.svg";
import Skeleton from "../../../../../components/Skeleton";

function InformationItem({
	title,
	subtitle,
}) {

	const style = {
		display: "flex",
		alignItems: "start",
		backgroundColor: "#f8f9fd",
		padding: "1.5vw",
	}

	return (
		<div style={style}>
			<img alt={"logo"} src={Money}/>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "0.5vw",
				marginLeft: "0.5vw",

			}}>
				<Typography variant={"subtitle"} color={"black"} className={styles.Typography}>{title}</Typography>
				<Typography variant={"subtitle2"} color={"gray"} weight={"normal"}>{subtitle}</Typography>
			</div>
		</div>
	);
}

function InformationSide() {

	const InformationBoxes = [
		{
			title: "Tax code",
			subtitle: "Enter the employee’s tax code. If you receive a letter from HMRC, " +
				"please update the tax code accordingly.",
		},
		{
			title: "Previous taxable pay",
			subtitle: "If a new employee with a P45 was chosen as the starter type, please state" +
				" the employee’s previous taxable pay (before current employment) from their " +
				"P45 statement.",
		},
		{
			title: "Previous Tax Paid",
			subtitle: "If a new employee with a P45 was chosen as the starter type, please state the " +
				"employee’s previous tax paid (before current employment) from their P45 statement.",
		},
	];

	return (
		<div>
			{
				InformationBoxes.map((box, index) => (
					<>
						<InformationItem
							key={index}
							title={box.title}
							subtitle={box.subtitle}
						/>
						{
							index !== InformationBoxes.length - 1 &&
							<Divider color={"light-gray"} />
						}
					</>
				))
			}
		</div>
	);
}

function TaxFields() {

	const employeeId = useParams().employeeId;

	const { employee, isPending } = useGetEmployee({ id: employeeId, relations: ['ytd_figure'] });

	const { mutateAsync: updateEmployee, isPending: isSaving } = useUpdateEmployee();

	const [employeeTaxInfo, setEmployeeTaxInfo] = useState({
		starter_type: "",
		starter_declaration: "",
		tax_code: "",
		is_cumulative: "",
		previous_taxable_pay: "",
		previous_tax_paid: "",
	});

	const initialState = {
		starter_type: employee?.starter_type,
		starter_declaration: employee?.starter_declaration,
		tax_code: employee?.tax_code,
		is_cumulative: employee?.is_cumulative,
		previous_taxable_pay: employee?.ytd_figure?.previous_employment_taxable_pay || "",
		previous_tax_paid: employee?.ytd_figure?.previous_employment_tax_deducted || "",
	}

	const starterTypeOptions = [
		{ value: "existing_employee", label: "Existing employee" },
		{ value: "new_without_p45", label: "New employee without a P45" },
		{ value: "new_with_p45", label: "New employee with a P45" },
	];

	const starterDeclarationOptions = [
		{ value: "first_job", label: "First job since 6th of April" },
		{ value: "only_job", label: "Currently only job" },
		{ value: "other_job", label: "Got another job or pension" },
	];

	const taxBasisOptions = [
		{ value: "true", label: "Cumulative" },
		{ value: "false", label: "Week 1 / Month 1" },
	];

	useEffect(() => {
		setEmployeeTaxInfo({
			starter_type: employee?.starter_type,
			starter_declaration: employee?.starter_declaration,
			tax_code: employee?.tax_code,
			is_cumulative: employee?.is_cumulative?.toString(),
			previous_taxable_pay: employee?.ytd_figure?.previous_employment_taxable_pay || "",
			previous_tax_paid: employee?.ytd_figure?.previous_employment_tax_deducted || "",
		});
	}, [employee]);

	if (isPending) {
		return <Skeleton width={'100%'} />;
	}

	return (
		<div>
			<FreepayrollSelect
				label={"Starter type"}
				options={starterTypeOptions}
				value={employeeTaxInfo.starter_type}
				disabled={employee?.fps_submitted}
				onSelect={(option) => {
					if (option.value !== employee?.starter_type) {
						setEmployeeTaxInfo({
							...initialState,
							starter_type: option.value
						});
						return;
					}
					setEmployeeTaxInfo({
						...employeeTaxInfo,
						starter_type: option.value,
					});
				}}
				size={'small'}
			/>
			{
				employeeTaxInfo.starter_type === "new_without_p45" &&
				<FreepayrollSelect
					label={"Starter declaration"}
					options={starterDeclarationOptions}
					value={employeeTaxInfo.starter_declaration}
					onSelect={(option) => {
						setEmployeeTaxInfo({
							...employeeTaxInfo,
							starter_declaration: option.value,
						});
					}}
					size={'small'}
				/>
			}
			<div style={{display: "flex", gap: "1.8vw"}}>
				<FreepayrollTextField
					fieldType={"text"}
					name={"tax_code"}
					label={"Tax code"}
					value={employeeTaxInfo.tax_code}
					error={!isTaxCodeValid(employeeTaxInfo.tax_code)}
					onChange={(e) => {
						setEmployeeTaxInfo({
							...employeeTaxInfo,
							tax_code: e.target.value,
						});
					}}
				/>
				<FreepayrollSelect
					label={"Tax basis"}
					options={taxBasisOptions}
					value={employeeTaxInfo?.is_cumulative?.toString()}
					onSelect={(option) => {
						setEmployeeTaxInfo({
							...employeeTaxInfo,
							is_cumulative: option?.value,
						});
					}}
					size={'small'}
				/>
			</div>
			{
				employeeTaxInfo.starter_type === "new_with_p45" &&
					<div style={{display: "flex", gap: "1.8vw"}}>
						<FreepayrollTextField
							fieldType={"number"}
							name={"previous_taxable_pay"}
							label={"Previous taxable pay"}
							onChange={(e) => {
								setEmployeeTaxInfo({
									...employeeTaxInfo,
									previous_taxable_pay: e.target.value,
								});
							}}
							value={employeeTaxInfo.previous_taxable_pay}
							error={employeeTaxInfo.previous_taxable_pay === ""}
							maxDecimalPlaces={2}
							customStartAdornment={
								<Typography variant={"subtitle2"} color={"black"} weight={"normal"}>£</Typography>
							}
						/>
						<FreepayrollTextField
							fieldType={"number"}
							name={"previous_tax_paid"}
							label={"Previous tax paid"}
							maxDecimalPlaces={2}
							onChange={(e) => {
								setEmployeeTaxInfo({
									...employeeTaxInfo,
									previous_tax_paid: e.target.value,
								});
							}}
							value={employeeTaxInfo.previous_tax_paid}
							error={employeeTaxInfo.previous_tax_paid === ""}
							customStartAdornment={
								<Typography variant={"subtitle2"} color={"black"} weight={"normal"}>£</Typography>
							}
						/>
					</div>
			}
			<div style={{display: 'flex', gap: "0.8vw", flexDirection: "row"}}>
				<FreepayrollButton
					variant={"white-button"}
					onClick={() => setEmployeeTaxInfo(initialState)}
					isLoading={isSaving}
				>
					Cancel
				</FreepayrollButton>
				<FreepayrollButton
					variant={"primary"}
					isLoading={isSaving}
					onClick={async () => {
						try {
							await updateEmployee({
								safeId: employee.safe_id,
								data: {
									employees: {
										action: 'taxes',
										starter_type: employeeTaxInfo.starter_type,
										starter_declaration: employeeTaxInfo.starter_declaration,
										tax_code: employeeTaxInfo.tax_code,
										is_cumulative: employeeTaxInfo.is_cumulative,
										previous_taxable_pay: employeeTaxInfo.previous_taxable_pay,
										previous_tax_paid: employeeTaxInfo.previous_tax_paid,
									}
								}
							});
						} catch (e) {
							console.error(e);
						}
					}}
					disabled={
						!isTaxCodeValid(employeeTaxInfo.tax_code) ||
						(employeeTaxInfo.starter_type === "new_with_p45" &&
							(employeeTaxInfo.previous_taxable_pay === "" || employeeTaxInfo.previous_tax_paid === ""))
					}
				>
					Save
				</FreepayrollButton>
			</div>
		</div>
	);
}

export const TaxesTab = () => {

	return (
		<div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "3vw"}}>
			<TaxFields/>
			<InformationSide/>
		</div>
	);
};
