import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import countryList from "react-select-country-list";
import {
	Chip,
	FreepayrollButton,
	FreepayrollDateField,
	FreepayrollSelect,
	FreepayrollTextField,
	Modal,
	Typography
} from "@collegia-partners/ui-kit";
import {
	useGetNiCategories,
	useUpdateDirectorDetails,
	useUpdateEmployee
} from "../../../../../hooks/employees";
import {useGetEmployee, useVerifyDuplicateEmployee} from "../../../../../hooks/employee/useGetEmployee";
import moment from "moment";
import {validateEmail} from "../../../../../utils/Helpers";
import {useParams} from "react-router-dom";
import Skeleton from "../../../../../components/Skeleton";

function EmployeeRecordItem({
    width = "1fr",
    label,
    value,
    onChange,
    fieldType = "text",
    error = false,
	options = [],
	errorMessage = "",
	maxLength = 100,
	isLoading = false,
}) {
	return (
		<div style={{ gridColumn: `span ${width === "2fr" ? 2 : 1}` }}>
			{
				fieldType === "select" && (
					<FreepayrollSelect
						options={options}
						value={value}
						label={label}
						onSelect={onChange}
					/>
				)
			}
			{
				fieldType === "text" && (
					<FreepayrollTextField
						fieldType={"text"}
						label={label}
						name={label}
						value={value}
						onChange={onChange}
						error={error}
						helperText={errorMessage}
						maxLength={maxLength}
						isLoading={isLoading}
					/>
				)
			}
			{
				fieldType === "date" && (
					<FreepayrollDateField
						label={label}
						value={value}
						onChange={onChange}
					/>
				)
			}
		</div>
	);
}

function DirectorComponent({
	employeeRecord,
	setOpen,
}) {
	return (
		<div style={{display: "grid", gridColumn: "span 2", paddingBottom: "1.83vw"}}>
			{
				employeeRecord?.is_director ?
					<button
						onClick={() => setOpen(true)}
						style={{all: "unset", cursor: "pointer"}}
					>
						<Chip color={"black"} cursor={"pointer"}>
							{
								employeeRecord?.active_director_detail?.end_date !== null ?
									"Directorship will end at the end of the current tax year"
									:
									"Appointed as director"
							}
						</Chip>
					</button>
					:
					<button
						onClick={() => setOpen(true)}
						style={{all: "unset", cursor: "pointer"}}
					>
						<Chip color={"black"} cursor={"pointer"}>
							Set as director
						</Chip>
					</button>
			}
		</div>
	);
}

function DirectorModal({
   open,
   setOpen,
   employeeRecord,
}) {
	
	const {mutateAsync: updateDirector, isPending} = useUpdateDirectorDetails();
	
	const [directorStartDate, setDirectorStartDate] = useState(null);
	const [directorEndDate, setDirectorEndDate] = useState(null);
	const [directorCalcMethod, setDirectorCalcMethod] = useState(null);
	
	const directorCalcOptions = [
		{ label: "Standard Annual Method", value: 'true' },
		{ label: "Alternative Method", value: 'false' },
	];
	
	useEffect(() => {
		if (employeeRecord) {
			setDirectorStartDate(employeeRecord?.active_director_detail?.start_date ?? null);
			setDirectorEndDate(employeeRecord?.active_director_detail?.end_date ?? null);
			setDirectorCalcMethod(employeeRecord?.active_director_detail?.cumulative_calculation?.toString() ?? null);
		}
	}, [employeeRecord]);
	
	return (
		<Modal isOpen={open} onClose={() => setOpen(false)} size={"md"} >
			<div style={{ display:'flex', gap: "0.8vw", flexDirection:"column" }}>
				<Typography variant={"title"}>
					{ employeeRecord?.is_director ? "Edit director details" : "Set director details" }
				</Typography>
				<div>
					<div style={{display: 'flex', gap: "0.8vw", flexDirection: "row"}}>
						<FreepayrollDateField
							label={"Director start date"}
							disabled={employeeRecord?.active_director_detail?.locked}
							value={moment(directorStartDate)}
							onChange={(newValue) => {
								setDirectorStartDate(newValue.format("YYYY-MM-DD"));
							}}
						/>
						<FreepayrollDateField
							label={"Director end date (Leave empty if still active)"}
							value={moment(directorEndDate)}
							onChange={(newValue) => {
								setDirectorEndDate(newValue.format("YYYY-MM-DD"));
							}}
						/>
					</div>
					<FreepayrollSelect
						options={directorCalcOptions}
						value={directorCalcMethod}
						label={"Director calculation method"}
						disabled={employeeRecord?.active_director_detail?.locked}
						onSelect={(option) => setDirectorCalcMethod(option.value)}
					/>
				</div>
				<div style={{display: 'flex', gap: "0.8vw", flexDirection: "row"}}>
					<FreepayrollButton
						variant={"white-button"}
						isLoading={isPending}
						onClick={() => setOpen(false)}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						isLoading={isPending}
						disabled={
							!directorStartDate ||
							!directorCalcMethod
						}
						onClick={async () => {
							try {
								await updateDirector({
									safeId: employeeRecord?.safe_id,
									data: {
										employees : {
											start_date: directorStartDate,
											end_date: directorEndDate,
											cumulative_calculation: directorCalcMethod,
										}
									}
								});
								setOpen(false);
							} catch (e) {
								console.error(e);
							}
						}}
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	)
}

export const GeneralTab = () => {

	const employeeId = useParams().employeeId;
	const { employee, isPending: isLoadingEmployee } = useGetEmployee({ id: employeeId, relations: ['address','active_director_detail'] });

	const { niCategories } = useGetNiCategories();

	const {
		mutateAsync: verifyDuplicateEmployeeMutate, isPending: isLoading,
	} = useVerifyDuplicateEmployee();

	const { mutate: updateEmployee, isPending } = useUpdateEmployee();

	const [employeeRecord, setEmployeeRecord] = useState(employee);
	const [openDirectorModal, setOpenDirectorModal] = useState(false);

	const countries = useMemo(() => countryList().getData(), []);
	const ninValidator = /^([ACEHJLMOPRSWXY][A-CEGHJ-NPR-TW-Z]|B[A-CEHJ-NPR-TW-Z]|G[ACEGHJ-NPR-TW-Z]|[KT][A-CEGHJ-MPR-TW-Z]|N[A-CEGHJL-NPR-SW-Z]|Z[A-CEGHJ-NPR-TW-Y])[0-9]{6}[A-D ]$/i;

	const niCategoriesOptions = useMemo(() => {
		return niCategories?.map((category) => ({
			label: `${category.category}  - ${category.description}` ,
			value: category.category,
		}));
	}, [niCategories]);

	const genderOptions = [
		{ label: "Male", value: "M" },
		{ label: "Female", value: "F" },
	];

	const titleOptions = [
		{ label: "Mr", value: "Mr" },
		{ label: "Mrs", value: "Mrs" },
		{ label: "Miss", value: "Miss" },
		{ label: "Ms", value: "Ms" },
	];

	const [emailDuplicateMessage, setEmailDuplicateMessage] = useState("");
	const [payrollIdDuplicateMessage, setPayrollIdDuplicateMessage] = useState("");

	useEffect(() => {
		setEmployeeRecord(employee);
	}, [employee]);

	const EmployeeDetailsLeft = [
		{
			label: "Forename",
			value: employeeRecord?.forename,
			onChange: (e) => {
				setEmployeeRecord({ ...employeeRecord, forename: e.target.value });
			},
		},
		{
			label: "Surname",
			value: employeeRecord?.surname,
			onChange: (e) => {
				setEmployeeRecord({ ...employeeRecord, surname: e.target.value });
			},
		},
		{
			label: "Title",
			value: employeeRecord?.title,
			onChange: (option) => {
				setEmployeeRecord({ ...employeeRecord, title: option.value });
			},
			options: titleOptions,
			fieldType: "select",
		},
		{
			label: "Gender",
			value: employeeRecord?.gender,
			onChange: (option) => {
				setEmployeeRecord({ ...employeeRecord, gender: option.value });
			},
			options: genderOptions,
			fieldType: "select",
		},
		{
			label: "Birthdate",
			value: moment(employeeRecord?.birthdate),
			onChange: (newValue) => {
				setEmployeeRecord({ ...employeeRecord, birthdate: newValue.format("YYYY-MM-DD") });
			},
			fieldType: "date",
		},
		{
			label: "NI Number",
			value: employeeRecord?.national_insurance_number,
			onChange: (e) => {
				setEmployeeRecord({
					...employeeRecord,
					national_insurance_number: e.target.value,
				});
			},
			error:
				!ninValidator.test(employeeRecord?.national_insurance_number) &&
				employeeRecord?.national_insurance_number !== "" &&
				employeeRecord?.national_insurance_number !== null,
		},
		{
			label: "NI Category",
			value: employeeRecord?.ni_category,
			onChange: (option) => {
				setEmployeeRecord({ ...employeeRecord, ni_category: option.value });
			},
			fieldType: "select",
			width: "2fr",
			options: niCategoriesOptions,
		},
		{
			label: "Employment Start Date",
			value: moment(employeeRecord?.join_date),
			onChange: (newValue) => {
				setEmployeeRecord({ ...employeeRecord, join_date: newValue.format("YYYY-MM-DD") });
			},
			fieldType: "date",
			width: "2fr",
		},
		{
			label: "Payroll ID",
			value: employeeRecord?.payroll_id,
			onChange: (e) => {
				setEmployeeRecord({ ...employeeRecord, payroll_id: e.target.value, duplicatedEmployeePayrollId: false });
				setPayrollIdDuplicateMessage("");
			},
			error: employeeRecord?.payroll_id === "" || employeeRecord?.payroll_id === null || employeeRecord?.duplicatedEmployeePayrollId,
			width: "1fr",
			errorMessage: payrollIdDuplicateMessage,
			isLoading: isLoading,
		},
	];

	const EmployeeDetailsRight = [
		{
			label: "Email",
			value: employeeRecord?.email,
			onChange: (e) => {
				setEmployeeRecord({ ...employeeRecord, email: e.target.value, duplicatedEmployeeEmail: false });
				setEmailDuplicateMessage("");
			},
			width: "2fr",
			error: (!validateEmail(employeeRecord?.email) && employeeRecord?.email !== "") ||
				employeeRecord?.duplicatedEmployeeEmail,
			errorMessage: emailDuplicateMessage,
			isLoading: isLoading,
		},
		{
			label: "Surname",
			value: employeeRecord?.surname,
			onChange: (e) => {
				setEmployeeRecord({ ...employeeRecord, surname: e.target.value });
			},
			width: "2fr",
		},
		{
			label: "Address Line 1",
			value: employeeRecord?.address?.address_line_1,
			maxLength: 35,
			onChange: (e) => {
				setEmployeeRecord({
					...employeeRecord,
					address: {
						...employeeRecord.address,
						address_line_1: e.target.value
					}
				});
			},
			width: "2fr",
		},
		{
			label: "Address Line 2",
			value: employeeRecord?.address?.address_line_2,
			maxLength: 35,
			onChange: (e) => {
				setEmployeeRecord({
					...employeeRecord,
					address: {
						...employeeRecord.address,
						address_line_2: e.target.value
					}
				});
			},
			width: "2fr",
		},
		{
			label: "City",
			maxLength: 35,
			value: employeeRecord?.address?.city,
			onChange: (e) => {
				setEmployeeRecord({
					...employeeRecord,
					address: {
						...employeeRecord.address,
						city: e.target.value
					}
				});
			},
		},
		{
			label: "Postal Code",
			maxLength: 8,
			value: employeeRecord?.address?.postal_code,
			onChange: (e) => {
				setEmployeeRecord({
					...employeeRecord,
					address: {
						...employeeRecord.address,
						postal_code: e.target.value
					}
				});
			},
		},
		{
			label: "Country",
			value: employeeRecord?.address?.country,
			onChange: (option) => {
				setEmployeeRecord({
					...employeeRecord,
					address: {
						...employeeRecord.address,
						country: option.value
					}
				});
			},
			options: countries,
			fieldType: "select",
			width: "2fr",
		},
	];

	let timeout = useRef(null);


	const verifyDuplicateEmployee = useCallback(async (email, payroll_id) => {
		try {
			const { exist_employee_email, exist_employee_payroll_id } = await verifyDuplicateEmployeeMutate({
				employees: {
					email: email,
					payroll_id: payroll_id,
					employee_id: employee?.id,
				},
			});
			if (exist_employee_email) {
				setEmailDuplicateMessage("Email already exists");
				setEmployeeRecord((prevInfo) => ({
					...prevInfo,
					duplicatedEmployeeEmail: exist_employee_email,
				}));
			} else if (exist_employee_payroll_id) {
				setPayrollIdDuplicateMessage("Payroll id already exists");
				setEmployeeRecord((prevInfo) => ({
					...prevInfo,
					duplicatedEmployeePayrollId: exist_employee_payroll_id,
				}));
			}
		} catch (e) {
			console.error(e);
		}
	}, [employee?.id, verifyDuplicateEmployeeMutate]);

	useEffect(() => {
		if (employeeRecord?.email !== '' && validateEmail(employeeRecord?.email)) {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
			timeout.current = setTimeout(() => {
				verifyDuplicateEmployee(employeeRecord?.email, null);
			}, 1000);
		}

		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	}, [employeeRecord?.email, verifyDuplicateEmployee]);

	useEffect(() => {
		if (employeeRecord?.payroll_id !== '') {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
			timeout.current = setTimeout(() => {
				verifyDuplicateEmployee(null, employeeRecord?.payroll_id);
			}, 1000);
		}

		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	}, [employeeRecord?.payroll_id, verifyDuplicateEmployee]);

	if (isLoadingEmployee) {
		return (
			<div style={{ display: 'flex'}} >
				<Skeleton width={"100%"} height={'15vw'} />
			</div>
		);
	}

	return (
		<div style={{display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "3vw"}}>
			<DirectorComponent employeeRecord={employeeRecord} setOpen={setOpenDirectorModal} />
			<div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px"}}>
				{EmployeeDetailsLeft.map((item, index) => (
					<EmployeeRecordItem key={index} {...item} />
				))}
			</div>
			<div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px"}}>
				{EmployeeDetailsRight.map((item, index) => (
					<EmployeeRecordItem key={index} {...item} />
				))}
			</div>
			<div style={{ display:'flex', gap: "2vw", marginTop: "40px" }} >
				<FreepayrollButton
					variant={"white-button"}
					isLoading={isPending}
					onClick={() => {
						setEmployeeRecord(employee);
					}}
				>
					Cancel
				</FreepayrollButton>
				<FreepayrollButton
					variant={"primary"}
					isLoading={isPending}
					onClick={() => {
						updateEmployee({
							safeId: employeeRecord?.safe_id,
							data: {
								employees: {
									'action': 'general',
									forename: employeeRecord.forename,
									surname: employeeRecord.surname,
									email: employeeRecord.email,
									title: employeeRecord.title,
									gender: employeeRecord.gender,
									birthdate: employeeRecord.birthdate,
									national_insurance_number: employeeRecord.national_insurance_number,
									address: employeeRecord.address,
									ni_category: employeeRecord.ni_category,
									telephone: employeeRecord.telephone,
									join_date: employeeRecord.join_date,
									payroll_id: employeeRecord.payroll_id,
									hmrc_id: employeeRecord.hmrc_id,
								}
							}
						});
					}}
					disabled={
						employeeRecord?.forename === "" ||
						employeeRecord?.surname === "" ||
						employeeRecord?.email === "" ||
						!validateEmail(employeeRecord?.email) ||
						employeeRecord?.title === "" ||
						employeeRecord?.gender === "" ||
						employeeRecord?.birthdate === "" ||
						employeeRecord?.ni_category === null ||
						employeeRecord?.duplicatedEmployeeEmail ||
						employeeRecord?.duplicatedEmployeePayrollId ||
						(employeeRecord?.national_insurance_number !== "" && employeeRecord?.national_insurance_number !== null && !ninValidator.test(employeeRecord.national_insurance_number))
					}
				>
					Save
				</FreepayrollButton>
			</div>
			<DirectorModal
				open={openDirectorModal}
				setOpen={setOpenDirectorModal}
				employeeRecord={employeeRecord}
			/>
		</div>
	);
};
