import { Modal, SelectEntity } from '@collegia-partners/ui-kit';
import { useCallback, useEffect, useState } from 'react';
import Request from '../../utils/Request';
import { useOnHold } from '../../hooks/employee/useOnHold';

export const EmployeeOnHold = ({ isOpen, setIsOpen }) => {
	const [query, setQuery] = useState('');
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [employees, setEmployees] = useState([]);
	const [entities, setEntities] = useState([]);
	const [selectedEmployees, setSelectedEmployees] = useState([]);
	const [unselectedEmployees, setUnselectedEmployees] = useState([]);
	const [isSelectedAll, setIsSelectedAll] = useState(false);
	const [isDeselectedAll, setIsDeselectedAll] = useState(false);
	const [isLoadingSearch, setIsLoadingSearch] = useState(false);
	const [pagination, setPagination] = useState(undefined);
	const { mutateAsync: onHold } = useOnHold();

	const onSubmit = useCallback(async () => {
		const data = {
			selected: selectedEmployees,
			unselected: unselectedEmployees,
			is_selected_all: isSelectedAll,
			is_deselected_all: isDeselectedAll,
		};

		try {
			await onHold(data)
			setIsOpen(false);
		} catch (error) {
			console.error(error);
		}
	}, [
		selectedEmployees,
		unselectedEmployees,
		isSelectedAll,
		isDeselectedAll,
		onHold,
		setIsOpen,
	]);

	useEffect(() => {
		if (!isOpen) {
			setEmployees([]);
			setEntities([]);
			setPage(1);
			setIsSelectedAll(false);
			setSelectedEmployees([]);
			setUnselectedEmployees([]);
			setQuery('');
			setSearch('');
			setPagination(undefined);
			setIsLoadingSearch(false);
			setIsDeselectedAll(false);
		}
	}, [isOpen]);

	useEffect(() => {
		setEntities(
			employees.map((employee) => ({
				id: employee.id,
				name: employee.name,
				is_checked: employee.status === 'On Hold',
			})),
		);
	}, [employees]);

	return (
		<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
			<SelectEntity
				entities={entities}
				isLoadingSearch={isLoadingSearch}
				pagination={pagination}
				setPage={setPage}
				page={page}
				setQuery={setQuery}
				query={query}
				setSearch={setSearch}
				search={search}
				selectedEntities={selectedEmployees}
				setSelectedEntities={setSelectedEmployees}
				unselectedEntities={unselectedEmployees}
				setUnselectedEntities={setUnselectedEmployees}
				isSelectedAll={isSelectedAll}
				setIsSelectedAll={setIsSelectedAll}
				isDeselectedAll={isDeselectedAll}
				setIsDeselectedAll={setIsDeselectedAll}
				onSubmit={onSubmit}
				title="Select Employees on Hold"
				paginationInfiniteScrollLoadParams={{
					api: Request,
					baseURL: process.env.REACT_APP_API || '',
					endpoint: '/api/employees/get-employees',
					page,
					shouldForceReload: page === 1,
					contentKey: 'data',
					search,
					setData: setEmployees,
					setIsLoading: setIsLoadingSearch,
					setPagination,
					customSearchParams: 'status=On Hold,Active',
				}}
			/>
		</Modal>
	);
};
