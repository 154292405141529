import React, {useEffect, useState} from "react";
import {
	FreepayrollButton, FreepayrollDateField,
	FreepayrollSelect,
	FreepayrollTextField,
	Typography
} from "@collegia-partners/ui-kit";
import {useGetEmployee} from "../../../../../hooks/employee/useGetEmployee";
import {useParams} from "react-router-dom";
import styles from "../../../../../components/common/FPTable/styles.module.scss";
import Skeleton from "../../../../../components/Skeleton";
import moment from "moment";
import {useAddParentalLeave} from "../../../../../hooks/employee/leaves";

function InformationItem({title, subtitle }) {

	const style = {
		display: "flex",
		alignItems: "start",
		backgroundColor: "#f8f9fd",
		padding: "1.5vw",
	}

	return (
		<div style={style}>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "0.5vw",
				marginLeft: "0.5vw",

			}}>
				<Typography variant={"subtitle"} color={"black"} className={styles.Typography}>{title}</Typography>
				<Typography variant={"subtitle2"} color={"gray"} weight={"normal"}>{subtitle}</Typography>
			</div>
		</div>
	);
}

function InformationSide() {

	const information = {
		title: "Statutory pay claims",
		subtitle: "If you have paid any Statutory Maternity Pay or\n" +
			"Statutory Paternity Pay, we will claim 92% of it from HMRC.\n" +
			"If you qualify for Small Employer's Relief, it will be 103%.",
	};

	return (
		<div>
			<InformationItem
				title={information.title}
				subtitle={information.subtitle}
			/>
		</div>
	);
}

function PaternityLeave({ employee, isPending }) {

	const { mutateAsync: addParentalLeave, isPending: isSaving } = useAddParentalLeave();

	const [employeeLaveInfo, setEmployeeLeaveInfo] = useState({
		expected_due_date: null,
		paternity_leave_type: "",
		first_start_date: null,
		first_end_date: null,
		second_start_date: null,
		second_end_date: null,
		average_weekly_earnings: "",
	});

	const leaveOptions = [
		{ value: "one_week", label: "My employee will take 1 week of leave" },
		{ value: "two_weeks", label: "My employee will take 2 weeks of leave in a row" },
		{ value: "two_weeks_separate_blocks", label: "My employee will take 2 weeks of leave separately" },
	];

	useEffect(() => {
		setEmployeeLeaveInfo({
			expected_due_date: employee?.parental_leave?.expected_due_date,
			paternity_leave_type: employee?.parental_leave?.paternity_leave_type,
			first_start_date: employee?.parental_leave?.first_start_date,
			first_end_date: employee?.parental_leave?.first_end_date,
			second_start_date: employee?.parental_leave?.second_start_date,
			second_end_date: employee?.parental_leave?.second_end_date,
			average_weekly_earnings: employee?.parental_leave?.average_weekly_earnings,
		});
	}, [employee]);

	if (isPending) {
		return <Skeleton width={'100%'} />;
	}

	return (
		<div style={{ display: "flex", flexDirection: 'column', gap: '1.23vw' }} >
			<FreepayrollDateField
				label={"Expected date of child birth"}
				value={moment(employeeLaveInfo.expected_due_date || null)}
				onChange={(newValue) => setEmployeeLeaveInfo({...employeeLaveInfo, expected_due_date: newValue.format("YYYY-MM-DD")})}
			/>
			<FreepayrollSelect
				label={"How will the employee take the leave?"}
				options={leaveOptions}
				value={employeeLaveInfo.paternity_leave_type}
				onSelect={(option) => {
					if (option.value === employeeLaveInfo.paternity_leave_type) return;
					setEmployeeLeaveInfo({
						...employeeLaveInfo,
						paternity_leave_type: option.value,
						first_start_date: "",
						first_end_date: "",
						second_start_date: "",
						second_end_date: "",
					});
				}}
			/>
			{
				employeeLaveInfo.paternity_leave_type !== undefined && (
					<>
						<Typography variant={"subtitle"} color={"black"} > First block of leave </Typography>
						<div style={{ display: 'flex', gap: "0.8vw" }} >
							<FreepayrollDateField
								label={"Leave start date"}
								value={moment(employeeLaveInfo.first_start_date || null)}
								onChange={(newValue) => {

									let endDate = '';
									const startDate = newValue.clone();

									if (employeeLaveInfo.paternity_leave_type === "one_week" || employeeLaveInfo.paternity_leave_type === "two_weeks_separate_blocks") {
										endDate = newValue.add(6, 'days');
									} else {
										endDate = newValue.add(13, 'days');
									}

									setEmployeeLeaveInfo({
										...employeeLaveInfo,
										first_start_date: startDate.format("YYYY-MM-DD"),
										first_end_date: endDate.format("YYYY-MM-DD"),
									})
								}}
							/>
							<FreepayrollDateField
								label={"Leave end date"}
								value={moment(employeeLaveInfo.first_end_date || null)}
								disabled={true}
							/>
						</div>
					</>
				)
			}
			{
				employeeLaveInfo.paternity_leave_type === 'two_weeks_separate_blocks' && (
					<>
						<Typography variant={"subtitle"} color={"black"} > Second block of leave </Typography>
						<div style={{display: 'flex', gap: "0.8vw", marginTop: '5px'}}>
							<FreepayrollDateField
								label={"Leave start date"}
								value={moment(employeeLaveInfo.second_start_date || null)}
								onChange={(newValue) => {
									let endDate = '';
									const startDate = newValue.clone();

									if (employeeLaveInfo.paternity_leave_type === "one_week" || employeeLaveInfo.paternity_leave_type === "two_weeks_separate_blocks") {
										endDate = newValue.add(6, 'days');
									} else {
										endDate = newValue.add(13, 'days');
									}

									setEmployeeLeaveInfo({
										...employeeLaveInfo,
										second_start_date: startDate.format("YYYY-MM-DD"),
										second_end_date: endDate.format("YYYY-MM-DD"),
									})
								}}
							/>
							<FreepayrollDateField
								label={"Leave end date"}
								value={moment(employeeLaveInfo.second_end_date || null)}
								disabled={true}
							/>
						</div>
					</>
				)
			}
			<Typography variant={"subtitle"} color={"gray"} weight={"normal"} >
				FreePayroll can only calculate the average weekly earnings, if we have at
				least 52 weeks of data. Otherwise, you will need to input manually.
			</Typography>
			<FreepayrollTextField
				fieldType={"number"}
				name={"average_weekly_earnings"}
				label={"Average weekly earnings"}
				onChange={(e) => {
					setEmployeeLeaveInfo({...employeeLaveInfo, average_weekly_earnings: e.target.value});
				}}
				value={employeeLaveInfo.average_weekly_earnings}
				maxDecimalPlaces={2}
				customStartAdornment={
					<Typography variant={"subtitle2"} color={"black"} weight={"normal"}>£</Typography>
				}
			/>
			<div style={{display: 'flex', gap: "0.8vw", flexDirection: "row"}}>
				<FreepayrollButton
					variant={"white-button"}
					isLoading={isSaving}
				>
					Cancel
				</FreepayrollButton>
				<FreepayrollButton
					variant={"primary"}
					isLoading={isSaving}
					disabled={
						!employeeLaveInfo.expected_due_date ||
						!employeeLaveInfo.paternity_leave_type ||
						!employeeLaveInfo.first_start_date ||
						!employeeLaveInfo.first_end_date ||
						!employeeLaveInfo.average_weekly_earnings ||
						(
							employeeLaveInfo.paternity_leave_type === 'two_weeks_separate_blocks' &&
							(!employeeLaveInfo.second_start_date || !employeeLaveInfo.second_end_date)
						)
					}
					onClick={async () => {
						try {
							await addParentalLeave({
								employees: {
									employee_safe_id: employee?.safe_id,
									'statutory_type': 'paternity',
									is_paternity: true,
									is_maternity: false,
									expected_due_date: employeeLaveInfo.expected_due_date,
									paternity_leave_type: employeeLaveInfo.paternity_leave_type,
									first_start_date: employeeLaveInfo.first_start_date,
									first_end_date: employeeLaveInfo.first_end_date,
									second_start_date: employeeLaveInfo.second_start_date,
									second_end_date: employeeLaveInfo.second_end_date,
									average_weekly_earnings: employeeLaveInfo.average_weekly_earnings,
								}
							});
						} catch (e) {
							console.error(e);
						}
					}}
				>
					Save
				</FreepayrollButton>
			</div>
		</div>
	);
}

function MaternityLeave({ employee, isPending }) {

	const { mutateAsync: addParentalLeave, isPending: isSaving } = useAddParentalLeave();

	const [employeeLaveInfo, setEmployeeLeaveInfo] = useState({
		expected_due_date: null,
		paternity_leave_type: "",
		first_start_date: null,
		first_end_date: null,
		average_weekly_earnings: "",
	});

	useEffect(() => {
		setEmployeeLeaveInfo({
			expected_due_date: employee?.parental_leave?.expected_due_date,
			paternity_leave_type: employee?.parental_leave?.paternity_leave_type,
			first_start_date: employee?.parental_leave?.first_start_date,
			first_end_date: employee?.parental_leave?.first_end_date,
			average_weekly_earnings: employee?.parental_leave?.average_weekly_earnings,
		});
	}, [employee]);

	if (isPending) {
		return <Skeleton width={'100%'} />;
	}

	return (
		<div style={{display: "flex", flexDirection: 'column', gap: '1.23vw'}}>
			<FreepayrollDateField
				label={"Expected date of child birth"}
				value={moment(employeeLaveInfo.expected_due_date || null)}
				onChange={(newValue) => setEmployeeLeaveInfo({
					...employeeLaveInfo,
					expected_due_date: newValue.format("YYYY-MM-DD"),
					first_end_date: null,
					first_start_date: null,
				})}
			/>
			<div style={{display: 'flex', gap: "0.8vw"}}>
				<FreepayrollDateField
					label={"Leave start date"}
					value={moment(employeeLaveInfo.first_start_date || null)}
					onChange={(newValue) => {
						setEmployeeLeaveInfo({
							...employeeLaveInfo,
							first_start_date: newValue.format("YYYY-MM-DD"),
							first_end_date: null,
						})
					}}
				/>
				<FreepayrollDateField
					label={"Leave end date"}
					value={moment(employeeLaveInfo.first_end_date || null)}
					onChange={(newValue) => {
						setEmployeeLeaveInfo({
							...employeeLaveInfo,
							first_end_date: newValue.format("YYYY-MM-DD"),
						})
					}}
					minDate={moment(employeeLaveInfo.first_start_date).add(1, 'weeks')}
					maxDate={moment(employeeLaveInfo.first_start_date).add(52, 'weeks')}
				/>
			</div>
			<Typography variant={"subtitle"} color={"gray"} weight={"normal"}>
				FreePayroll can only calculate the average weekly earnings, if we have at
				least 52 weeks of data. Otherwise, you will need to input manually.
			</Typography>
			<FreepayrollTextField
				fieldType={"number"}
				name={"average_weekly_earnings"}
				label={"Average weekly earnings"}
				onChange={(e) => {
					setEmployeeLeaveInfo({...employeeLaveInfo, average_weekly_earnings: e.target.value});
				}}
				value={employeeLaveInfo.average_weekly_earnings}
				maxDecimalPlaces={2}
				customStartAdornment={
					<Typography variant={"subtitle2"} color={"black"} weight={"normal"}>£</Typography>
				}
			/>
			<div style={{display: 'flex', gap: "0.8vw", flexDirection: "row"}}>
				<FreepayrollButton
					variant={"white-button"}
					isLoading={isSaving}
				>
					Cancel
				</FreepayrollButton>
				<FreepayrollButton
					variant={"primary"}
					isLoading={isSaving}
					disabled={
						!employeeLaveInfo.expected_due_date ||
						!employeeLaveInfo.first_start_date ||
						!employeeLaveInfo.first_end_date ||
						!employeeLaveInfo.average_weekly_earnings
					}
					onClick={async () => {
						try {
							await addParentalLeave({
								employees: {
									employee_safe_id: employee?.safe_id,
									'statutory_type': 'maternity',
									is_paternity: false,
									is_maternity: true,
									expected_due_date: employeeLaveInfo.expected_due_date,
									first_start_date: employeeLaveInfo.first_start_date,
									first_end_date: employeeLaveInfo.first_end_date,
									average_weekly_earnings: employeeLaveInfo.average_weekly_earnings,
								}
							});
						} catch (e) {
							console.error(e);
						}
					}}
				>
					Save
				</FreepayrollButton>
			</div>
		</div>
	);
}

export const ParentalLeaveTab = () => {

	const employeeId = useParams().employeeId;

	const { employee, isPending } = useGetEmployee({ id: employeeId, relations: ['parental_leave','leaves'] });

	return (
		<div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "3vw"}}>
			{
				employee?.gender === 'M' &&
					<PaternityLeave employee={employee} isPending={isPending} />
			}
			{
				employee?.gender === 'F' &&
					<MaternityLeave employee={employee} isPending={isPending} />
			}
			<InformationSide/>
		</div>
	);
};
