import StyleTypography from '../../StyledComponents/StyleTypography';
import { Avatar, Popover } from '@mui/material';
import React, { useState } from 'react';
import AddPayItemModal from './AddPayItemModal';
import EditPayItemModal from './EditPayItemModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { useEmailPayslip, useViewPayslip } from '../../../hooks/employees';

function renderSummaryLine(item, value, isDeduction = true) {
	return (
		<div
			className={'employee-summary-items'}
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				flexWrap: 'wrap',
				width: '100%',
			}}
		>
			<div>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={'#000000'}
					fontWeight={'normal'}
				>
					{item}
				</StyleTypography>
			</div>
			{isDeduction ? (
				<div>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={value > 0 ? '#FF0000' : '#000000'}
					>
						{parseFloat(value) > 0 && '('}
						{(value ?? 0.0)
							.toLocaleString('en-GB', {
								style: 'currency',
								currency: 'GBP',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})
							.replace('-', '')}
						{parseFloat(value) > 0 && ')'}
					</StyleTypography>
				</div>
			) : (
				<div>
					<StyleTypography fontSize={18} fontSizeMedium={13} color={'#000000'}>
						{(value ?? 0.0).toLocaleString('en-GB', {
							style: 'currency',
							currency: 'GBP',
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</StyleTypography>
				</div>
			)}
		</div>
	);
}

function renderSummaryPayItemLine(
	item,
	value,
	payItem,
	selectedPayItem,
	setSelectedPayItem,
	setShowEditPayItem,
	isDeduction,
) {
	return (
		<div
			className={'employee-summary-items'}
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				flexWrap: 'wrap',
				width: '100%',
			}}
		>
			<div
				onClick={() => {
					setSelectedPayItem(payItem);
					setShowEditPayItem(true);
				}}
				style={{
					cursor: 'pointer',
				}}
			>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={'#0360FD'}
					fontWeight={'medium'}
					className={'pay-item-edit'}
				>
					{item} {payItem.is_benefit_in_kind && '(BiK)'}
				</StyleTypography>
			</div>
			{isDeduction ? (
				<div>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={value > 0 ? '#FF0000' : '#000000'}
					>
						{parseFloat(value) > 0 && '('}
						{(value ?? 0.0)
							.toLocaleString('en-GB', {
								style: 'currency',
								currency: 'GBP',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})
							.replace('-', '')}
						{parseFloat(value) > 0 && ')'}
					</StyleTypography>
				</div>
			) : (
				<div>
					<StyleTypography fontSize={18} fontSizeMedium={13} color={'#000000'}>
						{(value ?? 0.0).toLocaleString('en-GB', {
							style: 'currency',
							currency: 'GBP',
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</StyleTypography>
				</div>
			)}
		</div>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeeSummary = ({
	setSelectedEmployee,
	selectedEmployee,
	setUpdateEmployeePayRuns,
	setEmployeePayRuns,
}) => {
	const [showAddPayItem, setShowAddPayItem] = useState(false);
	const [showEditPayItem, setShowEditPayItem] = useState(false);
	const [selectedPayItem, setSelectedPayItem] = useState({});
	const [popOver, setPopOver] = useState(false),
		[anchorEl, setAnchorEl] = useState(null);
	const push = useNavigate();

	const { mutate: viewPayslip, isPending: isOpeningPayslip } = useViewPayslip();
	const { mutate: emailPayslip, isPending: isSending } = useEmailPayslip();

	return (
		<div
			id={'employee-summary'}
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				alignContent: 'flex-start',
			}}
		>
			{/*Employee Header*/}
			<div style={{ width: '100%' }}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap',
					}}
				>
					<div
						style={{
							marginRight: '10px',
						}}
					>
						<Avatar>
							{' '}
							{selectedEmployee.employee.forename[0]}
							{selectedEmployee.employee.surname[0]}
						</Avatar>
					</div>
					<div>
						<StyleTypography
							fontSize={27}
							fontSizeMedium={20}
							className={'employee-name'}
							onClick={() =>
								push(`/main/people/record/${selectedEmployee.employee.safe_id}`)
							}
						>
							{selectedEmployee.employee.forename}{' '}
							{selectedEmployee.employee.surname}
						</StyleTypography>
						<StyleTypography
							fontSize={14}
							fontSizeMedium={12}
							color={'#000000'}
						>
							Tax Code{' '}
							<strong>
								{selectedEmployee.employee.tax_code}{' '}
								{!selectedEmployee.tax_basis_cumulative && '(W1/M1)'}
							</strong>
							| NI Cat <strong>{selectedEmployee.employee.ni_category}</strong>
						</StyleTypography>
					</div>
					<div
						style={{
							marginLeft: 'auto',
							textAlign: 'end',
						}}
					>
						<IconButton
							style={{ padding: 0 }}
							onClick={(event) => {
								setAnchorEl(event.currentTarget);
								setPopOver(true);
							}}
						>
							<MoreVertIcon />
						</IconButton>
					</div>
				</div>
				<Popover
					id={'popover'}
					open={popOver}
					anchorEl={anchorEl}
					onClose={() => {
						setAnchorEl(null);
						setPopOver(false);
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
				>
					<div>
						<div
							className={'popover-list-grid'}
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
							onClick={() => {
								emailPayslip({
									safeId: selectedEmployee.employee.safe_id,
									id: selectedEmployee.id,
								});
							}}
						>
							<StyleTypography
								fontSize={15}
								fontSizeMedium={13}
								color={'#000000'}
							>
								{isSending ? 'Sending...' : 'Email payslip'}
							</StyleTypography>
						</div>
						<div
							className={'popover-list-grid'}
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
							onClick={() => {
								viewPayslip({
									safeId: selectedEmployee.employee.safe_id,
									id: selectedEmployee.id,
								});
							}}
						>
							<StyleTypography
								fontSize={15}
								fontSizeMedium={13}
								color={'#000000'}
							>
								{isOpeningPayslip ? 'Opening...' : 'View payslip (Draft)'}
							</StyleTypography>
						</div>
					</div>
				</Popover>
			</div>

			<div
				style={{
					width: '100%',
					paddingTop: '20px',
				}}
			>
				<span
					className={'add-pay-item'}
					onClick={() => setShowAddPayItem(true)}
				>
					+ Add pay item
				</span>
			</div>

			{/*Summary*/}
			<div
				className={'employee-summary-items'}
				style={{
					width: '100%',
				}}
			>
				<StyleTypography
					fontSize={20}
					fontSizeMedium={14}
					fontWeight={'bold'}
					color={'#000000'}
				>
					Summary
				</StyleTypography>
			</div>

			{/*Base Pay*/}
			{renderSummaryLine('Base Pay', selectedEmployee.base_pay_amount, false)}

			{/*PG Student Loan*/}
			{selectedEmployee.pg_loan > 0 &&
				renderSummaryLine('PG Student Loan', selectedEmployee.pg_loan)}

			{/*Student Loan*/}
			{selectedEmployee.student_loan > 0 &&
				renderSummaryLine('Student Loan', selectedEmployee.student_loan)}

			{/*Statutory Maternity Pay*/}
			{selectedEmployee.smp > 0 &&
				renderSummaryLine(
					'Statutory Maternity Pay',
					selectedEmployee.smp,
					false,
				)}

			{/*Statutory Paternity Pay*/}
			{selectedEmployee.spp > 0 &&
				renderSummaryLine(
					'Statutory Paternity Pay',
					selectedEmployee.spp,
					false,
				)}

			{/*Statutory Sick Pay*/}
			{selectedEmployee.ssp > 0 &&
				renderSummaryLine('Statutory Sick Pay', selectedEmployee.ssp, false)}

			{selectedEmployee.pay_items &&
				selectedEmployee.pay_items
					.filter((item) => item.is_gross)
					.map((payItem) => {
						if (payItem.is_editable) {
							return renderSummaryPayItemLine(
								payItem.item_name,
								payItem.item_amount,
								payItem,
								selectedPayItem,
								setSelectedPayItem,
								setShowEditPayItem,
								payItem.item_type === 'Deduction',
							);
						} else {
							return renderSummaryLine(
								payItem.item_name,
								payItem.item_amount,
								payItem.item_type === 'Deduction',
							);
						}
					})}

			{/*Employee NIC*/}
			{renderSummaryLine('Employee NIC', selectedEmployee.employee_ni_deducted)}

			{/*PAYE Income Tax*/}
			{renderSummaryLine(
				'PAYE Income Tax',
				selectedEmployee.income_tax_deducted,
			)}

			{/*Employee Pension*/}
			{renderSummaryLine(
				'Employee Pension',
				selectedEmployee.employee_pension_deducted,
			)}

			{selectedEmployee.pay_items &&
				selectedEmployee.pay_items
					.filter((item) => item.is_net)
					.map((payItem) => {
						if (payItem.is_editable) {
							return renderSummaryPayItemLine(
								payItem.item_name,
								payItem.item_amount,
								payItem,
								selectedPayItem,
								setSelectedPayItem,
								setShowEditPayItem,
								payItem.item_type === 'Deduction',
							);
						} else {
							return renderSummaryLine(
								payItem.item_name,
								payItem.item_amount,
								payItem.item_type === 'Deduction',
							);
						}
					})}

			{/*Net Pay*/}
			{renderSummaryLine('Net Pay', selectedEmployee.net_pay, false)}

			{/*Employer*/}
			<div
				className={'employee-summary-title'}
				style={{
					width: '100%',
				}}
			>
				<StyleTypography
					fontSize={20}
					fontSizeMedium={14}
					fontWeight={'bold'}
					color={'#000000'}
				>
					Employer
				</StyleTypography>
			</div>

			{/*Employer NIC*/}
			{renderSummaryLine(
				'Employer NIC',
				selectedEmployee.employer_ni_deducted,
				false,
			)}

			{/*Employer Pension*/}
			{renderSummaryLine(
				'Employer Pension',
				selectedEmployee.employer_pension_deducted,
				false,
			)}

			<AddPayItemModal
				open={showAddPayItem}
				setOpen={setShowAddPayItem}
				selectedEmployee={selectedEmployee}
				setSelectedEmployee={setSelectedEmployee}
				setUpdateEmployeePayRuns={setUpdateEmployeePayRuns}
				setEmployeePayRuns={setEmployeePayRuns}
			/>

			<EditPayItemModal
				open={showEditPayItem}
				setOpen={setShowEditPayItem}
				selectedEmployee={selectedEmployee}
				setSelectedEmployee={setSelectedEmployee}
				selectedPayItem={selectedPayItem}
				setSelectedPayItem={setSelectedPayItem}
				setUpdateEmployeePayRuns={setUpdateEmployeePayRuns}
				setEmployeePayRuns={setEmployeePayRuns}
			/>
		</div>
	);
};

export default EmployeeSummary;
