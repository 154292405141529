import React, {useEffect, useState} from "react";
import {
	Chip,
	Divider,
	FreepayrollButton,
	FreepayrollSelect,
	FreepayrollTextField, Icon, Modal,
	Typography
} from "@collegia-partners/ui-kit";
import {
	useUpdateEmployee
} from "../../../../../hooks/employees";
import {useGetEmployee} from "../../../../../hooks/employee/useGetEmployee";
import {formatDateFromBackend, renderPensionStatusColor} from "../../../../../utils/Helpers";
import {useParams} from "react-router-dom";
import Skeleton from "../../../../../components/Skeleton";
import {AgeFromDateString} from "age-calculator";

function InformationItem({ title, subtitle, isChip = false}) {

	const style = {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	};

	return (
		<div style={style}>
			<Typography
				variant={"subtitle2"}
				weight={"normal"}
				color={"black"}
				size={"medium"}
			>
				{title}
			</Typography>
			{
				isChip ? (
					<Chip color={renderPensionStatusColor(subtitle)}>
						{subtitle}
					</Chip>
				) : (
					<Typography
						variant={"subtitle2"}
						weight={"bold"}
						color={"black"}
						size={"medium"}
					>
						{subtitle}
					</Typography>
				)
			}
		</div>
	);
}

function PensionSettings({employee, isPending}) {

	const [openEditModal, setOpenEditModal] = useState(false);

	const InformationBoxes = [
		{
			title: 'Pension provider',
			subtitle: 'Collegia Partners',
		},
		{
			title: "Pension Type",
			subtitle: employee?.pension_setting?.relief_at_source ?
				"Relief at source" : "Salary sacrifice",
		},
		{
			title: "Pension Calculation Basis",
			subtitle: employee?.pension_setting?.qualifying_earnings ?
				"Qualifying earnings" : "Pensionable earnings",
		},
		{
			title: "Eligible for tax relief",
			subtitle: employee?.pension_setting?.eligible_for_tax_relief === true ? 'Yes' : 'No',
		},
		{
			title: "Employee contribution",
			subtitle: `${employee?.pension_setting?.employee_rate_percentage ?? 'N/A'} %`
		},
		{
			title: "Employer contribution",
			subtitle: `${employee?.pension_setting?.employer_rate_percentage ?? 'N/A'} %`,
		},
	];

	const style = {
		display: "flex",
		flexDirection: "column",
		gap: "1.23vw",
		padding: "1.5vw",
		backgroundColor: "#f8f9fd",
	};

	return (
		<div style={style} >
			<PensionSettingsModal
				employee={employee}
				open={openEditModal}
				setOpen={setOpenEditModal}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					gap: "0.8vw",
					cursor: "pointer",
				}}
				onClick={() => setOpenEditModal(true)}
			>
				<Typography
					variant={"subtitle2"}
					weight={"bold"}
					color={"black"}
				>
					Edit
				</Typography>
				<Icon iconName={"pencil"} style={{ width: "1vw" }} />
			</div>
			<Divider color={"light-gray"} />
			{
				InformationBoxes.map((box, index) => (
					isPending ? (
						<Skeleton key={index} height={"2.5vw"} width={"100%"} background={'#D6D8E1'} />
					) : (
						<>
							<InformationItem
								key={index}
								title={box.title}
								subtitle={box.subtitle}
							/>
							{
								index !== InformationBoxes.length - 1 &&
								<Divider color={"light-gray"} />
							}
						</>
					)
				))
			}
		</div>
	);
}

function PensionAssessment({employee, isPending}) {

	const { mutateAsync: updateEmployee, isPending: isUpdating } = useUpdateEmployee();

	const InformationBoxes = [
		{
			title: 'Worker type',
			subtitle: employee?.pension_assessment?.worker_type,
		},
		{
			title: "Eligibility Date",
			subtitle: formatDateFromBackend(employee?.pension_assessment?.eligibility_date),
		},
		{
			title: "Age",
			subtitle: new AgeFromDateString(employee?.birthdate).age,
		},
		{
			title: "Status",
			subtitle: employee?.pension_assessment?.ae_status,
			isChip: true,
		},
		{
			title: "Enrolment date",
			subtitle: formatDateFromBackend(employee?.pension_assessment?.enrolment_date),
		},
	];

	const style = {
		display: "flex",
		flexDirection: "column",
		gap: "1.23vw",
		padding: "1.5vw",
		backgroundColor: "#f8f9fd",
	};

	return (
		<div style={style} >
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "0.8vw",
			}}
			>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}  >
					<Typography
						variant={"subtitle2"}
						weight={"bold"}
						color={"black"}
					>
						Eligibility assessment
					</Typography>
					{
						employee?.pension_assessment?.ae_status !== 'Enrolled' &&
						employee?.pension_assessment?.worker_type !== 'Eligible Job Holder' && (
							<FreepayrollButton
								onClick={() => updateEmployee({
									safeId: employee.safe_id,
									data: {
										employees: {
											'action': 'opt_in',
											opted_in: !employee.pension_assessment?.opted_in
										}
									}
								})}
								size={"small"}
								variant={"blue-button"}
								fullWidth={false}
								custom
								width={2.5}
								height={0.5}
								isLoading={isUpdating}
							>
								{employee?.pension_assessment?.opted_in ? "Don't opt in" : "Opt In"}
							</FreepayrollButton>
						)
					}
				</div>
				<Typography
					variant={"label"}
				>
					Eligible jobholders earn more than £833 per month, are aged between 22 and their State
					Pension age. They have to be enrolled into a qualifying workplace pension scheme.
				</Typography>
			</div>
			<Divider color={"light-gray"} />
			{
				InformationBoxes.map((box, index) => (
					isPending ? (
						<Skeleton key={index} height={"2.5vw"} width={"100%"} background={'#D6D8E1'} />
					) : (
						<>
							<InformationItem
								key={index}
								title={box.title}
								subtitle={box.subtitle}
								isChip={box.isChip || false}
							/>
							{
								index !== InformationBoxes.length - 1 &&
								<Divider color={"light-gray"} />
							}
						</>
					)
				))
			}
		</div>
	);
}

export const PensionTab = () => {

	const employeeId = useParams().employeeId;

	const { employee, isPending } = useGetEmployee({ id: employeeId, relations: ['pension_setting','pension_assessment'] });
	
	return (
		<div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "3vw"}}>
			<div style={{ display: "grid", gridColumn: 'span 2', rowGap: '10px' }} >
				<Typography
					variant={"subtitle"}
					size={"xmedium"}
				>
					How Auto Enrolment works on FreePayroll ?
				</Typography>
				<div style={{ marginRight: '25%', opacity: '0.7'}} >
					<Typography
						variant={"subtitle"}
						size={"medium"}
						weight={"normal"}
						color={"black"}
					>
						Every time you run payroll, our software assesses your workforce to identify any
						employees who are eligible job holders but are not yet enrolled in the
						pension scheme.

						After submitting payroll, we automatically communicate this data to
						Collegia Pension, ensuring a seamless enrollment process.
					</Typography>
				</div>
			</div>
			<PensionSettings employee={employee} isPending={isPending}/>
			<PensionAssessment employee={employee} isPending={isPending}/>
		</div>
	);
};

function PensionSettingsModal ({ employee, open, setOpen }) {

	const { mutateAsync: updateEmployee, isPending: isSaving } = useUpdateEmployee();

	const PensionTypeOptions = [
		{
			value: "true",
			label: "Relief at source",
		},
		{
			value: "false",
			label: "Salary sacrifice"
		}
	];

	const PensionCalculationOptions = [
		{
			value: "true",
			label: "Pensionable Earnings",
		},
		{
			value: "false",
			label: "Qualifying Earnings"
		}
	];

	const [pensionSettings, setPensionSettings] = useState({
		relief_at_source: employee?.pension_setting?.relief_at_source,
		salary_sacrifice: employee?.pension_setting?.relief_at_source?.toString() === "true" ? "false" : "true",
		pensionable_earnings: employee?.pension_setting?.pensionable_earnings,
		qualifying_earnings: employee?.pension_setting?.pensionable_earnings?.toString() === "true" ? "false" : "true",
		employee_rate_percentage: employee?.pension_setting?.employee_rate * 100,
		employer_rate_percentage: employee?.pension_setting?.employer_rate * 100,
		employee_rate: employee?.pension_setting?.employee_rate,
		employer_rate: employee?.pension_setting?.employer_rate
	});

	useEffect(() => {
		setPensionSettings({
			relief_at_source: employee?.pension_setting?.relief_at_source,
			salary_sacrifice: employee?.pension_setting?.relief_at_source?.toString() === "true" ? "false" : "true",
			pensionable_earnings: employee?.pension_setting?.pensionable_earnings,
			qualifying_earnings: employee?.pension_setting?.pensionable_earnings?.toString() === "true" ? "false" : "true",
			employee_rate_percentage: employee?.pension_setting?.employee_rate * 100,
			employer_rate_percentage: employee?.pension_setting?.employer_rate * 100,
			employee_rate: employee?.pension_setting?.employee_rate,
			employer_rate: employee?.pension_setting?.employer_rate
		});
	}, [employee, open]);

	return (
		<Modal isOpen={open} onClose={() => setOpen(false)}>
			<div style={{display: "flex", flexDirection: "column", gap: "1.23vw"}}>
				<Typography
					variant={"title"}
				>
					Edit pension settings
				</Typography>
				<FreepayrollSelect
					label={"Pension type"}
					onSelect={(option) => {
						setPensionSettings({
							...pensionSettings,
							relief_at_source: option.value,
							salary_sacrifice: option.value === "true" ? "false" : "true"
						});
					}}
					value={pensionSettings?.relief_at_source?.toString()}
					options={PensionTypeOptions}
				/>
				<FreepayrollSelect
					label={"Pension calculation"}
					onSelect={(option) => {
						setPensionSettings({
							...pensionSettings,
							pensionable_earnings: option.value,
							qualifying_earnings: option.value === "true" ? "false" : "true"
						});
					}}
					value={pensionSettings?.pensionable_earnings?.toString()}
					options={PensionCalculationOptions}
				/>
				<div style={{display: "flex", gap: "0.8vw"}}>
					<FreepayrollTextField
						name={"employee_rate_percentage"}
						fieldType={"number"}
						label={"Employee contribution"}
						value={employee?.pension_setting?.employee_rate_percentage}
						onChange={(e) => {
							setPensionSettings({
								...pensionSettings,
								employee_rate_percentage: e.target.value,
								employee_rate: Number(e.target.value) / 100
							});
						}}
						maxDecimalPlaces={2}
						customEndAdornment={
							<Typography variant={"subtitle2"} color={"black"} weight={"normal"}>
								%
							</Typography>
						}
						error={pensionSettings?.employee_rate_percentage < 5}
						helperText={pensionSettings?.employee_rate_percentage < 5 ? "Minimum 5%" : ""}
					/>
					<FreepayrollTextField
						name={"employer_rate_percentage"}
						fieldType={"number"}
						label={"Employer contribution"}
						maxDecimalPlaces={2}
						value={employee?.pension_setting?.employer_rate_percentage}
						onChange={(e) => {
							setPensionSettings({
								...pensionSettings,
								employer_rate_percentage: e.target.value,
								employer_rate: Number(e.target.value) / 100
							});
						}}
						error={pensionSettings?.employer_rate_percentage < 3}
						helperText={pensionSettings?.employer_rate_percentage < 3 ? "Minimum 3%" : ""}
						customEndAdornment={
							<Typography variant={"subtitle2"} color={"black"} weight={"normal"}>
								%
							</Typography>
						}
					/>
				</div>
				<div style={{display: 'flex', gap: "0.8vw", flexDirection: "row"}}>
					<FreepayrollButton
						variant={"white-button"}
						onClick={() => setOpen(false)}
						isLoading={isSaving}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						isLoading={isSaving}
						onClick={async () => {
							try {
								await updateEmployee({
									safeId: employee.safe_id,
									data: {
										employees: {
											'action': 'pension_settings',
											employee_rate: pensionSettings.employee_rate,
											employer_rate: pensionSettings.employer_rate,
											relief_at_source: pensionSettings.relief_at_source,
											pensionable_earnings: pensionSettings.pensionable_earnings,
											salary_sacrifice: pensionSettings.salary_sacrifice,
											qualifying_earnings: pensionSettings.qualifying_earnings
										}
									}
								});
								setOpen(false);
							} catch (e) {
								console.error(e);
							}
						}}
						disabled={
							Number(pensionSettings?.employer_rate_percentage) < 3 ||
							Number(pensionSettings?.employee_rate_percentage) < 5 ||
							(
								Number(pensionSettings?.employer_rate_percentage) + Number(pensionSettings?.employee_rate_percentage) > 100 ||
								Number(pensionSettings?.employer_rate_percentage) + Number(pensionSettings?.employee_rate_percentage) < 8
							)
						}
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	);
}
