import React, {useEffect, useState} from "react";
import StyleTypography from "../../../components/StyledComponents/StyleTypography";
import Loading from "../../../components/Loading";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import StyleButton from "../../../components/StyledComponents/StyleButton";
import {useUser} from "../../../context/UserContext";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {useCloseAccount} from "../../../hooks/user";
import {useNavigate} from "react-router-dom";
import {Typography, Divider} from "@collegia-partners/ui-kit";

function EditItem({
	title = "",
	onClick = () => {},
}) {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent:'space-between',
				backgroundColor: '#F4F6FB',
				padding: '1.5vw',
				cursor: 'pointer'
			}}
			onClick={onClick}
		>
			<div>
				<Typography
					variant={"subtitle2"}
					weight={'normal'}
					color={'black'}
				>
					{title}
				</Typography>
			</div>

			<div>
				<KeyboardArrowRight/>
			</div>
		</div>
	);
}

const EditPersonalInfo = () => {
	
	const {setLayoutProps} = useLoggedLayout();
	const { profileData: user, isPending: isLoadingUser } = useUser();
	const { mutate: closeAccount, isPending: isClosing } = useCloseAccount();
	const navigate = useNavigate();
	
	const [open, setOpen] = useState(false);

	const Items = [
		{
			title: "Change my email",
			onClick: () => {
				navigate('/main/settings/edit-email');
			}
		},
		{
			title: "Change my password",
			onClick: () => {
				navigate('/main/settings/edit-password');
			}
		},
		{
			title: "Two-factor authentication",
			onClick: () => {
				navigate('/main/settings/edit-2fa');
			}
		},
		{
			title: "Close account",
			onClick: () => {
				setOpen(true);
			}
		}
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'Home',
			showBackButton: true,
			backLink: '/main',
		});
	}, [setLayoutProps]);
	
	if (isClosing || isLoadingUser) {
		return <Loading/>;
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '1.33vw' }} >
			<div>
				<Typography
					variant={"title"}
					weight={"bold"}
				>
					Edit Personal Info
				</Typography>
				<Typography
					variant={"subtitle"}
					weight={'normal'}
					color={'gray'}
				>
					Here you can change your  credentials for your FreePayroll account.
				</Typography>
			</div>

			<Divider color={'light-gray'}/>

			<div style={{ width: '40%' }} >
				{
					Items.map((item, index) => {
						if (item.title === "Close account" && user?.is_account_owner === false) {
							return <></>;
						}
						return (
							<>
								<EditItem
									title={item.title}
									onClick={item.onClick}
								/>
								{
									Items.length-1 !== index
										? <Divider color={'gray'}/>
										: ""
								}
							</>
						)
					})
				}
			</div>

			<Dialog
				onClose={() => {
					setOpen(false);
				}}
				open={open}
				id={"close-account-dialog"}
				fullWidth
			>
				<DialogTitle>
					<StyleTypography
						fontSize={35}
						fontSizeMedium={28}
						fontWeight={'bold'}
						color={"#FF0000"}
					>
						Close Account
					</StyleTypography>
				</DialogTitle>

				<DialogContent>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={16}
						color={"#000"}
					>
						If you want to deactivate your account, proceed with caution.
						However, if you ever wish to reactivate it, you’ll need to contact the support team.
					</StyleTypography>
				</DialogContent>

				<DialogActions>
					<div className="edit-personal-info">
						<div className="edit-personal-info-cancel">
							<StyleButton
								fullWidth={false}
								onClick={() => setOpen(false)}
							>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={12}
									color={"#fff"}
								>
									Cancel
								</StyleTypography>
							</StyleButton>
						</div>

						<div className="edit-personal-info-close">
							<StyleButton
								fullWidth={false}
								backgroundColor={'#FF0000'}
								border={'#FF0000'}
								onClick={() => {
									closeAccount();
								}}
							>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={12}
									color={"#fff"}
								>
									Close Account
								</StyleTypography>
							</StyleButton>
						</div>
					</div>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default EditPersonalInfo;