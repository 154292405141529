import {InnerStepZero} from "./InnerSteps/InnerStepZero";
import InnerStepOne from "./InnerSteps/InnerStepOne";

function _renderInnerStep (stepZero, setStepZero, basicSetup, user) {
	switch (stepZero.inner_step) {
		case 0:
			return (
				<InnerStepZero
					stepZero={stepZero}
					setStepZero={setStepZero}
					user={user}
				/>
			);
		case 1:
			return (
				<InnerStepOne
					stepZero={stepZero}
					setStepZero={setStepZero}
					basicSetup={basicSetup}
				/>
			);
		default:
			return;
	}
}

export const StepZero = ({
	stepZero,
	setStepZero,
	basicSetup = () => {},
	user = {},
}) => {

  return (
	  <div id={'step-zero'}>
		  {_renderInnerStep(stepZero, setStepZero, basicSetup, user)}
	  </div>

  )
}