import React, { useCallback, useState } from 'react';
import {
	FreepayrollButton,
	FreepayrollTextField,
	Typography,
} from '@collegia-partners/ui-kit';
import { z } from 'zod';
import Request from '../utils/Request';
import { toast } from 'sonner';
import FullBlackLogo from '../images/svg/Logos/new-fp-logo-blakc-blue-horizontal.svg';

function PublicHeader() {
	return (
		<div className="header">
			<img alt={'logo'} src={FullBlackLogo} className={'logo'} />
			{/*<button className="button-circle">?</button>*/}
		</div>
	);
}

const emailSchema = z.string().email({ message: 'Invalid email address' });

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [error, setError] = useState('');

	const handleSubmit = useCallback(async () => {
		const result = emailSchema.safeParse(email);
		if (!result.success) {
			setError(result.error.errors[0].message);
			return;
		}
		setError('');
		await toast.promise(
			Request.post('api/employers/users/forgot-password', {
				users: {
					email: email,
				},
			}),
			{
				pending: 'Sending reset password email...',
				success: 'Reset password email sent successfully',
				error: 'An error occurred while sending email, please contact support',
			},
		);
	}, [email]);

	return (
		<div id="forgot-password-new">
			<PublicHeader />
			<div className="forgot-password-new-container">
				<div>
					<Typography
						variant={'title'}
						size={'large'}
						color={'primary'}
						weight={'bold'}
					>
						Password? We can help!
					</Typography>
					<Typography variant={'body'} size={'medium'} color={'black'}>
						Enter your email address and we'll send you a link to reset your
						password.
					</Typography>
				</div>
				<FreepayrollTextField
					label={'Email'}
					fieldType={'email'}
					placeholder={'Enter your email'}
					name={'email'}
					value={email}
					onChange={(e) => setEmail(e.target.value.toLowerCase())}
					error={!!error}
					helperText={error}
				/>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '1vw',
					}}
				>
					<FreepayrollButton
						variant={'primary'}
						size={'medium'}
						onClick={handleSubmit}
					>
						Reset Password
					</FreepayrollButton>
					<FreepayrollButton variant={'white-button'} size={'medium'}>
						Back to login
					</FreepayrollButton>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
