import {
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import Request from "../utils/Request";
import {toast} from "sonner";

export function useCreateSalary() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/pay-items/createSalary', data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['list-pay-items'] });
			toast.success('Salary created successfully');
		},
	});
}

export function useHidePayItem() {
	const queryClient = useQueryClient();
	
	return useMutation({
		mutationFn: async ({payItemId}) => {
			return await Request.put(`/api/employers/pay-items/hide-pay-item/${payItemId}`)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['list-pay-items'] });
			toast.success('Pay item removed successfully');
		}
	})
}