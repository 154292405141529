import React, {useEffect, useMemo, useRef, useState} from 'react';
import countryList from 'react-select-country-list';
import CompanyLogo from "../../../images/company-deatils/profile.svg";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {FreepayrollButton, FreepayrollSelect, FreepayrollTextField, Typography, Divider} from "@collegia-partners/ui-kit";
import {useGetEmployer, useUpdateEmployerDetails} from "../../../hooks/employer";
import Skeleton from "../../../components/Skeleton";

function CompanyDetailItem({label, value, onChange, disabled = false, isSelect = false, options = []}) {

	if (isSelect) {
		return (
			<div>
				<FreepayrollSelect
					options={options}
					onSelect={onChange}
					value={value}
					label={label}
					noGhostHelperText
					disabled={disabled}
				/>
			</div>
		);
	}

	return (
		<div>
			<FreepayrollTextField
				fieldType={"text"}
				name={label}
				label={label}
				value={value}
				onChange={onChange}
				disabled={disabled}
			/>
		</div>
	)
}

export const Details = (): JSX.Element => {

	const { setLayoutProps } = useLoggedLayout();

	const { employer, isPending } = useGetEmployer({ relations: ['address'] });

	const { mutateAsync: updateEmployerDetails, isPending: isLoading } = useUpdateEmployerDetails();

	const countries = useMemo(() => countryList().getData(), []);
	let transformedCountries = countries.map(country => ({
		value: country.label,
		label: country.label,
	}));
	transformedCountries.push({
		value: 'GB',
		label: 'United Kingdom',
	});

	const [employerRecord, setEmployerRecord] = useState({});
	const Months = [
		{value: 'January', label: 'January'},
		{value: 'February', label: 'February'},
		{value: 'March', label: 'March'},
		{value: 'April', label: 'April'},
		{value: 'May', label: 'May'},
		{value: 'June', label: 'June'},
		{value: 'July', label: 'July'},
		{value: 'August', label: 'August'},
		{value: 'September', label: 'September'},
		{value: 'October', label: 'October'},
		{value: 'November', label: 'November'},
		{value: 'December', label: 'December'},
	];

	const EmployerDetails = [
		{
			label: 'Company name',
			value: employerRecord?.company_name,
			onChange: (e) => setEmployerRecord({
				...employerRecord,
				company_name: e.target.value,
			}),
			disabled: employer?.is_manual_creation
		},
		{
			label: 'Address line 1',
			value: employerRecord?.address?.address_line_1,
			onChange: (e) => setEmployerRecord({
				...employerRecord,
				address: {
					...employerRecord?.address,
					address_line_1: e.target.value,
				},
			}),
		},
		{
			label: 'Legal Structure',
			value: employerRecord?.legal_structure,
			onChange: (e) => setEmployerRecord({
				...employerRecord,
				legal_structure: e.target.value,
			}),
		},
		{
			label: 'Address line 2 (Optional)',
			value: employerRecord?.address?.address_line_2,
			onChange: (e) => setEmployerRecord({
				...employerRecord,
				address: {
					...employerRecord?.address,
					address_line_2: e.target.value,
				},
			}),
		},
		{
			label: 'Registration number',
			value: employerRecord?.company_number,
			disabled: true,
		},
		{
			label: 'Holiday Year Start Month',
			value: employerRecord?.holiday_year_start_month,
			options: Months,
			onChange: (option) => setEmployerRecord({
				...employerRecord,
				holiday_year_start_month: option.value,
			}),
			isSelect: true,
		},
		{
			label: 'Postal Code',
			value: employerRecord?.address?.postal_code,
			onChange: (e) => setEmployerRecord({
				...employerRecord,
				address: {
					...employerRecord?.address,
					postal_code: e.target.value,
				},
			}),
		},
		{
			label: 'City',
			value: employerRecord?.address?.city,
			onChange: (e) => setEmployerRecord({
				...employerRecord,
				address: {
					...employerRecord?.address,
					city: e.target.value,
				},
			}),
		},
		{
			label: 'Country',
			value: employerRecord?.address?.country,
			disabled: true,
			isSelect: true,
			options: transformedCountries,
		},
	];

	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const fileTypes = ['image/jpeg', 'image/png'];
	const inputRef = useRef(null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file && fileTypes.includes(file.type)) {
			const img = new Image();
			img.src = URL.createObjectURL(file);

			img.onload = () => {
				if (img.width >= 200 && img.height >= 200) {
					setSelectedFile(file);
					setPreview(URL.createObjectURL(file));
					setEmployerRecord({
						...employerRecord,
						company_logo: file,
					})
				} else {
					setSelectedFile(null);
					setPreview(null);
					alert('Image must be 200x200 pixels.');
				}
			};
		}
	};

	const triggerFileInput = () => {
		inputRef.current.click();
	};

	useEffect(() => {
		setEmployerRecord(employer);
	}, [employer]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'Company',
			activeSubpage: 'Details',
			showBackButton: false,
		});
	}, [setLayoutProps]);

	return (
		<div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
			<div style={{display: "flex", gap: '1.33vw', alignItems: 'center'}}>
				<div style={{display: "none"}}>
					<input
						type={"file"}
						accept={".jpg, .jpeg, .png"}
						onChange={handleFileChange}
						ref={inputRef}
					/>
				</div>
				<img
					id={"details-image-container"}
					src={
						preview
							? preview
							: employerRecord?.company_logo
								? employerRecord?.company_logo
								: CompanyLogo
					}
					alt={"Company Logo"}
					onClick={triggerFileInput}
				/>
				<div style={{display: "flex", flexDirection: 'column', gap: '5px'}}>
					<Typography variant={"subtitle"} size={'large'}>
						Company logo
					</Typography>
					<Typography variant={"body"}>
						Will be shown on employee payslips.
					</Typography>
				</div>
			</div>
			<Divider color={'light-gray'} />
			<div style={{display: 'flex', flexWrap: 'wrap', gap: '1rem'}}>
				{
					EmployerDetails.map((item, index) => isPending ? (
						<Skeleton height={50} width={'100%'} background={'#D6D8E1'}/>
					) : (
						<div key={index} style={{flex: '1 1 calc(50% - 1rem)', maxWidth: 'calc(50% - 1rem)'}}>
							<CompanyDetailItem {...item} />
						</div>
					))
				}
			</div>
			<div style={{display: 'flex', flexWrap: 'wrap', gap: '1rem'}}>
				<FreepayrollButton
					variant={"white-button"}
					fullWidth={false}
					size={"medium"}
					width={3}
					height={1}
					custom={true}
					isLoading={isLoading}
					onClick={() => setEmployerRecord(employer)}
				>
					Discard
				</FreepayrollButton>
				<FreepayrollButton
					variant={"primary"}
					fullWidth={false}
					size={"medium"}
					width={4}
					height={1}
					custom={true}
					isLoading={isLoading}
					disabled={
						(employerRecord?.company_name === employer?.company_name &&
							employerRecord?.address?.address_line_1 === employer?.address?.address_line_1 &&
							employerRecord?.legal_structure === employer?.legal_structure &&
							employerRecord?.address?.address_line_2 === employer?.address?.address_line_2 &&
							employerRecord?.company_number === employer?.company_number &&
							employerRecord?.holiday_year_start_month === employer?.holiday_year_start_month &&
							employerRecord?.address?.postal_code === employer?.address?.postal_code &&
							employerRecord?.address?.city === employer?.address?.city &&
							employerRecord?.company_logo === employer?.company_logo &&
							employerRecord?.address?.country === employer?.address?.country) ||
						employerRecord?.company_name === '' ||
						employerRecord?.address?.address_line_1 === '' ||
						employerRecord?.legal_structure === '' ||
						employerRecord?.company_number === '' ||
						employerRecord?.holiday_year_start_month === '' ||
						employerRecord?.address?.postal_code === '' ||
						employerRecord?.address?.city === '' ||
						employerRecord?.address?.country === ''
					}
					onClick={async () => {
						await updateEmployerDetails( {
							address_line_1: employerRecord?.address?.address_line_1,
							address_line_2: employerRecord?.address?.address_line_2,
							city: employerRecord?.address?.city,
							company_logo: selectedFile,
							company_name: employerRecord?.company_name,
							company_number: employerRecord?.company_number,
							country: employerRecord?.address?.country,
							holiday_year_start_month: employerRecord?.holiday_year_start_month,
							legal_structure: employerRecord?.legal_structure,
							postal_code: employerRecord?.address?.postal_code,
						}).catch(error => {
							console.error(error);
						});
					}}

				>
					Save
				</FreepayrollButton>
			</div>
		</div>
	);
};
