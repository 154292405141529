import { useState } from 'react';
import { SignUpCard } from '../components/Pages/SignUp/SignUpCard';
import { CreateAccountCard } from '../components/Pages/SignUp/CreateAccountCard';
import Loading from '../components/Loading';
import { useCreateLead, useCreateUser } from '../hooks/signup';

const SignUp = () => {
	const { isPending: isCreatingUser } = useCreateUser();
	const { isPending: isCreatingLead } = useCreateLead();

	const [step, setStep] = useState(0),
		[leadId, setLeadId] = useState(null);

	if (isCreatingUser || isCreatingLead) {
		return <Loading />;
	}

	return (
		<div id={'sign-up-image'} className="sign-up-wrapper">
			<div className="sign-up-image-container" />
			<div id={'sign-up-container'} className="sign-up-container">
				{step === 0 ? (
					<SignUpCard step={step} setStep={setStep} setLeadId={setLeadId} />
				) : (
					<CreateAccountCard leadId={leadId} />
				)}
			</div>
		</div>
	);
};

export default SignUp;
