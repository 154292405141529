import Typography from '@mui/material/Typography';
import { Button, Checkbox, FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';

export const InnerStepZero = ({ stepZero, setStepZero, user }) => {
	return (
		<div
			id={'inner-step-zero'}
			style={{
				display: 'flex',
				flexWrap: 'wrap',
			}}
		>
			<div
				style={{
					flexBasis: '100%',
					maxWidth: '100%',
					flexGrow: 0,
				}}
				className={'main-title-container'}
			>
				<Typography className={'main-title'}>
					Welcome to your FreePayroll account, {user.name}
				</Typography>
				<Typography className={'main-subtitle'}>
					We’ll gather some information from you to customise your setup
					experience.
				</Typography>
			</div>

			<div
				style={{
					flexBasis: '100%',
					maxWidth: '100%',
					flexGrow: 0,
				}}
				className={'body-title-container'}
			>
				<Typography className={'body-title'}>
					First things first: Tell us about your team{' '}
				</Typography>
				<Typography />
			</div>

			<div
				style={{
					flexBasis: '100%',
					maxWidth: '100%',
					flexGrow: 0,
				}}
				className={'body-sub-container'}
			>
				<Typography className={'body-subtitle-1'}>
					Who is your company planning to pay?
				</Typography>
				<Typography className={'body-subtitle-2'}>
					Select all that apply
				</Typography>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								defaultValue={false}
								icon={<CheckBoxOutlineBlankSharpIcon />}
								checkedIcon={<CheckBoxSharpIcon />}
								onChange={(e) =>
									setStepZero({ ...stepZero, myself: e.target.checked })
								}
							/>
						}
						label={'Myself'}
					/>
					<FormControlLabel
						control={
							<Checkbox
								defaultValue={false}
								icon={<CheckBoxOutlineBlankSharpIcon />}
								checkedIcon={<CheckBoxSharpIcon />}
								onChange={(e) =>
									setStepZero({ ...stepZero, employees: e.target.checked })
								}
							/>
						}
						label={'Employees'}
					/>
					<FormControlLabel
						control={
							<Checkbox
								defaultValue={false}
								icon={<CheckBoxOutlineBlankSharpIcon />}
								checkedIcon={<CheckBoxSharpIcon />}
								onChange={(e) =>
									setStepZero({ ...stepZero, not_paying: e.target.checked })
								}
							/>
						}
						label={"We're not planning to pay anyone for at least 3 months"}
					/>
				</FormGroup>
			</div>

			<div
				style={{
					flexBasis: '100%',
					maxWidth: '100%',
					flexGrow: 0,
					display: 'flex',
					flexWrap: 'wrap',
				}}
				className={'button-container'}
			>
				<Button
					className={'default-black-button'}
					disabled={
						!stepZero.myself && !stepZero.employees && !stepZero.not_paying
					}
					onClick={() =>
						setStepZero({
							...stepZero,
							inner_step: 1,
						})
					}
				>
					Continue
				</Button>
			</div>
		</div>
	);
};
