import { Button, Typography } from '@mui/material';
import closedEye from '../../../../../images/svg/closed-eye.svg';
import openEye from '../../../../../images/svg/open-eye.svg';
import { useState } from 'react';
import { FreepayrollTextField } from '@collegia-partners/ui-kit';

const InnerStepOne = ({ stepOne, setStepOne, basicSetup = () => {} }) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<div
			style={{ display: 'flex', flexDirection: 'column' }}
			id={'inner-step-one'}
		>
			<div
				className={'main-title-container'}
				style={{
					width: '100%',
				}}
			>
				<Typography className={'main-title'}>HMRC Settings</Typography>
				<Typography className={'main-subtitle'}>
					We need the following information to properly calculate taxes due and
					report them to HMRC.
				</Typography>
			</div>

			<div className={'body-sub-container'}>
				<Typography className={'body-subtitle-1'}>
					Real Time Information (RTI)
				</Typography>
				<Typography className={'body-subtitle-2'}>
					RTI is the system used in the to report payroll information in
					real-time to HMRC, the UK's tax authority. FreePayroll automates HMRC
					reporting, allowing you to focus on running your business.
				</Typography>
			</div>

			<div className={'body-sub-container'} style={{ paddingBottom: '50px' }}>
				<Typography className={'body-subtitle-1'}>
					Some of the reports that we send to HMRC on your behalf
				</Typography>
				<Typography className={'body-subtitle-2'}>
					Full Payment Submission (FPS): This report must be submitted each time
					employees are paid and includes details such as employee earnings, tax
					and National Insurance contributions, and other deductions.
					<br />
					<br />
					Employer Payment Summary (EPS): This report is used to claim
					Employment Allowance, recover statutory payments, and report any other
					adjustments to the amounts previously reported in FPS submissions.
				</Typography>
			</div>

			<div
				style={{
					display: 'flex',
					gap: '32px',
					width: '100%',
				}}
			>
				<div
					style={{
						flex: '0 0 33.33%',
					}}
					className={'body-sub-container-1'}
				>
					<FreepayrollTextField
						fieldType={'number'}
						name={'hmc_user_id'}
						label={'HMRC Gateway ID*'}
						value={stepOne.rti_user_id}
						onChange={(e) => {
							setStepOne({ ...stepOne, rti_user_id: e.target.value });
						}}
						maxDecimalPlaces={0}
						customEndAdornment={
							<a
								href={
									'https://www.gov.uk/government/publications/access-our-services-using-government-gateway/use-a-government-gateway-account'
								}
								target={'_blank'}
								rel={'noreferrer'}
							>
								Help?
							</a>
						}
					/>
				</div>

				<div
					style={{
						flex: '0 0 33.33%',
					}}
					className={'body-sub-container-2'}
				>
					<FreepayrollTextField
						fieldType={showPassword ? 'text' : 'password'}
						name={'rti_password'}
						label={'HMRC Password*'}
						value={stepOne.rti_password}
						onChange={(e) => {
							setStepOne({ ...stepOne, rti_password: e.target.value });
						}}
						customEndAdornment={
							<img
								src={showPassword ? closedEye : openEye}
								alt={'show password'}
								onClick={() => setShowPassword(!showPassword)}
								style={{ cursor: 'pointer', width: '1.562vw' }}
							/>
						}
						autocomplete={'off'}
						className={'default-text-field custom-height'}
					/>
				</div>
			</div>

			<div
				className={'button-container'}
				style={{
					display: 'flex',
					justifyContent: 'flex-start',
					width: '100%',
				}}
			>
				<Button
					className={'default-black-button'}
					disabled={!stepOne.rti_user_id || !stepOne.rti_password}
					onClick={() => {
						basicSetup({
							employers: {
								step: 'hmrc_settings',
								rti_keys: {
									user: stepOne.rti_user_id,
									password: stepOne.rti_password,
									paye_reference: stepOne.employer_paye_reference,
									unique_taxpayer_reference: stepOne.unique_taxpayer_reference,
									account_office_reference: stepOne.accounts_office_reference,
								},
								staging_date: stepOne.staging_date,
								agriculture_sector: stepOne.agriculture_sector,
								aquaculture_sector: stepOne.aquaculture_sector,
								road_transport_sector: stepOne.road_transport_sector,
								other_sector: stepOne.other_sector,
								small_employer_relief: stepOne.small_employer_relief,
								employment_allowance: stepOne.employment_allowance,
							},
						});
					}}
				>
					Continue
				</Button>
			</div>
		</div>
	);
};

export default InnerStepOne;
