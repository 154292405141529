import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {useLoggedLayout} from "../../../../context/LoggedLayoutContext";
import {
	FreepayrollDropbox,
	Icon,
	Typography,
	Chip,
	FreepayrollTabs,
	Modal,
	FreepayrollButton, FreepayrollRadio, FreepayrollDateField, FreepayrollTextField
} from "@collegia-partners/ui-kit";
import {getEmployeeStatus} from "../TeamMembers";
import {usePaymentAfterLeaving, useUpdateEmployee} from "../../../../hooks/employees";
import {useGetEmployee} from "../../../../hooks/employee/useGetEmployee";
import {GeneralTab} from "./Tabs/GeneralTab";
import {SalaryTab} from "./Tabs/SalaryTab";
import {TaxesTab} from "./Tabs/TaxesTab";
import {PensionTab} from "./Tabs/PensionTab";
import {PayslipsTab} from "./Tabs/PayslipsTab";
import {BankDetailsTab} from "./Tabs/BankDetailsTab";
import {StudentLoansTab} from "./Tabs/StudentLoansTab";
import YearToDatesTab from "./Tabs/YearToDatesTab";
import {ParentalLeaveTab} from "./Tabs/ParentalLeaveTab";
import Skeleton from "../../../../components/Skeleton";
import {SickLeaveTab} from "./Tabs/SickLeaveTab";
import {useDownloadP45} from "../../../../hooks/employee/useDownloadP45";
import {
	addYears,
	differenceInCalendarDays,
	eachDayOfInterval, endOfMonth, format,
	isAfter,
	isSameMonth,
	parseISO,
	startOfMonth
} from "date-fns";
import {useGetEmployer} from "../../../../hooks/employer";
import moment from "moment";
import {useQueryClient} from "@tanstack/react-query";
import {parseDate} from "../../../../utils/Helpers";

function getMonthId(monthName) {
	switch (monthName) {
		case "January":
			return 0;
		case "February":
			return 1;
		case "March":
			return 2;
		case "April":
			return 3;
		case "May":
			return 4;
		case "June":
			return 5;
		case "July":
			return 6;
		case "August":
			return 7;
		case "September":
			return 8;
		case "October":
			return 9;
		case "November":
			return 10;
		case "December":
			return 11;
		default:
			return "error";
	}
}

function getWorkingDaysNumbers(working_pattern) {
	let array = [];

	if (working_pattern?.monday) {
		array.push(1);
	}

	if (working_pattern?.tuesday) {
		array.push(2);
	}

	if (working_pattern?.wednesday) {
		array.push(3);
	}

	if (working_pattern?.thursday) {
		array.push(4);
	}

	if (working_pattern?.friday) {
		array.push(5);
	}

	if (working_pattern?.saturday) {
		array.push(6);
	}

	if (working_pattern?.sunday) {
		array.push(0);
	}

	return array;
}

const getWorkingDaysInMonth = (startDate, endDate, workingDayNumbers) => {

	if (
		startDate === null ||
		startDate === undefined ||
		endDate === undefined ||
		endDate === null
	) {
		return 0;
	}

	const days = eachDayOfInterval({ start: startDate, end: endDate });
	return days.filter(day => workingDayNumbers.includes(day.getDay())).length;
};

const calculateProratedAmount = (periodStart, terminationDate, monthlyEquivalent, workingDayNumbers) => {
	let proratedAmount;

	if (periodStart === null || terminationDate === null) {
		return 0;
	}

	periodStart = parseISO(periodStart);

	if (isSameMonth(periodStart, terminationDate)) {
		const workingDaysInMonth = getWorkingDaysInMonth(startOfMonth(periodStart), endOfMonth(periodStart), workingDayNumbers);
		const daysWorked = getWorkingDaysInMonth(periodStart, terminationDate, workingDayNumbers);

		proratedAmount = monthlyEquivalent / workingDaysInMonth * daysWorked;
	} else {
		const workingDaysInMonth1 = getWorkingDaysInMonth(startOfMonth(periodStart), endOfMonth(periodStart), workingDayNumbers);
		const daysWorkedInMonth1 = getWorkingDaysInMonth(periodStart, endOfMonth(periodStart), workingDayNumbers);

		const workingDaysInMonth2 = getWorkingDaysInMonth(startOfMonth(terminationDate), endOfMonth(terminationDate), workingDayNumbers);
		const daysWorkedInMonth2 = getWorkingDaysInMonth(startOfMonth(terminationDate), terminationDate, workingDayNumbers);

		proratedAmount = (monthlyEquivalent / workingDaysInMonth1 * daysWorkedInMonth1) + (monthlyEquivalent / workingDaysInMonth2 * daysWorkedInMonth2);
	}

	return Math.ceil(proratedAmount * 100) / 100;
};

function TerminateEmployee({ open, setOpen }) {

	const { employer } = useGetEmployer({relations: []});

	const {employee} = useGetEmployee({
		id: useParams().employeeId,
		relations: ['salary','working_pattern','active_payroll_period','pay_schedule']
	});

	const {
		mutateAsync: updateEmployee,
		isPending: isUpdating
	} = useUpdateEmployee();

	const [terminationDate, setTerminationDate] = useState(null);
	const [yearlyHolidays, setYearlyHolidays] = useState(null);
	const [leaveYearStartDate, setLeaveYearStartDate] = useState(null);
	const [holidaysTaken, setHolidaysTaken] = useState(null);
	const [accruedHolidays, setAccruedHolidays] = useState(null);
	const [holidaysLeft, setHolidaysLeft] = useState(null);
	const [holidayPay, setHolidayPay] = useState(null);
	const [proRataTerminationPay, setProRataTerminationPay] = useState(null);

	const workingDaysNumbers = getWorkingDaysNumbers(employee?.working_pattern);

	useEffect(() => {
		if (terminationDate === null) {
			return;
		}

		if (isAfter(parseISO(employee?.join_date), leaveYearStartDate)) {
			let accruedHolidays = differenceInCalendarDays(terminationDate, parseISO(employee?.join_date)) + 1;
			setAccruedHolidays(accruedHolidays);
		} else {
			let accruedHolidays = differenceInCalendarDays(terminationDate, leaveYearStartDate) + 1;
			setAccruedHolidays(accruedHolidays);
		}

		let daysInLeaveYear = differenceInCalendarDays(addYears(leaveYearStartDate, 1), leaveYearStartDate);
		setHolidaysLeft((Math.ceil((accruedHolidays / daysInLeaveYear) * (yearlyHolidays) * 100) / 100) - holidaysTaken);

		if (holidaysLeft > 0 && employee?.salary?.employment_type === "salaried") {
			setHolidayPay(Math.ceil((employee?.salary?.annual_salary / 260) * (holidaysLeft) * 100) / 100);
		}

		if (employee?.on_leave === false) {
			if (employee?.pay_schedule?.day_rate_method === "yearly_working_days") {
				let daysWorked = getWorkingDaysInMonth(parseISO(employee?.active_payroll_period?.period_start_date), terminationDate, workingDaysNumbers);
				let yearlyWorkingDays = employee?.working_pattern?.number_of_working_days * 52;
				setProRataTerminationPay(Math.ceil((employee?.salary?.annual_salary / yearlyWorkingDays) * (daysWorked) * 100) / 100);
			} else {
				setProRataTerminationPay(calculateProratedAmount(employee?.active_payroll_period?.period_start_date, terminationDate, employee?.salary?.monthly_salary, workingDaysNumbers));
			}

		} else {
			setProRataTerminationPay(0);
		}

	}, [
		accruedHolidays,
		holidaysLeft,
		holidaysTaken,
		leaveYearStartDate,
		employee?.join_date,
		employee?.on_leave,
		employee?.active_payroll_period?.period_start_date,
		employee?.pay_schedule?.day_rate_method,
		employee?.salary?.annual_salary,
		employee?.salary?.employment_type,
		employee?.salary?.monthly_salary,
		employee?.working_pattern?.number_of_working_days,
		terminationDate,
		workingDaysNumbers,
		yearlyHolidays
	]);

	return (
		<Modal isOpen={open} onClose={() => setOpen(false)}>
			<div style={{display: "flex", flexDirection: "column", gap: "1.83vw"}}>
				<div style={{display: "flex", flexDirection: "column", gap: "0.3vw"}}>
					<Typography
						variant={"title"}
						weight={"bold"}
						color={"#0160FD"}
					>
						Terminate employment
					</Typography>
					<Typography
						variant={"subtitle"}
						weight={"normal"}
						color={"black"}
					>
						The employee will be marked as terminated and the current payroll will be their last one.
					</Typography>
				</div>

				<div style={{display: "flex", flexDirection: "column", gap: "1.03vw"}}>
					<FreepayrollDateField
						label={"Termination date"}
						value={moment(terminationDate)}
						onChange={(date) => {
							const minDate = moment(employee?.active_payroll_period?.period_start_date);
							const maxDate = moment(employee?.active_payroll_period?.period_end_date);

							if (date >= minDate && date <= maxDate) {
								if (getMonthId(employer.holiday_year_start_month) > date.month()) {
									let startLeaveYearDate = new Date(date.year() - 1, getMonthId(employer.holiday_year_start_month), 1);
									setLeaveYearStartDate(startLeaveYearDate);
								} else {
									let startLeaveYearDate = new Date(date.year(), getMonthId(employer.holiday_year_start_month), 1);
									setLeaveYearStartDate(startLeaveYearDate);
								}
								setTerminationDate(new Date(date));
							} else {
								setLeaveYearStartDate(null);
								setTerminationDate(null);
							}
						}}
						minDate={moment(employee?.active_payroll_period?.period_start_date)}
						maxDate={moment(employee?.active_payroll_period?.period_end_date)}
					/>
					{
						leaveYearStartDate && (
							<div style={{display: 'flex', columnGap: '20px'}}>
								<FreepayrollTextField
									fieldType={"number"}
									name={"Yearly holidays"}
									label={"Entitled yearly holidays"}
									value={yearlyHolidays}
									onChange={(e) => setYearlyHolidays(e.target.value)}
									maxDecimalPlaces={2}
									customEndAdornment={
										<Typography
											variant={"body"}
											weight={"normal"}
											color={"black"}
										>
											Days
										</Typography>
									}
								/>
								<FreepayrollTextField
									fieldType={"number"}
									name={"Holidays taken"}
									label={"Holidays taken"}
									value={holidaysTaken}
									onChange={(e) => setHolidaysTaken(e.target.value)}
									maxDecimalPlaces={2}
									customEndAdornment={
										<Typography
											variant={"body"}
											weight={"normal"}
											color={"black"}
										>
											Days
										</Typography>
									}
								/>
							</div>
						)
					}
					{
						leaveYearStartDate && (
							<div>
								<FreepayrollTextField
									fieldType={"text"}
									name={"Holidays left"}
									label={"Holidays left"}
									disabled={true}
									value={holidaysLeft}
									customEndAdornment={
										<Typography
											variant={"body"}
											weight={"normal"}
											color={"black"}
										>
											Days
										</Typography>
									}
								/>
								<FreepayrollTextField
									fieldType={"number"}
									name={"Entitled holiday pay"}
									label={"Entitled holiday pay"}
									value={holidayPay}
									disabled={true}
									customEndAdornment={
										<Typography
											variant={"body"}
											weight={"normal"}
											color={"black"}
										>
											£
										</Typography>
									}
								/>
								{
									employee?.salary?.employment_type === "salaried" && (
										<FreepayrollTextField
											fieldType={"number"}
											name={"Pro rata termination pay"}
											label={"Pro rata termination pay"}
											value={proRataTerminationPay}
											disabled={true}
											customEndAdornment={
												<Typography
													variant={"body"}
													weight={"normal"}
													color={"black"}
												>
													£
												</Typography>
											}
										/>
									)
								}
							</div>
						)
					}
				</div>
				<div style={{display: "flex", flexDirection: "row", gap: "1.03vw"}}>
					<FreepayrollButton
						variant={"white-button"}
						onClick={() => setOpen(false)}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						onClick={async () => {
							try {
								await updateEmployee({
									safeId: employee.safe_id,
									data: {
										employees: {
											action: 'terminate_employee',
											yearly_holidays: yearlyHolidays,
											holidays_taken: holidaysTaken,
											holidays_left: holidaysLeft,
											termination_date: format(parseDate(terminationDate),"yyyy-MM-dd"),
											leave_year_start_date: leaveYearStartDate.toISOString().split('T')[0],
											leave_year_end_date: addYears(leaveYearStartDate, 1).toISOString().split('T')[0],
											prorated_termination_pay: proRataTerminationPay,
											entitled_holiday_pay: holidayPay,
										}
									}
								});
								setOpen(false);
							} catch (e) {
								console.error(e);
							}
						}}
						isLoading={isUpdating}
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	);
}

function PaymentAfterLeavingModal({open, setOpen}) {

	const {mutateAsync: updatePaymentAfterLeaving} = usePaymentAfterLeaving()

	const safeId = useParams().employeeId;

	const [isRegularPayment, setIsRegularPayment] = useState(true);

	const options = [
		{
			value: 'Regular payment',
			description: {
				text: 'For example, contractual pay such as salary or notice pay.'
			}
		},
		{
			value: 'Irregular payment',
			description: {
				text: 'For example, accrued holiday pay, or an unexpected bonus.'
			}
		},
	]

	return (
		<Modal isOpen={open} onClose={() => setOpen(false)}>
			<div style={{display: "flex", flexDirection: "column", gap: "1.83vw"}}>
				<div style={{display: "flex", flexDirection: "column", gap: "0.3vw"}}>
					<Typography
						variant={"title"}
						weight={"bold"}
						color={"#0160FD"}
					>
						Payment after leaving
					</Typography>
					<Typography
						variant={"subtitle"}
						weight={"normal"}
						color={"black"}
					>
						When enabled, we will create an empty payslip in the upcoming pay run for this employee.
					</Typography>
				</div>

				<div style={{display: "flex", flexDirection: "row", gap: "1.03vw"}}>
					<FreepayrollRadio
						options={options}
						onOptionChange={(option) => {
							if (option === 'Regular payment') {
								setIsRegularPayment(true);
							} else {
								setIsRegularPayment(false);
							}
						}}
						checkedOption={isRegularPayment ? 'Regular payment' : 'Irregular payment'}
					/>
				</div>
				<div style={{display: "flex", flexDirection: "row", gap: "1.03vw"}}>
					<FreepayrollButton
						variant={"white-button"}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						onClick={async () => {
							try {
								await updatePaymentAfterLeaving({
									employees: {
										employee_safe_id: safeId,
										action: isRegularPayment ? 'enable' : 'disable',
										payment_type: isRegularPayment ? 'regular' : 'irregular'
									}
								});
								setOpen(false);
							} catch (e) {
								console.error(e);
							}
						}}
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	);
}

function EmployeeRecordHeader({ setOpenPALModal, setOpenTerminateModal }) {

	const employeeId = useParams().employeeId;
	const {employee, isPending} = useGetEmployee({id: employeeId, relations: ['p60s','p45']});
	const { mutate: downloadP45, isPending: isDownloading } = useDownloadP45(employeeId);

	if (isPending) {
		return (
			<div>
				<Skeleton width={"100%"} height={"3.5vw"}/>
			</div>
		);
	}

	return (
		<div style={{display: "flex", alignItems: "center", gap: "0.8vw"}}>
			<Chip color={getEmployeeStatus(employee?.status)}>
				{employee?.status}
			</Chip>
			<Typography
				variant={"title"}
				color={"black"}
			>
				{`${employee?.forename} ${employee?.surname}`}
			</Typography>
			<FreepayrollDropbox
				target={
					<button
						style={{all: "unset", cursor: "pointer"}}
					>
						<Icon
							iconName="dots-horizontal"
							style={{transform: "rotateZ(90deg)"}}
						/>
					</button>
				}
				className={"EmployeeSummaryActions"}
			>
				{
					employee?.p60s?.length > 0 &&
					employee?.p60s.map((p60, index) => (
						<button
							key={index}
							className={"EmployeeSummaryAction"}
						>
							Download P60 / {p60?.tax_year?.formated_tax_year}
						</button>
					))
				}
				<button
					className={"EmployeeSummaryAction"}
					onClick={() => {
						downloadP45();
					}}
					disabled={!employee?.p45?.id}
				>
					{isDownloading ? 'Downloading...' : 'Download P45'}
				</button>
				{
					(employee?.status === "Terminated" || !employee?.active) &&
					<button
						className={"EmployeeSummaryAction"}
						onClick={() => setOpenPALModal(true)}
					>
						Payment After Leaving
					</button>
				}
				{
					employee?.status === "Active" &&
					<button
						className={"EmployeeSummaryAction"}
						onClick={() => setOpenTerminateModal(true)}
					>
						Terminate employment
					</button>
				}
				{
					employee?.status === "Terminated" &&
					<button
						className={"EmployeeSummaryAction"}
					>
						Reinstate employee
					</button>
				}
			</FreepayrollDropbox>
		</div>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeeRecord = (): JSX.Element => {

	const employeeId = useParams().employeeId;

	const {setLayoutProps} = useLoggedLayout();

	const queryClient = useQueryClient();
	const employee = queryClient.getQueryData(['employee', employeeId]);

	const [tabIndex, setTabIndex] = useState(0);
	const [openPALModal, setOpenPALModal] = useState(false);
	const [openTerminateModal, setOpenTerminateModal] = useState(false);

	const TabItems = [
		{
			children: "General",
			value: 0,
		},
		{
			children: "Salary",
			value: 1,
		},
		{
			children: "Taxes",
			value: 2,
		},
		{
			children: "Pension",
			value: 3,
		},
		{
			children: "Payslips",
			value: 4,
		},
		{
			children: employee?.gender === "F" ? "Maternity leave" : "Paternity leave",
			value: 5,
		},
		{
			children: "Year to dates",
			value: 6,
		},
		{
			children: "Sick leave",
			value: 7,
		},
		{
			children: "Bank Details",
			value: 8,
		},
		{
			children: "Student Loans",
			value: 9,
		},
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'People',
			showBackButton: true,
			backLink: '/main/people/team-members',
			expanded: false
		});
	}, [setLayoutProps]);
	
	return (
		<div style={{display: "flex", flexDirection: "column", gap: "3vw", padding: "0 7vw"}}>
			<EmployeeRecordHeader
				setOpenPALModal={setOpenPALModal}
				setOpenTerminateModal={setOpenTerminateModal}
			/>
			<FreepayrollTabs
				items={TabItems}
				contents={[]}
				value={tabIndex}
				onValueChange={(value) => {
					if (value === 'null') return;
					setTabIndex(value);
				}}
			/>
			{
				tabIndex === 0 &&
					<GeneralTab/>
			}
			{
				tabIndex === 1 &&
					<SalaryTab/>
			}
			{
				tabIndex === 2 &&
					<TaxesTab/>
			}
			{
				tabIndex === 3 &&
					<PensionTab/>
			}
			{
				tabIndex === 4 &&
					<PayslipsTab/>
			}
			{
				tabIndex === 5 &&
					<ParentalLeaveTab/>
			}
			{
				tabIndex === 6 &&
					<YearToDatesTab/>
			}
			{
				tabIndex === 7 &&
					<SickLeaveTab/>
			}
			{
				tabIndex === 8 &&
					<BankDetailsTab/>
			}
			{
				tabIndex === 9 &&
					<StudentLoansTab/>
			}
			{
				openPALModal &&
					<PaymentAfterLeavingModal
						open={openPALModal}
						setOpen={setOpenPALModal}
					/>
			}
			{
				openTerminateModal &&
					<TerminateEmployee
						open={openTerminateModal}
						setOpen={setOpenTerminateModal}
					/>
			}
		</div>
	);
};

export default EmployeeRecord;
