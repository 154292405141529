import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';
import StyleTypography from '../StyledComponents/StyleTypography';
import { useState } from 'react';
import { useReopenPayroll } from '../../hooks/payroll/useReopenPayroll';
import { FreepayrollButton } from '@collegia-partners/ui-kit';

function renderDialogContent(step, setStep) {
	if (step === 0) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
					width: '100%',
				}}
			>
				<ul>
					<li>
						By initiating a rollback, you acknowledge and accept responsibility
						for any misalignment with HMRC resulting from the FPS resubmission.
						It is advised to make corrections in future pay runs when possible
						to avoid complications.
					</li>
					<br />
					<li>
						It is <strong>not possible</strong> to amend pay runs for{' '}
						<strong>leavers</strong>. For this cases, please add a Payment After
						Leaving (PAL).
					</li>
					<br />
					<li>
						If you have paid an employee under a{' '}
						<strong>wrong NI Category, do not rollback.</strong> Please get in
						touch with support.
					</li>
					<br />
					<li>
						Once the rollback begins, you must reprocess each subsequent pay run
						until you reach the current pay run to ensure accurate cumulative
						payroll figures.
					</li>
					<br />
					<li>
						On the next step, you will need to select the employees that will be
						affected by the rollback.
					</li>
					{/*<li>*/}
					{/*	All current period data will be lost. You will need to re-enter the data for the current*/}
					{/*	period.*/}
					{/*</li>*/}
					{/*<br/>*/}
					{/*<li>*/}
					{/*	YTD figures for employees will reset to the beginning of the selected period.*/}
					{/*</li>*/}
					{/*<br/>*/}
					{/*<li>*/}
					{/*	Be careful as NI Categories or Tax Codes might have changed, please update them accordingly.*/}
					{/*</li>*/}
					{/*<br/>*/}
					{/*<li>*/}
					{/*	Employees will be reassessed for auto enrolment, unless they have already been enrolled.*/}
					{/*</li>*/}
					{/*<br/>*/}
					{/*<li>*/}
					{/*	If adjustments result in smaller pension contributions, these will not be reflected in the*/}
					{/*	pension in case monies have already been invested.*/}
					{/*	For those cases, you will need to adjust pension contributions in the current or future*/}
					{/*	periods.*/}
					{/*</li>*/}
				</ul>
			</div>
		);
	} else {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
					width: '100%',
				}}
			>
				<ul>
					<li>
						By initiating a rollback, you acknowledge and accept responsibility
						for any misalignment with HMRC resulting from the FPS resubmission.
						It is advised to make corrections in future pay runs when possible
						to avoid complications.
					</li>
					<br />
					<li>
						It is <strong>not possible</strong> to amend pay runs for{' '}
						<strong>leavers</strong>. For this cases, please add a Payment After
						Leaving (PAL).
					</li>
					<br />
					<li>
						If you have paid an employee under a{' '}
						<strong>wrong NI Category, do not rollback.</strong> Please get in
						touch with support.
					</li>
					<br />
					<li>
						Once the rollback begins, you must reprocess each subsequent pay run
						until you reach the current pay run to ensure accurate cumulative
						payroll figures.
					</li>
					<br />
					<li>
						On the next step, you will need to select the employees that will be
						affected by the rollback.
					</li>
					{/*<li>*/}
					{/*	All current period data will be lost. You will need to re-enter the data for the current*/}
					{/*	period.*/}
					{/*</li>*/}
					{/*<br/>*/}
					{/*<li>*/}
					{/*	YTD figures for employees will reset to the beginning of the selected period.*/}
					{/*</li>*/}
					{/*<br/>*/}
					{/*<li>*/}
					{/*	Be careful as NI Categories or Tax Codes might have changed, please update them accordingly.*/}
					{/*</li>*/}
					{/*<br/>*/}
					{/*<li>*/}
					{/*	Employees will be reassessed for auto enrolment, unless they have already been enrolled.*/}
					{/*</li>*/}
					{/*<br/>*/}
					{/*<li>*/}
					{/*	If adjustments result in smaller pension contributions, these will not be reflected in the*/}
					{/*	pension in case monies have already been invested.*/}
					{/*	For those cases, you will need to adjust pension contributions in the current or future*/}
					{/*	periods.*/}
					{/*</li>*/}
				</ul>
			</div>
		);
	}
}

export const ReopenPayRun = ({ open, setOpen, payrollId, setPayrollId }) => {
	const [step, setStep] = useState(0);

	const { mutateAsync: reopenPayroll, isPending: isReopening } =
		useReopenPayroll();

	return (
		<Dialog
			onClose={() => {
				setOpen(false);
				setPayrollId(null);
			}}
			open={open}
			fullWidth={true}
			maxWidth={'md'}
			id={'salary-modal'}
		>
			<DialogTitle>
				<StyleTypography fontSize={35} fontSizeMedium={28} fontWeight={'bold'}>
					Read carefully before proceeding
				</StyleTypography>
			</DialogTitle>
			<DialogContent>{renderDialogContent(step, setStep)}</DialogContent>
			<DialogActions>
				<div
					style={{
						display: 'flex',
						width: '100%',
						gap: '16px',
						padding: '8px',
					}}
				>
					<div
						style={{
							flex: 1,
						}}
					>
						<FreepayrollButton
							fullWidth
							className={'default-black-button'}
							onClick={async () => {
								try {
									await reopenPayroll(payrollId);
									setOpen(false);
								} catch (error) {
									console.error(error);
								}
							}}
							isLoading={isReopening}
						>
							Continue
						</FreepayrollButton>
					</div>

					<div
						style={{
							flex: 1,
						}}
					>
						<Button
							fullWidth
							className={'default-white-button'}
							onClick={() => {
								setOpen(false);
								setPayrollId(null);
							}}
						>
							Cancel
						</Button>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	);
};
