import React, {useCallback, useEffect, useState} from "react";
import {
	FreepayrollButton, FreepayrollDropbox,
	FreepayrollSelect,
	FreepayrollTextField, Icon,
	Modal,
	Typography
} from "@collegia-partners/ui-kit";
import FPTable from "../../../components/common/FPTable/FPTable";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {useCreateSalary, useHidePayItem} from "../../../hooks/pay-items";
import {useListPayItems} from "../../../hooks/employer/pay-items/useListPayItems";

function AddSalaryModal ({
	open,
	setOpen,
}) {
	const [salary, setSalary] = useState({
		name: "",
		code: "",
		type: "",
		pay_method: "",
		multiplier: "",
		rate: ""
	});

	const RateTypes = [
		{
			value: "multiplier",
			label: "Multiplier Rate"
		},
		{
			value: "hourly",
			label: "Hourly Rate"
		},
		{
			value: "unit",
			label: "Rate per Unit"
		}
	];

	const { mutateAsync: createSalary, isPending } = useCreateSalary();

	const onClick = useCallback(async () => {
		await createSalary({
			pay_item: {
				name: salary.name,
				code: salary.code,
				type: salary.type,
				pay_method: salary.pay_method,
				multiplier: salary.multiplier,
				rate: salary.rate
			}
		}).catch();
		setSalary({
			name: "",
			code: "",
			type: "",
			pay_method: "",
			multiplier: "",
			rate: ""
		});
		setOpen(false);
	},[createSalary, salary, setOpen]);

	return (
		<Modal isOpen={open} onClose={() => setOpen(false)} size={"md"} >
			<div style={{display: "flex", flexDirection: "column", gap: "1vw"}}>
				<Typography
					variant={"title"}
				>
					Add Salary
				</Typography>
				<Typography
					variant={"label"}
				>
					Choose a name for this custom rate and choose
					if it should be calculated per hour or per unit.
				</Typography>
				<div style={{display: "flex", gap: "1.2vw"}}>
					<FreepayrollTextField
						fieldType={"text"}
						label={"Rate Name"}
						value={salary.name}
						onChange={(e) => {
							setSalary({
								...salary,
								name: e.target.value
							});
						}}
						name={"rate_name"}
					/>
					<FreepayrollTextField
						fieldType={"text"}
						label={"Account Code (Optional)"}
						value={salary.code}
						onChange={(e) => {
							setSalary({
								...salary,
								code: e.target.value
							});
						}}
						helperText={"This code will be the code displayed on your journal"}
						name={"account_code"}
					/>
				</div>
				<div style={{display: "flex", gap: "1.2vw"}}>
					<FreepayrollSelect
						options={RateTypes}
						label={"Rate Type"}
						value={salary.type}
						onSelect={(option) => {
							setSalary({
								...salary,
								multiplier: "",
								rate: "",
								type: option.value,
								pay_method: ""
							});
						}}
					/>
					<FreepayrollSelect
						options={[
							{
								value: "custom",
								label: "Custom for each employee",
								disabled: salary.type === "multiplier"
							},
							{
								value: "fixed",
								label: "Fixed value for all employees"
							}
						]}
						label={"Pay Method"}
						value={salary.pay_method}
						onSelect={(option) => {
							setSalary({
								...salary,
								rate: "",
								pay_method: option.value
							});
						}}
					/>
				</div>
				{
					salary.type === "multiplier" && salary.pay_method === 'fixed' && (
						<FreepayrollTextField
							fieldType={"number"}
							label={"Multiplier"}
							value={salary.multiplier}
							onChange={(e) => {
								setSalary({
									...salary,
									multiplier: e.target.value
								});
							}}
							name={"multiplier"}
							maxDecimalPlaces={4}
							customStartAdornment={
								<Typography variant={"subtitle"} color={"black"} weight={"bold"}>
									X
								</Typography>
							}
						/>
					)
				}
				{
					(salary.type === "hourly" || salary.type === 'unit') && salary.pay_method === 'fixed' && (
						<FreepayrollTextField
							fieldType={"number"}
							label={
								salary.type === 'hourly' ?
									"Salary Rate" : "Rate per Unit"
							}
							value={salary.rate}
							onChange={(e) => {
								setSalary({
									...salary,
									rate: e.target.value
								});
							}}
							name={"rate"}
							maxDecimalPlaces={2}
							customStartAdornment={
								<Typography variant={"subtitle"} color={"black"} weight={"bold"}>
									£
								</Typography>
							}
						/>
					)
				}
				<div style={{display: "flex", gap: "1.2vw"}}>
					<FreepayrollButton
						variant={"white-button"}
						isLoading={isPending}
						onClick={() => {
							setOpen(false);
						}}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						onClick={onClick}
						disabled={
							!salary.name ||
							!salary.type ||
							!salary.pay_method ||
							(
								salary.pay_method === "fixed" &&
								((salary.type === "multiplier" && !salary.multiplier) ||
									((salary.type === "hourly" || salary.type === "unit") && !salary.rate))
							)
						}
						isLoading={isPending}
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	);
}

const Salaries = (): JSX.Element => {

	const { setLayoutProps } = useLoggedLayout();
	const { mutate: hidePayItem, isPending: isHiddingPayItem } = useHidePayItem();

	const [open, setOpen] = useState(false);

	const columns = [
		{
			text: "Name",
			color: "black",
			key: "name",
		},
		{
			text: "Rate Type",
			color: "black",
			customRender: (row, index) => (
				<Typography
					variant="body"
					size="medium"
					key={index}
					color={"black"}
				>
					{
						row.salary_type === "multiplier" ?
							"Multiplier Rate" :
							row.salary_type === "hourly" ?
								"Hourly Rate" :
								"Rate per Unit"
					}
				</Typography>
			)
		},
		{
			text: "Pay Method",
			color: "black",
			customRender: (row, index) => (
				<Typography
					variant="body"
					size="medium"
					key={index}
					color={"black"}
				>
					{
						row.pay_method === "fixed" ?
							"Fixed value for all employees" :
							"Custom for each employee"
					}
				</Typography>
			)
		},
		{
			text: "Actions",
			color: "black",
			customRender: (row, index) => (
				<FreepayrollDropbox
					target={
						<button
							style={{all: "unset", cursor: "pointer"}}
						>
							<Icon
								iconName="dots-horizontal"
								className={"EmployeeSummaryIcon"}
							/>
						</button>
					}
					key={index}
					className={"EmployeeSummaryActions"}
				>
					<button
						className={"EmployeeSummaryAction"}
						disabled={isHiddingPayItem}
						onClick={() => hidePayItem({
							payItemId: row.id
						})}
					>
						<Icon iconName={"delete"} width={"13.5vw"}/>
						Remove rate
					</button>
				</FreepayrollDropbox>
			),
			width: "0.5fr"
		},
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'Company',
			activeSubpage: 'Salaries',
		});
	}, [setLayoutProps]);

	return (
		<div style={{width: "100%", display: "flex", flexDirection: "column", gap: "2.57vw"}}>
			<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
				<div>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Custom Salaries
					</Typography>
					<Typography
						variant={"label"}
					>
						Here you can set custom salaries for your employees.
					</Typography>
				</div>
				<div style={{width: "fit-content"}}>
					<FreepayrollButton
						size={"medium"}
						height={1.2}
						custom
						variant={"primary"}
						onClick={() => setOpen(true)}
					>
						Create Salary
					</FreepayrollButton>
				</div>
			</div>
			<div>
				<FPTable
					headers={columns}
					hookName={useListPayItems}
					hookKey={'payItems'}
					hookParams={{
						is_salary: true
					}}
				/>
			</div>
			<AddSalaryModal
				open={open}
				setOpen={setOpen}
			/>
		</div>
	);
};

export default Salaries;
