import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import StyleTypography from '../../StyledComponents/StyleTypography';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { CurrencyInputCustom } from '../../StyledComponents/CurrencyInputCustom';
import { Decimal } from 'decimal.js';
import { useManagePayItem } from '../../../hooks/payroll/useManagePayItem';
import { useListPayItems } from '../../../hooks/employer/pay-items/useListPayItems';

const SalaryPayItem = ({
	selectedPayItem,
	amount,
	setAmount,
	units,
	setUnits,
	salaryRate,
	setSalaryRate,
}) => {
	return (
		<>
			{/*Hours or Units*/}
			<div
				style={{
					padding: '8px',
					width: '50%',
				}}
			>
				<InputLabel className={'default-input-label'}>
					{selectedPayItem.salary_type === 'hourly' ? 'Hours' : 'Units'}
				</InputLabel>
				<TextField
					fullWidth
					className={'default-text-field'}
					onChange={(event) => {
						setUnits(event.target.value);
						if (event.target.value === '') {
							setAmount('');
							return;
						}
						if (!salaryRate) return;
						const a = new Decimal(event.target.value);
						const b = new Decimal(salaryRate ?? 0);
						setAmount(a.times(b).toNumber());
					}}
					value={units}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: { prefix: '' },
						endAdornment: '',
					}}
				/>
			</div>

			{/*Salary rate*/}
			<div
				style={{
					padding: '8px',
					width: '50%',
				}}
			>
				<InputLabel className={'default-input-label'}>Salary Rate*</InputLabel>
				<TextField
					disabled={selectedPayItem.pay_method === 'fixed'}
					fullWidth
					className={'default-text-field'}
					value={salaryRate}
					onChange={(event) => {
						setSalaryRate(event.target.value);
						if (event.target.value === '') {
							setAmount('');
							return;
						}
						if (!units) return;
						const a = new Decimal(units);
						const b = new Decimal(event.target.value);
						setAmount(a.times(b).toNumber());
					}}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: { prefix: '' },
						endAdornment: '£',
					}}
				/>
			</div>

			{/*Amount*/}
			<div
				style={{
					padding: '8px',
					width: '100%',
				}}
			>
				<InputLabel className={'default-input-label'}>Amount*</InputLabel>
				<TextField
					disabled
					fullWidth
					className={'default-text-field'}
					value={amount}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: { prefix: '' },
						endAdornment: '£',
					}}
				/>
			</div>
		</>
	);
};

const OvertimePayItem = ({
	selectedPayItem,
	amount,
	setAmount,
	units,
	setUnits,
	salaryRate,
	setSalaryRate,
}) => {
	return (
		<>
			{/*Units*/}
			<div
				style={{
					padding: '8px',
					width: '50%',
				}}
			>
				<InputLabel className={'default-input-label'}>Units*</InputLabel>
				<TextField
					fullWidth
					className={'default-text-field'}
					onChange={(event) => {
						setUnits(event.target.value);
						if (event.target.value === '') {
							setAmount('');
							return;
						}
						if (!salaryRate) return;
						const a = new Decimal(event.target.value);
						const b = new Decimal(salaryRate);
						setAmount(a.times(b).toNumber());
					}}
					value={units}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: { prefix: '' },
						endAdornment: '',
					}}
				/>
			</div>

			{/*Salary rate*/}
			<div
				style={{
					padding: '8px',
					width: '50%',
				}}
			>
				<InputLabel className={'default-input-label'}>
					Overtime Rate ({selectedPayItem.salary_multiplier}x)
				</InputLabel>
				<TextField
					disabled={selectedPayItem.pay_method === 'fixed'}
					fullWidth
					className={'default-text-field'}
					value={salaryRate}
					onChange={(event) => {
						setSalaryRate(event.target.value);
						if (event.target.value === '') {
							setAmount('');
							return;
						}
						if (!units) return;
						const a = new Decimal(units);
						const b = new Decimal(event.target.value);
						setAmount(a.times(b).toNumber());
					}}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: { prefix: '' },
						endAdornment: '£',
					}}
				/>
			</div>

			{/*Amount*/}
			<div
				style={{
					padding: '8px',
					width: '100%',
				}}
			>
				<InputLabel className={'default-input-label'}>Amount*</InputLabel>
				<TextField
					disabled
					fullWidth
					className={'default-text-field'}
					value={amount}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: { prefix: '' },
						endAdornment: '£',
					}}
				/>
			</div>
		</>
	);
};

const OneOffPayItem = ({ amount, setAmount }) => {
	//AMOUNT
	return (
		<div
			style={{
				padding: '8px',
				width: '100%',
			}}
		>
			<InputLabel className={'default-input-label'}>Amount*</InputLabel>
			<TextField
				fullWidth
				className={'default-text-field'}
				onChange={(event) => {
					setAmount(event.target.value);
				}}
				value={amount}
				InputProps={{
					inputComponent: CurrencyInputCustom,
					inputProps: { prefix: '' },
					endAdornment: '£',
				}}
			/>
		</div>
	);
};

const AddPayItemModal = ({
	open,
	setOpen,
	selectedEmployee,
	setSelectedEmployee,
	setUpdateEmployeePayRuns,
	setEmployeePayRuns,
}) => {
	const { mutateAsync: managePayItem } = useManagePayItem();

	const { payItems } = useListPayItems({
		page: 1,
		orderBy: 'name',
		orderDir: 'asc',
		perPage: 1000,
	});

	const [selectedPayItem, setSelectedPayItem] = useState(' ');
	const [amount, setAmount] = useState('');
	const [units, setUnits] = useState('');
	const [salaryRate, setSalaryRate] = useState(selectedPayItem?.salary_rate);

	return (
		<Dialog
			onClose={() => {
				setSelectedPayItem(' ');
				setAmount('');
				setOpen(false);
			}}
			open={open}
			fullWidth={true}
			maxWidth={'sm'}
			id={'salary-modal'}
		>
			<DialogTitle>
				<StyleTypography fontSize={35} fontSizeMedium={28} fontWeight={'bold'}>
					Add Pay Item
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
						margin: '-8px',
					}}
				>
					{/*Pay Item dropdown*/}
					<div
						style={{
							padding: '8px',
							width: '100%',
						}}
					>
						<InputLabel className={'default-input-label'}>
							Pay Items*
						</InputLabel>
						<FormControl fullWidth>
							<Select
								className={'default-select'}
								id={'select-gender'}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200,
										},
									},
								}}
								onChange={(event) => {
									if (
										event.target.value?.salary_type === 'multiplier' &&
										event.target.value?.pay_method === 'fixed'
									) {
										const a = new Decimal(
											selectedEmployee.employee.salary.hourly_salary,
										);
										const b = new Decimal(
											event.target.value?.salary_multiplier,
										);
										setSalaryRate(a.times(b).toNumber());
									} else {
										setSalaryRate(event.target.value?.salary_rate);
									}
									setSelectedPayItem(event.target.value);
									setAmount('');
									setUnits('');
								}}
								value={selectedPayItem}
							>
								<MenuItem key={'select-title'} value={' '} disabled>
									Select
								</MenuItem>
								{payItems?.data?.map((pay_item) => {
									return (
										<MenuItem key={pay_item.id} value={pay_item}>
											{pay_item.name} {pay_item.is_salary ? ' (Salary)' : ''}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>

					{selectedPayItem.is_salary &&
						selectedPayItem.salary_type !== 'multiplier' && (
							<SalaryPayItem
								selectedPayItem={selectedPayItem}
								setAmount={setAmount}
								setUnits={setUnits}
								amount={amount}
								units={units}
								salaryRate={salaryRate}
								setSalaryRate={setSalaryRate}
							/>
						)}

					{selectedPayItem.is_salary &&
						selectedPayItem.salary_type === 'multiplier' && (
							<OvertimePayItem
								selectedPayItem={selectedPayItem}
								setAmount={setAmount}
								setUnits={setUnits}
								amount={amount}
								units={units}
								salaryRate={salaryRate}
								setSalaryRate={setSalaryRate}
							/>
						)}

					{selectedPayItem.is_salary === false &&
						selectedPayItem.is_recurring === false && (
							<OneOffPayItem amount={amount} setAmount={setAmount} />
						)}
				</div>
			</DialogContent>
			<DialogActions>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
						margin: '-8px',
					}}
				>
					<div
						style={{
							padding: '8px',
							width: '50%',
						}}
					>
						<Button
							fullWidth
							className={'default-black-button'}
							disabled={selectedPayItem === ' ' || amount === ''}
							onClick={async () => {
								try {
									const { employee, data } = await managePayItem({
										pay_schedule_runs: {
											item_amount: parseFloat(amount),
											salary_units: units ? parseFloat(units) : null,
											pay_method: selectedPayItem.pay_method,
											salary_rate: salaryRate ? parseFloat(salaryRate) : null,
											employee_pay_run_id: selectedEmployee.id,
											employee_id: selectedEmployee.employee_id,
											employer_pay_item_id: selectedPayItem.id,
											action: 'add',
										},
									});
									setSelectedEmployee(employee);
									setEmployeePayRuns(data);
									setUpdateEmployeePayRuns(data);
									setOpen(false);
									setSelectedPayItem(' ');
									setAmount('');
								} catch (e) {
									console.error('Error adding pay item');
								}
							}}
						>
							Add
						</Button>
					</div>

					<div
						style={{
							padding: '8px',
							width: '50%',
						}}
					>
						<Button
							fullWidth
							className={'default-white-button'}
							onClick={() => {
								setSelectedPayItem(' ');
								setAmount('');
								setOpen(false);
							}}
						>
							Cancel
						</Button>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default AddPayItemModal;
