import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useReducer } from 'react';
import { useCreateLead } from '../../../hooks/signup';
import { FreepayrollButton } from '@collegia-partners/ui-kit';

export const SignUpCard = ({
	step = 0,
	setStep = () => {},
	setLeadId = () => {},
}) => {
	const { mutateAsync: createLead, isPending: isCreatingLead } =
		useCreateLead();

	const [state, setState] = useReducer(
		(state, updates) => ({ ...state, ...updates }),
		{
			employees: '',
			payroll: '',
		},
	);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '100%',
			}}
		>
			<Card id={'sign-up-card'}>
				<CardContent id={'sign-up-card-content'}>
					<Typography id={'how-many-employees'} className={'sign-up-header'}>
						How many employees do you have, including yourself?
					</Typography>
					<FormControl>
						<RadioGroup
							className={'sign-up-radio-group'}
							aria-labelledby={'how-many-employees'}
							name={'how-many-employees-group'}
							value={state.employees}
							onChange={(e) =>
								setState({
									employees: e.target.value,
								})
							}
						>
							<FormControlLabel
								value={'1-5'}
								control={<Radio />}
								label={'1-5 employees'}
							/>
							<FormControlLabel
								value={'6-24'}
								control={<Radio />}
								label={'6-24 employees'}
							/>
							<FormControlLabel
								value={'25-99'}
								control={<Radio />}
								label={'25-99 employees'}
							/>
							<FormControlLabel
								value={'100+'}
								control={<Radio />}
								label={'100+ employees'}
							/>
						</RadioGroup>
					</FormControl>
					<Typography id={'currently-run-payroll'} className={'sign-up-header'}>
						How do you currently run payroll?
					</Typography>
					<FormControl>
						<RadioGroup
							className={'sign-up-radio-group'}
							aria-labelledby={'currently-run-payroll'}
							name={'currently-run-group'}
							value={state.payroll}
							onChange={(e) =>
								setState({
									payroll: e.target.value,
								})
							}
						>
							<FormControlLabel
								value={'new'}
								control={<Radio />}
								label={'We’re new to running payroll'}
							/>
							<FormControlLabel
								value={'another'}
								control={<Radio />}
								label={'We use another online payroll software'}
							/>
							<FormControlLabel
								value={'manually'}
								control={<Radio />}
								label={'We do it ourselves manually'}
							/>
						</RadioGroup>
					</FormControl>
					<FreepayrollButton
						className={'default-black-button sign-up-button'}
						variant={'primary'}
						onClick={async () => {
							try {
								const { id } = await createLead({
									leads: {
										lead_size: state.employees,
										current_payroll: state.payroll,
									},
								});
								setLeadId(id);
								setStep(step + 1);
							} catch (e) {
								setStep(step);
							}
						}}
						isLoading={isCreatingLead}
						disabled={!state.payroll || !state.employees}
					>
						SUBMIT
					</FreepayrollButton>
				</CardContent>
			</Card>
			<div id={'already-using-freepayroll'} style={{}}>
				<Typography>
					Already using FreePayroll? <a href={'/login'}>Sign in here.</a>
				</Typography>
			</div>
		</div>
	);
};
