import React, { useRef } from 'react';
import StyleTypography from '../../components/StyledComponents/StyleTypography';
import { Download } from '@mui/icons-material';
import { ReviewDataModal } from '../Pages/People/ReviewDataModal';

const BulkEmployeesFps = ({
	openFps = false,
	uploadedData = [],
	uploadMethod = ' ',
	setOpenFps = () => {},
	bulkEmployeeSave = () => {},
	bulkEmployeeImport = () => {},
	setUploadedData = () => {},
	setUploadMethod = () => {},
	submissionErrors = [],
}): JSX.Element => {
	const fileInputRef = useRef(null);

	const handleFileInputClick = () => {
		fileInputRef.current.click();
	};
	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const fileName = file.name;
			const fileExtension = fileName.split('.').pop().toLowerCase();
			if (uploadMethod === 'fps_ytd' || uploadMethod === 'fps') {
				if (fileExtension === 'xml') {
					bulkEmployeeImport(file, setUploadedData);
				} else {
					alert('The import method selected, requires a XML file.');
				}
			}
		}
	};

	const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
		const newRows = uploadedData.slice();
		for (let i = fromRow; i <= toRow; i++) {
			newRows[i] = { ...newRows[i], ...updated };
		}
		setUploadedData(newRows);
	};

	return (
		<div style={{ display: 'flex', flexWrap: 'wrap' }}>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				{openFps && (
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
							width: '100%',
						}}
						id={'bulk-employees-container'}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								width: '100%',
								//@media (min-width:900px): width: 50% would be needed for full responsiveness, but inline media queries aren't supported directly.
							}}
							id={'bulk-employees-upload-box'}
							onClick={handleFileInputClick}
						>
							<Download />
							<StyleTypography
								color={'#000'}
								fontSize={30}
								fontSizeMedium={25}
								fontWeight={'bold'}
								textAlign={'center'}
							>
								Drag and drop your files here or chose file
							</StyleTypography>

							<input
								type="file"
								ref={fileInputRef}
								onChange={handleFileChange}
								style={{ display: 'none' }}
							/>

							<StyleTypography
								color={'#ABAEB7'}
								fontSize={18}
								fontSizeMedium={15}
							>
								Upload files to this project
							</StyleTypography>
						</div>
					</div>
				)}
			</div>
			<ReviewDataModal
				open={uploadedData.length > 0}
				setOpen={() => {}}
				setOpenFps={setOpenFps}
				uploadedData={uploadedData}
				onGridRowsUpdated={onGridRowsUpdated}
				bulkEmployeeSave={bulkEmployeeSave}
				submissionErrors={submissionErrors}
				uploadMethod={uploadMethod}
				setUploadedData={setUploadedData}
				setUploadedMethod={setUploadMethod}
			/>
		</div>
	);
};

export default BulkEmployeesFps;
