import {
	useMutation,
	useQuery, useQueryClient,
} from '@tanstack/react-query';
import Request from "../utils/Request";
import {toast} from "sonner";
import {useNavigate} from "react-router-dom";

export function useGetNiCategories() {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['ni_categories'],
		queryFn: async () => {
			return await Request.get('/api/employees/ni-categories')
				.then(response => response.data)
		},
		staleTime: 1000 * 60 * 60, // 1 hour
		refetchOnWindowFocus: false,
	});

	return {
		niCategories: data?.data,
		isPending,
		isError,
	};
}

export function useListEmployees({page, orderBy, orderDir, search, filters}) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['employees', {page, orderBy, orderDir, search, ...filters}],
		queryFn: async () => {
			return await Request.get(`/api/employees/get-employees`,{params: {page, orderBy, orderDir, search, ...filters}})
				.then(response => response.data)
		},
		keepPreviousData: false,
		staleTime: 1000 * 60 * 60, // 1 hour
	});

	return {
		employees: data?.data,
		isPending,
		isError,
	};
}

export function useDeleteEmployee(id) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (id) => {
			return await Request.delete(`/api/employees/delete-employee/${id}`)
				.then(response => response.data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['employees'] });
			toast.success('Employee deleted');
		},
	});
}

export function useAddEmployee() {

	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return useMutation({
		mutationFn: async ({data, isOnboarding }) => {
			return await Request.post(`/api/employees/`, data)
				.then(response => response.data);
		},
		onSuccess: (_, variables) => {
			if (variables.isOnboarding) {
				queryClient.invalidateQueries({ queryKey: ['employer'] });
				navigate('/main');
				toast.success('Onboarding completed');
			} else {
				queryClient.invalidateQueries({ queryKey: ['employees'] });
				navigate('/main/people/team-members');
				toast.success('Employee added');
			}
		},
	});
}

export function useCompleteEmployeeSetup() {

	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employees/complete-setup`, data)
				.then(response => response.data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['employees'] });
			toast.success('Employee setup completed');
			navigate('/main/people/team-members');
		},
	});
}

export function useUpdateDirectorDetails() {

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({safeId, data}) => {
			return await Request.post(`/api/employees/director-details/${safeId}`, data)
				.then(response => response.data);
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['employee'] });
			toast.success('Director details updated');
		},
	});
}

export function useUpdateEmployee() {

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({safeId, data}) => {
			return await Request.put(`/api/employees/${safeId}`, data)
				.then(response => response.data);
		},

		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['employee'] });
			queryClient.invalidateQueries({ queryKey: ['employees'] });
			queryClient.invalidateQueries({ queryKey: ['employer'] });
			queryClient.invalidateQueries({ queryKey: ['pay-schedules'] });
			toast.success('Employee details updated');
		},
	});
}

export function useGetEmployeePayslips({ page, id }) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['employee_payslips', page, id],
		queryFn: async () => {
			return await Request.get(`/api/employees/${id}/payslips?page=${page}`)
				.then(response => response.data)
		},
		onError: (error) => {
			return error;
		},
	});

	return {
		payslips: data?.data,
		isPending,
		isError,
	};
}

export function useViewPayslip() {

	return useMutation({
		mutationFn: async ({id, safeId}) => {
			return await Request.get(`/api/employees/${safeId}/payslips/view/${id}`,{ responseType: 'blob' })
				.then(response => {
					return response;
				});
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			const url = window.URL.createObjectURL(new Blob([data?.data], { type: 'application/pdf' }));
			const link = document.createElement('a');
			link.href = url;
			link.target = '_blank'; // This will open the PDF in a new tab
			document.body.appendChild(link);
			link.click();
		},
	});
}

export function useEmailPayslip() {

	return useMutation({
		mutationFn: async ({id, safeId}) => {
			return await Request.get(`/api/employees/${safeId}/payslips/email/${id}`)
				.then(response => {
					return response;
				});
		},
		onError: (error) => {
			return error;
		},
		onSuccess: () => {
			toast.success('Payslip sent');
		},
	});
}

export function useDownloadEmployeePayslips() {

	return useMutation({
		mutationFn: async (safeId) => {
			return await Request.get(`/api/employees/${safeId}/payslips/download`,{ responseType: 'blob' })
				.then(response => {
					return response;
				});
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			const url = window.URL.createObjectURL(new Blob([data.data], { type: 'application/zip' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'payslips.zip');
			document.body.appendChild(link);
			link.click();
		},
	});
}

export function usePaymentAfterLeaving() {

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employees/payment-after-leaving`,data)
				.then(response => {
					return response;
				});
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			toast.success('Payment after leaving updated');
		},
	});
}

export function useReadFps(setUploadedData) {

	return useMutation({
		mutationFn: async (file) => {
			const formData = new FormData();
			formData.append('file', file);
			return await Request.post(`/api/employees/bulk-add-import`,formData,{headers: {'Content-Type': 'multipart/form-data'}})
				.then(response => {
					return response?.data;
				});
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			setUploadedData(data?.data);
			toast.success('FPS imported successfully');
		},
	});
}

export function useBulkImportFps(isOnboarding,setSubmissionErrors) {

	const queryClient = useQueryClient();
	const push = useNavigate();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employees/bulk-add-save`,data)
				.then(response => {
					return response;
				});
		},
		onError: (error) => {
			if (error.response.status === 400) {
				setSubmissionErrors(error.response.data.errors);
			}
			return error;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['employees'] });
			if (!isOnboarding) {
				push('/main/people/team-members');
			} else {
				queryClient.invalidateQueries({ queryKey: ['employer'] });
				push('/main');
			}
			toast.success('Employees imported successfully');
		},
	});
}
