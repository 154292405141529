import Typography from '@mui/material/Typography';
import PinInput from 'react-pin-input';
import { useEffect, useState, useCallback } from 'react';
import Loading from '../../components/Loading';
import StyleTypography from '../../components/StyledComponents/StyleTypography';
import { useUser } from '../../context/UserContext';
import { useConfirmEmail, useResendEmail } from '../../hooks/user';
import { FreepayrollButton } from '@collegia-partners/ui-kit';

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmEmail = () => {
	const { profileData: user, isPending: isLoadingUser } = useUser();

	const [canResend, setCanResend] = useState(true);
	const { mutate: confirmEmail, isPending: isConfirmingEmail } =
		useConfirmEmail();
	const { mutate: resendEmail, isPending: isResendingEmail } = useResendEmail();

	const [pin, setPin] = useState('');

	useEffect(() => {
		if (user?.temporary_password) {
			resendEmail();
		}
	}, [resendEmail, user?.temporary_password]);

	const toggleResend = useCallback(
		(resend = true) => {
			setCanResend(resend);

			const timeout = setTimeout(() => setCanResend(true), 60000);

			return () => clearTimeout(timeout);
		},
		[setCanResend],
	);

	if (isLoadingUser || isResendingEmail) {
		return <Loading />;
	}

	return (
		<div className={'confirm-email-wrapper'}>
			<div id={'confirm-email-image'} />

			<div id={'confirm-email-container'}>
				<div
					style={{
						width: '100%',
						textAlign: 'center',
						paddingBottom: '13px',
					}}
				>
					<StyleTypography
						fontSize={50}
						fontSizeMedium={36}
						fontWeight={'bold'}
					>
						Enter the code <br />
						to confirm your email
					</StyleTypography>
				</div>

				<div
					style={{
						width: '100%',
						textAlign: 'center',
					}}
				>
					<StyleTypography fontSize={22} fontSizeMedium={15} color={'#000000'}>
						Enter the code we sent to the address <b>{user?.email}</b> to
						confirm your email
					</StyleTypography>
				</div>

				<div
					style={{
						width: '100%',
					}}
				>
					<PinInput
						className={'pin-input'}
						length={6}
						initialValue={pin}
						type={'numeric'}
						onChange={(value) => setPin(value)}
						onComplete={(value) => setPin(value)}
					/>
				</div>

				<div />

				{canResend ? (
					<Typography
						id={'resend'}
						className={'typography-p'}
						onClick={() => {
							resendEmail();
							toggleResend(false);
						}}
					>
						Didn't receive it?{' '}
						<span className={'resend-sub'}>Click here to resend it</span>.
					</Typography>
				) : (
					<Typography id={'resend'} className={'typography-p'}>
						You can resend it after 1 minute.
					</Typography>
				)}

				<FreepayrollButton
					className={'default-black-button'}
					isLoading={isConfirmingEmail}
					variant={'primary'}
					disabled={pin.length < 6}
					onClick={() => {
						confirmEmail({
							users: {
								pin,
							},
						});
					}}
				>
					CONFIRM
				</FreepayrollButton>
			</div>
		</div>
	);
};

export default ConfirmEmail;
