import { InnerStepZero } from './InnerSteps/InnerStepZero';
import { InnerStepOne } from './InnerSteps/InnerStepOne';
import { InnerStepTwo } from './InnerSteps/InnerStepTwo';

function _renderInnerStep(stepTwo, setStepTwo, setTabIndex, basicSetup) {
	switch (stepTwo.inner_step) {
		case 0:
			return (
				<InnerStepZero
					stepTwo={stepTwo}
					setStepTwo={setStepTwo}
					basicSetup={basicSetup}
				/>
			);
		case 1:
			return (
				<InnerStepOne
					stepTwo={stepTwo}
					setStepTwo={setStepTwo}
					basicSetup={basicSetup}
				/>
			);
		case 2:
			return (
				<InnerStepTwo
					stepTwo={stepTwo}
					setStepTwo={setStepTwo}
					basicSetup={basicSetup}
				/>
			);

		default:
			return;
	}
}

export const StepTwo = ({ stepTwo, setStepTwo, setTabIndex, basicSetup }) => {
	return (
		<div id={'step-two'}>
			{_renderInnerStep(stepTwo, setStepTwo, setTabIndex, basicSetup)}
		</div>
	);
};
