import {
	keepPreviousData, useMutation,
	useQuery
} from '@tanstack/react-query';
import Request from "../utils/Request";
import * as XLSX from "xlsx";
import FileDownload from "js-file-download";

export function useGetMandateInfo() {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['mandate_info'],
		queryFn: async () => {
			return await Request.get(`/api/employers/auto-enrolment/get-mandate-info`)
				.then(response => response?.data?.data ?? {});
		},
		keepPreviousData: true,
		staleTime: 1000 * 60 * 5, // 5 minutes
	});
	
	return {
		mandate_info: data,
		isPending,
		isError,
	};
}

export function useGetPensionPaymentsPast({page, orderBy, orderDir}) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['pension_payments_past', {page, orderBy, orderDir}],
		queryFn: async () => {
			return await Request.get(`/api/employers/auto-enrolment/pension-payments-past`, {
				params: {
						page,
						orderBy,
						orderDir
				}
			}).then(response => response?.data ?? {});
		},
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // 5 minutes
	});

	return {
		pension_payments_past: data?.data,
		isPending,
		isError,
	};
}

export function useGetPensionPaymentsUpcoming({page, orderBy, orderDir}) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['pension_payments_upcoming', {page, orderBy, orderDir}],
		queryFn: async () => {
			return await Request.get(`/api/employers/auto-enrolment/pension-payments-upcoming`,
				{
					params: {
						page,
						orderBy,
						orderDir
					}
				}).then(response => response?.data ?? {});
		},
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // 5 minutes
	});
	return {
		pension_payments_upcoming: data?.data,
		isPending,
		isError,
	};
}

export function useGetPensionPaymentsOptOut() {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['pension_payments_opt-out'],
		queryFn: async () => {
			return await Request.get(`/api/employers/auto-enrolment/pension-payments-opt-out`)
				.then(response => response?.data?.data ?? {});
		},
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // 5 minutes
	});

	return {
		pension_payments_opt_out: data,
		isPending,
		isError,
	};
}

export function useDownloadBreakdown() {
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post("api/employers/auto-enrolment/download-pension-payment-breakdown", data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			const worksheet = XLSX.utils.json_to_sheet(data.data);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
			const excelFileContent = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

			FileDownload(
				excelFileContent,
				`pension-payment-breakdown.xlsx`
			);
		},
	});
}

export function useDownloadInvoice() {
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post("api/employers/auto-enrolment/download-pension-payment-invoice", data,{ responseType: 'blob' })
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
			const link = document.createElement('a');
			link.href = url;
			link.target = '_blank'; // This will open the PDF in a new tab
			document.body.appendChild(link);
			link.click();
		},
	});
}