import {FreepayrollButton, Icon, Typography} from "@collegia-partners/ui-kit";
import React from "react";
import {clsx} from "clsx";

type CompletedContentProps = {
	mode: "approved" | "rejected" | "completed";
	approvalAction: string;
	scheduleName: string;
	taxPeriod: string;
	payDate: string;
	onButtonClick: () => void;
	onDownloadClick?: () => void;
}


export const CompletedContent = ({
	mode,
	approvalAction,
	scheduleName,
	taxPeriod,
	payDate,
	onButtonClick,
	onDownloadClick,
}: CompletedContentProps) => {
	window.scrollTo(0,0);
	return (
		<div id={'completed-payroll-approval'}>
			<div className={"TitleContainer"}>
				{
					mode === "rejected" ? (
						<Icon iconName={"error"} className={"CustomIcon"}/>
					) : (
						<Icon iconName={"check"} className={"CustomIcon"}/>
					)
				}
				<Typography
					variant={"title"}
					size={"xlarge"}
					color={"primary"}
					className={clsx({
						"RejectedTitle": mode === "rejected",
					})}
				>
					{approvalAction}
				</Typography>
			</div>
			<div className={"PayrollInformation"}>
				<Typography variant={"body"} size={"xmedium"} weight={"bold"} color={"black"}>
					• Pay Schedule: {scheduleName}
				</Typography>
				<Typography variant={"body"} size={"xmedium"} weight={"bold"} color={"black"}>
					• Tax Period: {taxPeriod}
				</Typography>
				<Typography variant={"body"} size={"xmedium"} weight={"bold"} color={"black"}>
					• Pay Date: {payDate}
				</Typography>
			</div>
			{
				mode === "rejected" ? (
					<div className={"WhatHappensNow"}>
						<Typography variant={"body"} size={"medium"} weight={"bold"} color={"black"}>
							What happens now?
						</Typography>
						<Typography variant={"body"} color={"black"} className={"RejectExplanation"}>
							You have successfully rejected the payroll request. The bureau has been notified of your
							decision and will review the details. They will make the necessary adjustments and submit
							a new approval request for your review. If you have any specific feedback,
							please feel free to communicate directly with your bureau to ensure all changes
							are made accordingly.
						</Typography>
					</div>
				) : (
					<div className={"WhatHappensNow"}>
						<Typography variant={"body"} size={"medium"} weight={"bold"} color={"black"}>
							{mode === 'completed' ? "We are now sending" : "Once bureau manager closes the pay run, we will send:"}
						</Typography>
						<Typography variant={"body"} color={"black"} className={"ListItem"}>
							• Your Full Payment Submission (FPS) to HMRC
						</Typography>
						<Typography variant={"body"} color={"black"} className={"ListItem"}>
							• Payslips to workers
						</Typography>
						<Typography variant={"body"} color={"black"}>
							We submit your Employer Payment Submission (EPS) to HMRC every 18th day of each month.
						</Typography>
					</div>
				)
			}
			{
				mode === "completed" && (
					<>
						<div>
							<Typography variant={"body"} color={"black"}>
								<strong>Reminder:</strong>Don't forget to pay your employees their net wages.
							</Typography>
						</div>
						<div>
							<button
								onClick={onDownloadClick}
								className={"DownloadInstructions"}
							>
								Click here to download net pay instructions.
							</button>
						</div>
					</>
				)
			}
			<FreepayrollButton
				variant={"primary"}
				size={"medium"}
				className={"BrilliantButton"}
				onClick={onButtonClick}
			>
				Brilliant!
			</FreepayrollButton>
		</div>
	)
};