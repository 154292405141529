import logo from "../../images/svg/Logos/new-fp-logo-blakc-blue-horizontal.svg";
import image404 from "../../images/svg/Errors/404.svg";
import StyleTypography from "../../components/StyledComponents/StyleTypography";

const Page404 = () => {

	return (
		<div className="main-page">
      <div 
        className="error-page-container" 
        id="error-page-container"
      >
        <div 
          className="error-page-content error-page-left" 
          id="error-page-left-inner-container"
        >
          <img src={logo} alt="FreePayroll logo" />
          <StyleTypography
            fontSize={60}
            fontSizeMedium={55}
            fontWeight="bold"
            color="#275EF3"
            component="h1"
          >
            Page Not Found
          </StyleTypography>
          <StyleTypography
            fontSize={18}
            fontSizeMedium={15}
            color="#ABAEB7"
          >
            The page you requested cannot be found, it might have expired or been removed.
          </StyleTypography>
        </div>

        <div 
          className="error-page-content error-page-right" 
          id="error-page-right-inner-container"
        >
          <img src={image404} alt="Not Found" />
        </div>
      </div>
    </div>
	)
};

export default Page404;