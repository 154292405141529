import React from "react";
import {
	FreepayrollButton,
	FreepayrollDropbox,
	Icon,
	Typography
} from "@collegia-partners/ui-kit";
import {
	useDownloadEmployeePayslips, useEmailPayslip,
	useGetEmployeePayslips, useViewPayslip,
} from "../../../../../hooks/employees";
import {formatDateFromBackend, formatMoney} from "../../../../../utils/Helpers";
import {useParams} from "react-router-dom";
import FPTable from "../../../../../components/common/FPTable/FPTable";


export const PayslipsTab = () => {

	const employeeId = useParams().employeeId;

	const { mutateAsync: emailPayslip, isPending: isSending } = useEmailPayslip()
	const { mutateAsync: downloadEmployeePayslips, isPending: isDownloading } = useDownloadEmployeePayslips();
	const { mutateAsync: viewPayslip, isPending: isViewing } = useViewPayslip();

	const columns = [
		{
			text: 'Tax Period',
			key: 'tax_period',
		},
		{
			text: 'Pay Date',
			key: 'employer_pay_schedule_run.period_end_date',
			customRender: (row) => (
				<Typography
					variant="body"
					size="medium"
					color={"black"}
				>
					{formatDateFromBackend(row.employer_pay_schedule_run.period_end_date)}
				</Typography>
			),
		},
		{
			text: 'Net Pay',
			customRender: (row) => (
				<Typography
					variant="body"
					size="medium"
					color={"black"}
				>
					{formatMoney(row.net_pay)}
				</Typography>
			),
		},
		{
			text: 'Actions',
			width: '0.1fr',
			customRender: (row) => (
				<FreepayrollDropbox
					target={
						<button
							style={{all: "unset", cursor: "pointer"}}
						>
							<Icon
								iconName="dots-horizontal"
								className={"EmployeeSummaryIcon"}
							/>
						</button>
					}
					className={"EmployeeSummaryActions"}
				>
					<button
						onClick={async () => {
							try {
								await viewPayslip({
									id: row.id,
									safeId: employeeId,
								});
							} catch (error) {
								console.error(error);
							}
						}}
						disabled={isSending || isViewing}
						className={"EmployeeSummaryAction"}
					>
						{isViewing ? "Opening payslip..." : "View Payslip"}
					</button>
					<button
						onClick={async () => {
							try {
								await emailPayslip({
									id: row.id,
									safeId: employeeId,
								});
							} catch (error) {
								console.error(error);
							}
						}}
						disabled={isSending || isViewing}
						className={"EmployeeSummaryAction"}
					>
						{isSending ? "Emailing payslip..." : "Email Payslip"}
					</button>
				</FreepayrollDropbox>
			),
		}
	];

	return (
		<div style={{display: "flex", flexDirection: "column", gap: "3vw"}}>
			<div style={{ display: "flex", justifyContent: "space-between"}} >
				<Typography
					variant={"subtitle"}
					size={"xmedium"}
				>
					Payslips
				</Typography>
				<FreepayrollButton
					variant={"primary"}
					size={"small"}
					fullWidth={false}
					custom={true}
					height={0.5}
					isLoading={isDownloading}
					onClick={async () => {
						try {
							await downloadEmployeePayslips(employeeId);
						} catch (error) {
							console.error(error);
						}
					}}
				>
					Download all
				</FreepayrollButton>
			</div>
			<FPTable
				headers={columns}
				hookName={useGetEmployeePayslips}
				hookKey={'payslips'}
				hookParams={{
					id: employeeId,
					page: 1,
				}}
			/>

		</div>
	);
};
