import StyleTypography from '../../StyledComponents/StyleTypography';
import { InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import StyleTable from '../../StyledComponents/StyleTable';
import { CurrencyInputCustom } from '../../StyledComponents/CurrencyInputCustom';

/**
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeesTable = ({
	tabIndex,
	setUpdateEmployeePayRuns,
	updateEmployeePayRuns,
	employee_pay_runs,
	setSelectedEmployee,
	showSummary,
	setShowSummary,
}) => {
	const columns = [
		{
			name: 'employee.payroll_id',
			label: 'Payroll ID',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={'#000000'}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			},
		},
		{
			name: 'employee',
			label: 'Employee',
			options: {
				filter: false,
				sort: false,
				setCellProps: () => ({ className: 'cell-style' }),
				customBodyRender: (value, tableMeta) => (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={'#000000'}
								fontWeight={'bold'}
							>
								{value?.forename} {value?.surname}
							</StyleTypography>
							{value?.on_leave && (
								<StyleTypography fontSize={16} fontSizeMedium={12}>
									On Leave
								</StyleTypography>
							)}
							{value?.rolled_back && (
								<StyleTypography fontSize={16} fontSizeMedium={12}>
									Amended
								</StyleTypography>
							)}
							{value?.first_payroll_run && (
								<StyleTypography fontSize={16} fontSizeMedium={12}>
									First time payroll
								</StyleTypography>
							)}
							{value?.status === 'Terminated' && value?.active && (
								<StyleTypography
									fontSize={16}
									fontSizeMedium={12}
									color={'red'}
								>
									Terminating
								</StyleTypography>
							)}
							{value?.payment_after_leaving && (
								<StyleTypography
									fontSize={16}
									fontSizeMedium={12}
									color={'red'}
								>
									Payment after leaving
								</StyleTypography>
							)}
						</div>
					</div>
				),
			},
		},
		{
			name: 'work_hours',
			label: 'Hours Worked',
			options: {
				filter: false,
				sort: false,
				display: tabIndex === 1,
				customBodyRender: (value, tableMeta, updateValue) => (
					<TextField
						className={'default-text-field'}
						value={value ?? 0.0}
						onChange={(e) => {
							updateValue(e.target.value);
							setUpdateEmployeePayRuns((prev) => {
								const newUpdateEmployeePayRuns = [...prev];
								newUpdateEmployeePayRuns[tableMeta.rowIndex] = {
									...newUpdateEmployeePayRuns[tableMeta.rowIndex],
									work_hours: e.target.value,
								};
								return newUpdateEmployeePayRuns;
							});
						}}
						slotProps={{
							input: {
								inputComponent: CurrencyInputCustom,
								inputProps: { prefix: '' },
							},
						}}
					/>
				),
			},
		},
		{
			name: 'employee.salary.hourly_salary',
			label: 'Hourly Rate',
			options: {
				filter: false,
				sort: false,
				setCellProps: () => ({ className: 'cell-style' }),
				display: tabIndex === 1,
				customBodyRender: (value) =>
					parseFloat(value ?? 0).toLocaleString('en-GB', {
						style: 'currency',
						currency: 'GBP',
					}),
			},
		},
		{
			name: 'gross_income_for_tax',
			label: 'Gross Pay',
			options: {
				filter: true,
				sort: false,
				display: tabIndex !== 1,
				setCellProps: () => ({ className: 'cell-style' }),
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={'#000000'}
						fontWeight={'bold'}
					>
						{parseFloat(value ?? 0).toLocaleString('en-GB', {
							style: 'currency',
							currency: 'GBP',
						})}
					</StyleTypography>
				),
			},
		},
		{
			name: 'net_pay',
			label: 'Net Pay',
			options: {
				filter: true,
				sort: false,
				display: tabIndex !== 1,
				setCellProps: () => ({ className: 'cell-style' }),
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={'#000000'}
						fontWeight={'bold'}
					>
						{parseFloat(value ?? 0).toLocaleString('en-GB', {
							style: 'currency',
							currency: 'GBP',
						})}
					</StyleTypography>
				),
			},
		},
	];
	const [searchText, setSearchText] = useState('');

	return (
		<div
			className={'team-member-table-container'}
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'space-between',
				width: '100%',
			}}
		>
			<div style={{ flexGrow: 1 }}>
				<div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
					<TextField
						className={'default-text-field'}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchIcon sx={{ fontSize: '1.25vw' }} />
								</InputAdornment>
							),
							placeholder: 'Search employee',
						}}
						fullWidth
						onChange={(e) => {
							setSearchText(e.target.value);
						}}
					/>
				</div>
				<StyleTable
					columns={columns}
					data={updateEmployeePayRuns ?? []}
					options={{
						selectableRowsHeader: true,
						selectableRows: 'none',
						responsive: 'simple',
						enableNestedDataAccess: '.',
						selectToolbarPlacement: 'none',
						searchAlwaysOpen: false,
						print: false,
						download: false,
						search: false,
						viewColumns: false,
						elevation: 0,
						filter: false,
						serverSide: false,
						searchProps: {
							placeholder: 'Search employee',
							classes: {
								input: 'default-text-field',
							},
						},
						searchText: searchText,
						customSearchRender: () => null,
						customSearch: (searchQuery, currentRow) => {
							let isFound = false;
							currentRow.forEach((col) => {
								if (col == null) {
									return;
								}
								if (
									(col.forename + ' ' + col.surname)
										.toUpperCase()
										.includes(searchQuery.toUpperCase()) ||
									col.payroll_id
										?.toUpperCase()
										.includes(searchQuery.toUpperCase())
								) {
									isFound = true;
								}
							});
							return isFound;
						},

						onRowClick: (rowData, rowMeta) => {
							if (tabIndex === 1) {
								return;
							}
							setSelectedEmployee(employee_pay_runs[rowMeta.dataIndex]);
							setShowSummary(!showSummary);
						},
						setRowProps: () => {
							if (tabIndex === 1) {
								return;
							}
							return {
								style: { cursor: 'pointer' },
							};
						},
					}}
					removeToolbar={true}
				/>
			</div>
		</div>
	);
};

export default EmployeesTable;
