import StyleTypography from './StyledComponents/StyleTypography';
import { useNavigate } from 'react-router-dom';

const RightMenuItem = ({
	icon = '',
	link = '',
	name = '',
	external = false,
}): JSX.Element => {
	const navigate = useNavigate();

	return (
		<div
			className={'right-menu-item'}
			onClick={
				external
					? () => window.open(link, '_bank', 'noreferrer')
					: () => navigate(link)
			}
		>
			<StyleTypography fontSize={18} fontSizeMedium={16} color={'#BBBBC3'}>
				<img className={'right-menu-icon'} src={icon} alt={name} />
				{name}
			</StyleTypography>
		</div>
	);
};

export default RightMenuItem;
