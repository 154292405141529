import React, { useEffect, useState } from 'react';
import StyleTypography from '../../../components/StyledComponents/StyleTypography';
import Loading from '../../../components/Loading';
import { MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import BulkEmployeesFps from '../../../components/People/BulkEmployeesFps';
import BulkEmployeesCsv from '../../../components/People/BulkEmployeesCsv';
import { Download } from '@mui/icons-material';
import { useLoggedLayout } from '../../../context/LoggedLayoutContext';
import { useGetEmployer } from '../../../hooks/employer';
import { useBulkImportFps, useReadFps } from '../../../hooks/employees';
import { useGetPayrollAndEmail } from '../../../hooks/employee/useGetPayrollAndEmail';
import { FreepayrollButton } from '@collegia-partners/ui-kit';

const handleDownload = () => {
	const link = document.createElement('a');
	link.href = '/FreePayroll-Template-Final.csv';
	link.download = 'FreePayroll-Template-Final.csv';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const BulkEmployees = ({ isOnboarding = false }) => {
	const [submissionErrors, setSubmissionErrors] = useState([]);
	const [uploadedData, setUploadedData] = useState([]);

	const { setLayoutProps } = useLoggedLayout();

	const { emails, payroll } = useGetPayrollAndEmail();
	const { employer } = useGetEmployer({ relations: ['pay_schedules'] });
	const { mutate: readFps, isPending: isReadingFps } =
		useReadFps(setUploadedData);
	const { mutate: importFps, isPending: isImportingFps } = useBulkImportFps(
		isOnboarding,
		setSubmissionErrors,
	);

	const [uploadMethod, setUploadMethod] = useState(' ');
	const [openFps, setOpenFps] = useState(false);
	const [openCsv, setOpenCsv] = useState(false);

	useEffect(() => {
		if (uploadedData.length > 0) {
			setOpenFps(true);
		}
	}, [uploadedData.length]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'People',
			showBackButton: true,
			backLink: '/main/people/team-members',
		});
	}, [setLayoutProps]);

	if (isImportingFps || isReadingFps) {
		return <Loading />;
	}
	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
			}}
		>
			<div
				style={{
					flex: '0 0 100%',
					maxWidth: '100%',
				}}
			>
				<div>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={31}
						fontWeight={'bold'}
						component={'h1'}
					>
						Bulk Add Employee
					</StyleTypography>
				</div>
				<div className={'sub-title-container'}>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={14}
						color={'#000000'}
						component={'h2'}
					>
						Here you can add multiple employees in one single go. <br />
						Please select the method of import first.
					</StyleTypography>
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					margin: '1vw 0',
				}}
			>
				<a
					href="https://help.freepayroll.uk/en/article/importing-employees-from-a-csv-file-9iodcm/"
					target="_blank"
					rel="noreferrer"
				>
					<FreepayrollButton
						variant="blue-button"
						custom
						height={0.3}
						width={3}
					>
						CSV Guide
					</FreepayrollButton>
				</a>
			</div>

			<div
				id={'bulk-employees-download-template'}
				style={{
					display: 'flex',
					alignItems: 'center',
					flex: '0 0 100%',
					maxWidth: '100%',
				}}
			>
				<StyleTypography
					color={'#000'}
					fontSize={16}
					fontSizeMedium={13}
					fontWeight={500}
					className={'download-template'}
					onClick={handleDownload}
				>
					<Download />
					Click here to download the csv template
				</StyleTypography>
			</div>

			<div
				style={{
					flex: '0 0 41.6667%',
					maxWidth: '41.6667%',
					marginTop: 16,
				}}
			>
				<FormControl fullWidth>
					<Select
						className={'default-select'}
						id={'select-current-provider'}
						value={uploadMethod}
						onChange={(event) => {
							setUploadMethod(event.target.value);
							if (event.target.value === 'csv') {
								setOpenCsv(true);
								setOpenFps(false);
								setUploadMethod(' ');
							} else {
								setOpenFps(true);
								setOpenCsv(false);
							}
						}}
					>
						<MenuItem value={' '} disabled>
							Select
						</MenuItem>
						<MenuItem value={'fps_ytd'}>
							Import employees and YTD figures from FPS
						</MenuItem>
						<MenuItem value={'fps'}>
							Import employees from FPS and disregard YTD figures
						</MenuItem>
						<MenuItem value={'csv'}>Import employees from CSV</MenuItem>
					</Select>
				</FormControl>
				<BulkEmployeesFps
					isOnboarding={isOnboarding}
					openFps={openFps}
					setOpenFps={setOpenFps}
					uploadedData={uploadedData}
					uploadMethod={uploadMethod}
					bulkEmployeeSave={importFps}
					bulkEmployeeImport={readFps}
					setUploadedData={setUploadedData}
					setUploadMethod={setUploadMethod}
					submissionErrors={submissionErrors}
				/>
				<BulkEmployeesCsv
					openCsv={openCsv}
					setOpenCsv={setOpenCsv}
					employeesEmail={emails}
					employeePayroll={payroll}
					isOnboarding={isOnboarding}
					employer={employer}
				/>
			</div>
		</div>
	);
};

export default BulkEmployees;
