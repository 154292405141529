import Axios from 'axios';
import {toast} from "sonner";

/**
 * Request options.
 *
 * @type {{baseURL: *, withCredentials: boolean, timeout: number}}
 */
const options = {
	baseURL: process.env.REACT_APP_API,
	withCredentials: false,
	timeout: 960000,
	headers: {
		Authorization: 'Bearer ' + localStorage.getItem('fpEmployerToken'),
	}
};

// creating the axios instance.
const Request = Axios.create(options);

/**
 * Dynamically add the Authorization header for each request.
 */
Request.interceptors.request.use(
	function(config) {
		// Dynamically get the token from localStorage
		const token = localStorage.getItem('fpEmployerToken');
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	function(error) {
		// Handle request errors
		return Promise.reject(error);
	}
);

/**
 * Creating custom function to parse the params.
 *
 *
 * @example: Create like url?parameter=value&parameter2=valor&parameterArray=[...values]
 * @param params
 * @return {string}
 */
Request.parseParams = (params) => {
	return Object.keys(params).map(function (key) {
		return [key, params[key]].map(encodeURIComponent).join("=");
	}).join("&");
};

Request.interceptors.response.use(
	function(response) {
		return response;
	},
	function(error) {
		// Handle global error responses
		if (error.response) {
			switch (error.response.status) {
				case 500:
					console.error('Server Error:', error.response);
					toast.error("An unexpected server error occurred. Please contact support.");
					break;
				case 403:
					console.error('Forbidden:', error.response);
					toast.error("You don't have permission to manage this resource.");
					break;
				case 401:
					localStorage.clear();
					sessionStorage.clear();
					window.location.href = '/login';
					toast.error('Your session has expired. Please log in again.');
					break;
				default:
					toast.error(`Error: ${error.response.data?.message || 'An error occurred'}`);
			}
		} else if (error.request) {
			console.error('No response received:', error.request);
			toast.error('Network error. Please check your connection and try again.');
		} else {
			console.error('Error during request setup:', error.message);
			toast.error(`Unexpected error: ${error.message}`);
		}
		return Promise.reject(error);
	}
);

export default Request;
