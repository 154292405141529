import React, {useEffect, useState} from "react";
import Loading from "../../../../components/Loading";
import {useLocation} from "react-router-dom";
import {useLoggedLayout} from "../../../../context/LoggedLayoutContext";
import {
	Checkbox,
	Divider, FreepayrollButton,
	FreepayrollSelect,
	FreepayrollTextField,
	Typography
} from "@collegia-partners/ui-kit";
import {useFindPayItem} from "../../../../hooks/employer/pay-items/useFindPayItem";
import {getPayItemType} from "../../../../utils/Helpers";
import {useCreatePayItem} from "../../../../hooks/employer/pay-items/useCreatePayItem";

function PayItemTitle({isRecurring = false, step}) {

	return (
		<div>
			<Typography
				variant={"title"}
			>
				{
					step === 0 ?
						"Pay items"
						:
						isRecurring ? "Recurring Pay Item" : "One-off Pay Item"
				}
			</Typography>
			<Typography
				variant={"subtitle"}
				color={"gray"}
				weight={"normal"}
			>
				{
					step === 0 ?
						"Pay items allow you to set up custom payments and track each payment in the payroll journal"
						:
						isRecurring ?
							"Create recurring pay items that you can apply to employees and have them automatically be added to future payrolls. ( Runs until cancelled )"
							:
							"One-off pay items can be reused in every payroll for every employee."
				}
			</Typography>
		</div>
	);
}

function SelectOneOffOrRecurring({ setIsRecurring, setStep }) {

	const PayItemTypes = [
		{
			title: "One-off",
			description: "Create One-off pay items that you can apply and reuse in every payroll, for every employee. E.g. Sales bonus, commissions",
			buttonText: "+ One-off Pay Item",
			onClick: () => {
				setIsRecurring(false);
				setStep(1);
			}
		},
		{
			title: "Recurring",
			description: "Create recurring pay items that you can apply to employees and have them automatically be added to future payrolls. ( Runs until cancelled )",
			buttonText: "+ Recurring Pay Item",
			onClick: () => {
				setIsRecurring(true);
				setStep(1);
			},
			disabled: true
		}
	];

	return (
		<div style={{ display: 'flex', justifyContent: 'space-between', columnGap: '5vw' }} >
			{
				PayItemTypes.map((item, index) => (
					<div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '1.83vw', alignItems: "flex-start" }}>
						<Typography
							variant={"title"}
						>
							{item.title}
						</Typography>
						<Typography
							variant={"subtitle2"}
							color={"gray"}
							weight={"normal"}
						>
							{item.description}
						</Typography>
						<FreepayrollButton
							variant={'primary'}
							size={'medium'}
							custom={true}
							fullWidth={false}
							width={2}
							height={1}
							onClick={item.onClick}
							disabled={item.disabled || false}
						>
							{item.buttonText}
						</FreepayrollButton>
					</div>
				))
			}
		</div>
	);
}

function PayItemBasicDetails({ payItemDetails, setPayItemDetails, setStep }) {

	const paymentTypeOptions = [
		{ value: "Gross addition", label: "Gross addition" },
		{ value: "Gross deduction", label: "Gross deduction", disabled: payItemDetails?.is_benefit_in_kind },
		{ value: "Net addition", label: "Net addition", disabled: payItemDetails?.is_benefit_in_kind },
		{ value: "Net deduction", label: "Net deduction", disabled: payItemDetails?.is_benefit_in_kind }
	];

	const { mutate: createPayItem, isPending: isCreating } = useCreatePayItem();

	return (
		<div style={{ maxWidth: '50%', alignItems:'flex-start', display: 'flex', flexDirection:'column', gap: '1.23vw' }} >
			<FreepayrollTextField
				fieldType={"text"}
				name={"pay_item_name"}
				label={"Pay Item name*"}
				value={payItemDetails?.name}
				onChange={(e) => {
					setPayItemDetails({
						...payItemDetails,
						name: e.target.value,
					});
				}}
			/>
			<FreepayrollTextField
				fieldType={"text"}
				name={"pay_item_code"}
				label={"Pay Item code (This will be the code displayed on your payroll journal)*"}
				value={payItemDetails?.account_code}
				onChange={(e) => {
					setPayItemDetails({
						...payItemDetails,
						account_code: e.target.value,
					});
				}}
			/>
			<Checkbox
				label="This is a benefit in kind"
				isChecked={payItemDetails?.is_benefit_in_kind || false}
				onChange={(checked) => {
					setPayItemDetails({
						...payItemDetails,
						is_benefit_in_kind: checked,
						is_gross: true,
						is_net: false,
						type: 'Addition',
						before_tax: false,
						before_ni: false,
						before_pension: false,
					});
				}}
			/>
			<FreepayrollSelect
				name={"payment_type"}
				label={"Payment Type*"}
				options={paymentTypeOptions}
				value={getPayItemType(payItemDetails)}
				onSelect={(option) => {
					if (!option) return;
					if (option?.value === "Gross addition") {
						setPayItemDetails({
							...payItemDetails,
							is_gross: true,
							is_net: false,
							type: 'Addition',
						});
					} else if (option?.value === "Gross deduction") {
						setPayItemDetails({
							...payItemDetails,
							is_gross: true,
							is_net: false,
							type: 'Deduction',
							is_benefit_in_kind: false,
						});
					} else if (option?.value === "Net addition") {
						setPayItemDetails({
							...payItemDetails,
							is_gross: false,
							is_net: true,
							type: 'Addition',
							before_tax: false,
							before_ni: false,
							before_pension: false,
							is_benefit_in_kind: false,
						});
					} else {
						setPayItemDetails({
							...payItemDetails,
							is_gross: false,
							is_net: true,
							type: 'Deduction',
							before_tax: false,
							before_ni: false,
							before_pension: false,
							is_benefit_in_kind: false,
						});
					}
				}}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection:'row',
					columnGap: '3vw'
				}}
			>
				<FreepayrollButton
					variant={"white-button"}
					size={"medium"}
					isLoading={isCreating}
					custom
					width={2}
					height={1}
					onClick={() => {
						setStep(0);
						setPayItemDetails({});
					}}
				>
					Cancel
				</FreepayrollButton>
				<FreepayrollButton
					variant={"primary"}
					size={"medium"}
					custom
					width={2.5}
					height={1}
					onClick={() => {
						if (payItemDetails?.is_net) {
							createPayItem({
								name: payItemDetails.name,
								account_code: payItemDetails.account_code,
								is_gross: payItemDetails.is_gross,
								is_net: payItemDetails.is_net,
								type: payItemDetails.type,
								before_tax: payItemDetails.before_tax,
								before_ni: payItemDetails.before_ni,
								before_pension: payItemDetails.before_pension,
							});
						} else {
							setStep(2);
						}
					}}
					isLoading={isCreating}
					disabled={
						!payItemDetails?.name ||
						!payItemDetails?.account_code ||
						!payItemDetails?.type ||
						(!payItemDetails?.is_gross && !payItemDetails?.is_net)
					}
				>
					{ payItemDetails?.is_net ? "Save" : "Next" }
				</FreepayrollButton>
			</div>
		</div>
	);
}

function PayItemTaxImplications({payItemDetails, setPayItemDetails, setStep}) {

	const { mutate: createPayItem, isPending: isCreating } = useCreatePayItem();

	const TaxImplicationItems = [
		{
			label: "Taxable",
			description: "We will include this amount in the total sum used to calculate income tax",
			state: "before_tax",
		},
		{
			label: "Ni-able",
			description: "We will include this amount in the total sum used to calculate national insurance contributions",
			state: "before_ni",
			disabled: payItemDetails?.is_benefit_in_kind,
		},
		{
			label: "Pensionable",
			description: "We will include this amount in the total sum used to calculate pension contributions",
			state: "before_pension",
		}
	]

	return (
		<div style={{display: 'flex', flexDirection: 'column', gap: '1.8vw'}}>
			<div style={{display: 'flex', flexDirection: 'column', gap: '0.83vw'}}>
				<Typography
					variant={"subtitle2"}
					color={"black"}
					weight={"bold"}
				>
					{payItemDetails?.name}
				</Typography>
				<Typography
					variant={"subtitle2"}
					weight={"normal"}
					color={"black"}
				>
					{getPayItemType(payItemDetails)}
				</Typography>
			</div>
			<div>
				<Typography
					variant={"label"}
					color={"black"}
					weight={"normal"}
				>
					( Select all that apply )
				</Typography>
			</div>
			<div style={{display: 'flex', flexDirection: 'column', gap: '1.8vw', alignItems: 'flex-start'}}>
				{
					TaxImplicationItems.map((item, index) => (
						<Checkbox
							key={index}
							label={
								<>
									<Typography
										variant={"subtitle"}
										weight={"normal"}
										color={"black"}
									>
										{item.label}
									</Typography>
									<Typography
										variant={"subtitle"}
										color={"gray"}
										weight={"normal"}
										size={"small"}
									>
										{item.description}
									</Typography>
								</>
							}
							disabled={item.disabled || false}
							isChecked={payItemDetails?.[item.state] || false}
							onChange={(checked) => {
								setPayItemDetails({
									...payItemDetails,
									[item.state]: checked,
								});
							}}
						/>
					))
				}
			</div>
			<div style={{display: 'flex', flexDirection: 'row', columnGap: '3vw', maxWidth: '50%'}}>
				<FreepayrollButton
					variant={"white-button"}
					size={"medium"}
					isLoading={isCreating}
					onClick={() => {
						setStep(0);
						setPayItemDetails({});
					}}
				>
					Cancel
				</FreepayrollButton>
				<FreepayrollButton
					variant={"primary"}
					size={"medium"}
					onClick={() => {
						createPayItem({
							name: payItemDetails.name,
							account_code: payItemDetails.account_code,
							is_gross: payItemDetails.is_gross,
							is_net: payItemDetails.is_net,
							type: payItemDetails.type,
							before_tax: payItemDetails.before_tax,
							before_ni: payItemDetails.before_ni,
							before_pension: payItemDetails.before_pension,
							is_benefit_in_kind: payItemDetails.is_benefit_in_kind,
						});
					}}
					isLoading={isCreating}
				>
					Save
				</FreepayrollButton>
			</div>
		</div>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayItems = (): JSX.Element => {

	const {setLayoutProps} = useLoggedLayout();

	const [step, setStep] = useState(0),
		[isRecurring, setIsRecurring] = useState(false),
		[payItemDetails, setPayItemDetails] = useState({}),
		location = useLocation();

	const encodedPayItemId = (new URLSearchParams(location.search))?.get('id');

	const {payItem, isLoading} = useFindPayItem(encodedPayItemId);

	useEffect(() => {
		setPayItemDetails(payItem);
	}, [payItem]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'Company',
			activeSubpage: 'Pay Items',
			showBackButton: true,
			backLink: '/main/company/pay-items',
		});
	}, [setLayoutProps]);

	if (isLoading) {
		return <Loading/>;
	}

	return (
		<div style={{display: 'flex', flexDirection: 'column', gap: '1.83vw'}}>
			<PayItemTitle
				isRecurring={isRecurring}
				step={step}
			/>
			<Divider color={'light-gray'}/>
			{
				step === 0 && (
					<SelectOneOffOrRecurring
						setIsRecurring={setIsRecurring}
						setStep={setStep}
					/>
				)
			}
			{
				step === 1 && (
					<PayItemBasicDetails
						payItemDetails={payItemDetails}
						setPayItemDetails={setPayItemDetails}
						setStep={setStep}
					/>
				)
			}
			{
				step === 2 && (
					<PayItemTaxImplications
						payItemDetails={payItemDetails}
						setPayItemDetails={setPayItemDetails}
						setStep={setStep}
					/>
				)
			}
		</div>
	);
};

export default PayItems;
