import {useMutation, useQueryClient} from '@tanstack/react-query';
import Request from '../../utils/Request';
import {toast} from "sonner";

export function useOnHold() {

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.put(`/api/employees/on-hold`, data).then(
				(response) => response.data,
			);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['employees'] });
			toast.success('Employee on hold updated');
		},
	});
}
