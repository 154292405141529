import { InnerStepZero } from './InnerSteps/InnerStepZero';
import InnerStepOne from './InnerSteps/InnerStepOne';

function _renderInnerStep(stepOne, setStepOne, basicSetup) {
	switch (stepOne.inner_step) {
		case 0:
			return <InnerStepZero stepOne={stepOne} setStepOne={setStepOne} />;
		case 1:
			return (
				<InnerStepOne
					stepOne={stepOne}
					setStepOne={setStepOne}
					basicSetup={basicSetup}
				/>
			);
		default:
			return;
	}
}

export const StepOne = ({
	stepOne,
	setStepOne,
	basicSetup = () => {},
}) => {
	return (
		<div id={'step-one'}>
			{_renderInnerStep(stepOne, setStepOne, basicSetup)}
		</div>
	);
};
