import {StyleAppBar} from "../../components/StyleAppBar";
import error from "../../images/svg/Errors/NotFound.svg";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import React, {useEffect} from "react";
import StyleButton from "../../components/StyledComponents/StyleButton";
import {useNavigate} from "react-router-dom";
import {Crisp} from "crisp-sdk-web";
import {useMe} from "../../hooks/user";

const ErrorPage = () => {

	const { profileData:user } = useMe();

	const navigate = useNavigate();

	useEffect(() => {
		Crisp.load();
		Crisp.session.setData({
			auth_token : localStorage.getItem('fpEmployerToken'),
		});
		Crisp.user.setEmail(user?.email);
		Crisp.user.setNickname(user?.name);
		Crisp.user.setPhone(user?.phone_number)
	}, [user?.email, user?.name, user?.phone_number])

	return (
		<div className="server-error-page">
      <StyleAppBar
        showBackButton={false}
        user={user}
      />
      <div className="server-error-page__main-container">
        <div className="server-error-page__text-content-wrapper">
          <StyleTypography
            fontSize={60}
            fontSizeMedium={55}
            fontWeight={"bold"}
            color={"#275EF3"}
            component={"h1"}
          >
            Internal Server Error
          </StyleTypography>

          <StyleTypography
            fontSize={18}
            fontSizeMedium={16}
            color={"#ABAEB7"}
          >
            An Internal Server Error occurred. <br/>
            Please contact support.
          </StyleTypography>

          <div className="server-error-page__action-button-wrapper">
            <StyleButton
              fullWidth={false}
              onClick={() => navigate("/main")}
            >
              Back to Home
            </StyleButton>
          </div>
        </div>

        <div className="server-error-page__illustration-wrapper">
          <img
            src={error}
            alt={"Not Found"}
          />
        </div>
      </div>
    </div>
	)
};

export default ErrorPage;