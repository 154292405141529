import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

export const CompanyDetailsCover = ({ setStep = () => {}, step = 0 }) => {
	return (
		<div
			id={'company-details-image'}
			style={{
				display: 'flex',
				width: '100%',
			}}
		>
			<div id={'company-details-image-container'}>
				<Typography className={'typography-h1 white'} variant={'h1'}>
					Let’s set you up in FreePayroll
				</Typography>

				<Typography className={'typography-p white'}>
					Now, we’ll ask you for more details about your company, team, and
					taxes.
				</Typography>

				<hr className={'white-line'} />

				<Button
					className={'default-white-button'}
					onClick={() => setStep(step + 1)}
				>
					Let's go
				</Button>
			</div>
		</div>
	);
};
