import { Dialog, DialogContent, DialogTitle, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import StyleTypography from '../../StyledComponents/StyleTypography';
import { CurrencyInputCustom } from '../../StyledComponents/CurrencyInputCustom';
import StyleButton from '../../StyledComponents/StyleButton';
import { Decimal } from 'decimal.js';
import { useManagePayItem } from '../../../hooks/payroll/useManagePayItem';

const SalaryPayItem = ({ selectedPayItem, setSelectedPayItem }) => {
	return (
		<>
			{/*Hours or Units*/}
			<div
				style={{
					width: '50%',
					display: 'inline-block',
					boxSizing: 'border-box',
					padding: '8px',
				}}
			>
				<InputLabel className={'default-input-label'}>
					{selectedPayItem.salary_type === 'hourly' ? 'Hours' : 'Units'}
				</InputLabel>
				<TextField
					fullWidth
					className={'default-text-field'}
					onChange={(event) => {
						if (event.target.value === '') {
							setSelectedPayItem({
								...selectedPayItem,
								salary_units: event.target.value,
								item_amount: 0,
							});
							return;
						}
						let amount = 0;
						if (selectedPayItem.salary_rate !== '') {
							amount = new Decimal(event.target.value)
								.times(new Decimal(selectedPayItem.salary_rate))
								.toNumber();
						}
						setSelectedPayItem({
							...selectedPayItem,
							salary_units: event.target.value,
							item_amount: amount,
						});
					}}
					value={selectedPayItem.salary_units}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: { prefix: '' },
						endAdornment: '',
					}}
				/>
			</div>

			{/*Salary rate*/}
			<div
				style={{
					width: '50%',
					display: 'inline-block',
					boxSizing: 'border-box',
					padding: '8px',
				}}
			>
				<InputLabel className={'default-input-label'}>Salary Rate*</InputLabel>
				<TextField
					disabled={selectedPayItem.pay_method === 'fixed'}
					fullWidth
					className={'default-text-field'}
					value={selectedPayItem.salary_rate}
					onChange={(event) => {
						if (event.target.value === '') {
							setSelectedPayItem({
								...selectedPayItem,
								salary_rate: event.target.value,
								item_amount: 0,
							});
							return;
						}

						let amount = 0;
						if (selectedPayItem.salary_units !== '') {
							amount = new Decimal(selectedPayItem.salary_units)
								.times(new Decimal(event.target.value))
								.toNumber();
						}

						setSelectedPayItem({
							...selectedPayItem,
							salary_rate: event.target.value,
							item_amount: amount,
						});
					}}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: { prefix: '' },
						endAdornment: '£',
					}}
				/>
			</div>

			{/*Amount*/}
			<div
				style={{
					width: '100%',
					boxSizing: 'border-box',
					padding: '8px',
					display: 'inline-block',
				}}
			>
				<InputLabel className={'default-input-label'}>Amount*</InputLabel>
				<TextField
					disabled
					fullWidth
					className={'default-text-field'}
					value={selectedPayItem.item_amount}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: { prefix: '' },
						endAdornment: '£',
					}}
				/>
			</div>
		</>
	);
};

const OvertimePayItem = ({ selectedPayItem, setSelectedPayItem }) => {
	return (
		<>
			{/*Units*/}
			<div
				style={{
					width: '50%',
					display: 'inline-block',
					boxSizing: 'border-box',
					padding: '8px',
				}}
			>
				<InputLabel className={'default-input-label'}>Units*</InputLabel>
				<TextField
					fullWidth
					className={'default-text-field'}
					onChange={(event) => {
						const amount = new Decimal(event.target.value)
							.times(new Decimal(selectedPayItem.salary_rate))
							.toNumber();

						setSelectedPayItem({
							...selectedPayItem,
							salary_units: event.target.value,
							item_amount: amount,
						});
					}}
					value={selectedPayItem.salary_units}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: { prefix: '' },
						endAdornment: '',
					}}
				/>
			</div>

			{/*Salary rate*/}
			<div
				style={{
					width: '50%',
					display: 'inline-block',
					boxSizing: 'border-box',
					padding: '8px',
				}}
			>
				<InputLabel className={'default-input-label'}>
					Overtime Rate ({selectedPayItem.salary_multiplier}x)
				</InputLabel>
				<TextField
					fullWidth
					className={'default-text-field'}
					value={selectedPayItem.salary_rate}
					onChange={(event) => {
						const amount = new Decimal(selectedPayItem.salary_units)
							.times(new Decimal(event.target.value))
							.toNumber();

						setSelectedPayItem({
							...selectedPayItem,
							salary_rate: event.target.value,
							item_amount: amount,
						});
					}}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: { prefix: '' },
						endAdornment: '£',
					}}
				/>
			</div>

			{/*Amount*/}
			<div
				style={{
					width: '100%',
					boxSizing: 'border-box',
					padding: '8px',
					display: 'inline-block',
				}}
			>
				<InputLabel className={'default-input-label'}>Amount*</InputLabel>
				<TextField
					disabled
					fullWidth
					className={'default-text-field'}
					value={selectedPayItem.item_amount}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: { prefix: '' },
						endAdornment: '£',
					}}
				/>
			</div>
		</>
	);
};

const OneOffPayItem = ({ selectedPayItem, setSelectedPayItem }) => {
	//AMOUNT
	return (
		<div
			style={{
				width: '100%',
				boxSizing: 'border-box',
				padding: '8px',
				display: 'inline-block',
			}}
		>
			<InputLabel className={'default-input-label'}>Amount*</InputLabel>
			<TextField
				fullWidth
				className={'default-text-field'}
				onChange={(event) => {
					setSelectedPayItem({
						...selectedPayItem,
						item_amount: event.target.value,
					});
				}}
				value={Math.abs(selectedPayItem.item_amount ?? 0)}
				InputProps={{
					inputComponent: CurrencyInputCustom,
					inputProps: { prefix: '', allowNegativeValue: false },
					endAdornment: '£',
				}}
			/>
		</div>
	);
};

const EditPayItemModal = ({
	open,
	setOpen,
	selectedPayItem,
	setSelectedPayItem,
	selectedEmployee,
	setSelectedEmployee,
	setUpdateEmployeePayRuns,
	setEmployeePayRuns,
}) => {
	const { mutateAsync: managePayItem } = useManagePayItem();

	return (
		<Dialog
			onClose={() => {
				setSelectedPayItem({});
				setOpen(false);
			}}
			open={open}
			fullWidth={true}
			maxWidth={'xs'}
		>
			<DialogTitle>
				<StyleTypography fontSize={35} fontSizeMedium={28} fontWeight={'bold'}>
					Edit Pay Item
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<div
					style={{
						width: '100%',
						boxSizing: 'border-box',
						padding: '8px',
						display: 'inline-block',
					}}
				>
					{/*Pay Item name*/}
					<div
						style={{
							width: '100%',
							boxSizing: 'border-box',
							padding: '8px',
							display: 'inline-block',
						}}
					>
						<InputLabel className={'default-input-label'}>Pay Item*</InputLabel>
						<TextField
							fullWidth
							disabled
							className={'default-text-field'}
							value={selectedPayItem?.item_name}
						/>
					</div>

					{selectedPayItem.is_salary &&
						selectedPayItem.salary_type !== 'multiplier' && (
							<SalaryPayItem
								selectedPayItem={selectedPayItem}
								setSelectedPayItem={setSelectedPayItem}
							/>
						)}

					{selectedPayItem.is_salary &&
						selectedPayItem.salary_type === 'multiplier' && (
							<OvertimePayItem
								selectedPayItem={selectedPayItem}
								setSelectedPayItem={setSelectedPayItem}
							/>
						)}

					{selectedPayItem.is_salary === false && (
						<OneOffPayItem
							selectedPayItem={selectedPayItem}
							setSelectedPayItem={setSelectedPayItem}
						/>
					)}
				</div>

				<div
					style={{
						width: '100%',
						boxSizing: 'border-box',
						padding: '8px',
						display: 'inline-block',
						marginTop: '16px',
					}}
				>
					<div
						style={{
							width: '50%',
							display: 'inline-block',
							boxSizing: 'border-box',
						}}
					>
						<div
							style={{
								width: '50%',
								display: 'inline-block',
								boxSizing: 'border-box',
								padding: '8px',
							}}
						>
							<StyleButton
								isRedButton={true}
								onClick={async () => {
									try {
										const { employee, data } = await managePayItem({
											pay_schedule_runs: {
												id: selectedPayItem.id,
												employer_pay_item_id:
													selectedPayItem.employer_pay_item_id,
												item_type: selectedPayItem.item_type,
												item_amount: parseFloat(selectedPayItem.item_amount),
												salary_rate: selectedPayItem.salary_rate
													? parseFloat(selectedPayItem.salary_rate)
													: null,
												salary_units: selectedPayItem.salary_units
													? parseFloat(selectedPayItem.salary_units)
													: null,
												action: 'remove',
												employee_id: selectedEmployee.employee_id,
												employee_pay_run_id: selectedEmployee.id,
											},
										});
										setSelectedEmployee(employee);
										setEmployeePayRuns(data);
										setUpdateEmployeePayRuns(data);
										setSelectedPayItem({});
										setOpen(false);
									} catch (e) {
										console.error(e);
									}
								}}
							>
								Remove
							</StyleButton>
						</div>
					</div>
					<div
						style={{
							width: '50%',
							display: 'inline-block',
							boxSizing: 'border-box',
						}}
					>
						<div
							style={{
								width: '50%',
								display: 'inline-block',
								boxSizing: 'border-box',
								padding: '8px',
							}}
						>
							<StyleButton
								isWhiteButton={true}
								onClick={() => {
									setOpen(false);
									setSelectedPayItem({});
								}}
							>
								Cancel
							</StyleButton>
						</div>

						<div
							style={{
								width: '50%',
								display: 'inline-block',
								boxSizing: 'border-box',
								padding: '8px',
							}}
						>
							<StyleButton
								onClick={async () => {
									try {
										const { employee, data } = await managePayItem({
											pay_schedule_runs: {
												id: selectedPayItem.id,
												employer_pay_item_id:
													selectedPayItem.employer_pay_item_id,
												item_type: selectedPayItem.item_type,
												item_amount: parseFloat(selectedPayItem.item_amount),
												salary_rate: selectedPayItem.salary_rate
													? parseFloat(selectedPayItem.salary_rate)
													: null,
												salary_units: selectedPayItem.salary_units
													? parseFloat(selectedPayItem.salary_units)
													: null,
												action: 'update',
												employee_id: selectedEmployee.employee_id,
												employee_pay_run_id: selectedEmployee.id,
											},
										});
										setSelectedEmployee(employee);
										setEmployeePayRuns(data);
										setUpdateEmployeePayRuns(data);
										setSelectedPayItem({});
										setOpen(false);
									} catch (e) {
										console.error(e);
									}
								}}
							>
								Save
							</StyleButton>
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default EditPayItemModal;
