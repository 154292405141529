import {Divider, FreepayrollDropbox, FreepayrollRadio, Icon, Pagination, Typography,Chip} from "@collegia-partners/ui-kit";
import Skeleton from "../../Skeleton/index";
import styles from "./styles.module.scss";
import React, {useEffect, useState} from "react";
import {clsx} from "clsx";
import {Cross2Icon} from "@radix-ui/react-icons";

type HeaderType = {
	text: string;
	width?: string;
	color?: string;
	key?: string;
	hasOrderBy?: boolean;
	filter?: boolean;
	filterOptions?: string[];
	customRender?: (row: any, rowIndex: number) => JSX.Element;
}

function Filter({ filters, setFilters, item }) {

	if (!item.filterOptions) {
		return <div></div>;
	}

	const options = [
		{ value: 'All' }, // Default option
		...item.filterOptions,
	];

	return (
		<FreepayrollDropbox
			target={
				<button
					style={{
						all: 'unset',
						cursor: 'pointer',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Icon iconName="filter" className={styles.FilterIcon} />
				</button>
			}
		>
			<FreepayrollRadio
				options={options}
				onOptionChange={(option) => {
					if (option === 'All') {
						setFilters({});
						return;
					}
					setFilters({
						...filters,
						[item.key]: option
					});
				}}
				checkedOption={filters[item.key] || 'All'}
			/>
		</FreepayrollDropbox>
	);
}

function TableHeader ({ headers, setOrderBy, orderDir, setOrderDir, hideHeader, filters, setFilters }: { headers: HeaderType[] }) {

	const toggleOrderDir = () => setOrderDir((prevDir) => (prevDir === 'asc' ? 'desc' : 'asc'));

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "1vw" }}>
			<div style={{display: "grid", gridTemplateColumns: headers.map(item => item.width || "1fr").join(" ") }}>
				{headers.map((item, index) => (
					<div key={index} style={{ display: 'flex', gap: '10px' }}>

						{/* Sorting Icon */}
						{item.hasOrderBy && (
							<button
								className={styles.ColumnButton}
								onClick={() => {
									if (item.hasOrderBy) {
										toggleOrderDir();
										setOrderBy(item.key);
									}
								}}
								disabled={!item.hasOrderBy && !item.filter}
							>
								<Icon
									iconName="arrow-down"
									className={clsx(styles.Arrow, {
										[styles.Desc]: orderDir === 'asc',
									})}
								/>
							</button>
						)}

						<Typography
							variant="body"
							size="medium"
							color={item.color || "black"}
							weight="bold"
						>
							{item.text}
						</Typography>

						{/* Filter Component */}
						{item.filter && <Filter filters={filters} setFilters={setFilters} item={item}/>}
					</div>
				))}
			</div>
			{
				!hideHeader && (
					<Divider color="light-gray"/>
				)
			}
		</div>
	)
}

function TableRows({headers, rows, fetching}: { headers: HeaderType[], rows: any[], fetching: boolean }) {

	return (
		<>
			{
				fetching ? (
						Array.from({length: 5}).map((_, index) => (
							<div
								key={index}
								style={{
									display: "grid",
									gridTemplateColumns: headers.map((item) => item.width || "1fr").join(" "),
									alignItems: "center",
									padding: "1vw 0",
								}}
							>
								{headers.map((header, headerIndex) => (
									<div key={`${index}-${headerIndex}`}>
										<Skeleton width={'100%'} height={'3vw'}/>
									</div>
								))}
							</div>
						)))
					:
					(
						<div>
							{rows?.map((row, rowIndex) => (
								<React.Fragment key={rowIndex}>
									{/* Row container to apply the grid layout to each row */}
									<div
										style={{
											display: "grid",
											gridTemplateColumns: headers.map((item) => item.width || "1fr").join(" "),
											alignItems: "center", // Vertical centering of each cell
											padding: "1.5vw 0", // Padding for each row
										}}
									>
										{headers.map((header, headerIndex) => (
											<div key={`${rowIndex}-${headerIndex}`} style={{overflow: "hidden"}}>
												{header.customRender ? (
													header.customRender(row, rowIndex)
												) : header.key ? (
													(() => {
														const keys = header.key.split('.');
														let value = row;
														keys.forEach(key => {
															value = value ? value[key] : undefined;
														});
														return value !== undefined ? (
															<Typography
																variant="body"
																size="medium"
																color={header.color || "black"}
																className={styles.Typography}
															>
																{value}
															</Typography>
														) : (
															<div></div>
														);
													})()
												) : (
													<div></div>
												)}
											</div>
										))}
									</div>
									<Divider color="light-gray"/>
								</React.Fragment>
							))}
							{rows?.length === 0 && (
								<div style={{padding: "1.5vw 0", width: '100%'}}>
									<Typography
										variant="body"
										size="medium"
										color="gray"
										weight="bold"
									>
										No data available
									</Typography>
								</div>
							)}
						</div>
					)
			}
		</>
	);
}

export default function FPTable({
    headers,
    hookName,
    hookKey,
    hookParams = {},
    paginate = true,
    hideHeader = false,
}: {
	headers: HeaderType[],
	hookName: any,
	hookKey: string,
	hookParams?: {},
	paginate?: boolean
}) {

	const [paginationParams, setPaginationParams] = useState({
		page: 1,
		rowsPerPage: 10,
		lastPage: 1,
		from: 0,
		to: 0,
		total: 0,
	});

	const [orderBy, setOrderBy] = useState(null);
	const [orderDir, setOrderDir] = useState('desc');
	const [filters, setFilters] = useState({});

	const {[hookKey]: data, isPending: fetching} = hookName({
		...hookParams,
		page: paginationParams.page,
		orderBy,
		orderDir,
		filters,
	});

	useEffect(() => {
		if (data) {
			setPaginationParams({
				page: data?.current_page,
				rowsPerPage: data?.per_page,
				lastPage: data?.last_page,
				from: data?.from,
				to: data?.to,
				total: data?.total,
			});
		}
	}, [data]);

	return (
		<div>
			{
				Object.keys(filters).length > 0 && (
					<div style={{display: "flex", flexDirection: "row", gap: "1vw", alignItems: 'center', marginBottom: '0.43vw'}}>
						<Typography
							variant="body"
							size="medium"
							color="gray"
							weight="bold"
						>
							Filtered by:
						</Typography>
						{
							Object.keys(filters).map((key, index) => (
								<Chip color="#A3A7B4">
									<button
										onClick={() => {
											delete filters[key]
											setFilters({...filters});
										}}
										className={styles.FilterChip}
									>
										<Cross2Icon
											className={styles.CrossIcon}
										/>
										<Typography
											variant="body"
											color="white"
											size="medium"
											className={styles.FilterText}
										>
											{`${filters[key]}`}
										</Typography>
									</button>
								</Chip>
							))
						}
					</div>
				)
			}
			<TableHeader
				headers={headers}
				orderBy={orderBy}
				setOrderBy={setOrderBy}
				orderDir={orderDir}
				setOrderDir={setOrderDir}
				filters={filters}
				setFilters={setFilters}
				hideHeader={hideHeader}
			/>
			<TableRows headers={headers} fetching={fetching} rows={data?.data}/>
			{
				paginate && data?.data?.length > 0 && (
					<Pagination
						paginationParams={paginationParams}
						setPaginationParams={setPaginationParams}
					/>
				)
			}
		</div>
	);
}
